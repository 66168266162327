import i18next from 'i18next';
import StatusCodes from 'http-status-codes';
import ServiceError from '../common/error';
import { isoDate, date } from '../common/formats';
import Decimal from 'decimal.js';
import { decimal } from '../common/bigdecimal';

const v1 = '/api/1/';

export interface Result {
    status: string;
    message?: string;
    refs?: string[];
}

const process = async<T>(res: Response, reviver?: (key: string, value: any) => any): Promise<T> => {
    const contentType = res.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1) {
        if (res.status >= 400) {
            const json = await res.json();

            throw json;
        }
        else {
            if (reviver) {
                return JSON.parse(await res.text(), reviver);
            }
            return await res.json();
        }
    }
    else if (res.status === StatusCodes.OK) {
        return {} as T;
    }
    else {
        const text = await res.text();

        const message = text.replace(/(.*)?<pre>(.*)<\/pre>(.*)/gm, "$2");

        if (res.status === StatusCodes.UNAUTHORIZED) {
            throw new ServiceError(StatusCodes.UNAUTHORIZED, message, 'error');
        }
        else {
            throw new ServiceError(StatusCodes.BAD_GATEWAY, message, 'error');
        }
    }
}

export const post = async<T = Record<string, never>, R = Record<string, never>>(method: string, data: T, replacer?: (key: string, value: any) => any, reviver?: (key: string, value: any) => any): Promise<R> => {
    const res = await fetch(v1 + method, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': `${i18next.language},en-US,en;q=0.9`
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data, replacer)
    });

    return process<R>(res, reviver);
}

export const postMP = async<T extends { files?: File[] } = Record<string, never>, R = Record<string, never>>(method: string, data: T, replacer?: (key: string, value: any) => any, reviver?: (key: string, value: any) => any): Promise<R> => {
    const { files, ...other } = data;

    const formData = new FormData();

    if (files) {
        for (const attachment of files) {
            formData.append('files[]', attachment, attachment.name);
        }
    }

    formData.append("data", JSON.stringify(other, replacer));

    const res = await fetch(v1 + method, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: formData
    });

    return process<R>(res, reviver);
}

export interface RunReportResult {
    report_id: number;
}

export interface RunReportData {
    lang: string;
    report: string;
    save?: boolean;
}

export const runReport = async<T extends RunReportData>(data: T) => {
    const replacer = (key: string, value: any): any => {
        const anydata:any = data;
        if (anydata[key] && anydata[key] instanceof Date) {
            return isoDate(anydata[key]);
        }
        return value;
    };

    return post<RunReportData, RunReportResult>('run', data, replacer);
}

export interface ReportStatusResult {
    status: RunStatus;
}

export enum RunStatus {
    PENDING,
    SUCCESS,
    FAILED
}
export interface ReportStatusData {
    report_id: string;
}

export const reportStatus = async<T extends ReportStatusData>(data: T) => post<ReportStatusData, ReportStatusResult>('runstatus', data);

export interface SendResetData {
    email?: string;
}

export interface SendResetResult {
    token?: string;
}

export const sendReset = async (data: SendResetData) => post<SendResetData, SendResetResult>('sendreset', data);

export interface ResetPasswordData {
    reset_code?: string;
    reset_token?: string;
    password?: string;
}

export const resetPassword = async (data: ResetPasswordData) => post<ResetPasswordData>('resetpassword', data);

export interface SignInData {
    lang?: string;
    email?: string;
    password?: string;
}

export interface SignInResult {
    user_id?: number;
    user_email?: string;
    user_name?: string;
    media_id?: number;
    url?: string;
    role?: string;
    access?: RoleAccess;
}

export const signIn = async (data: SignInData) => post<SignInData, SignInResult>('signin', data);



export interface Access {
    home: number;
}


export interface ClickupData {
    src: string;
    client?: string;
    lang: string;
}

export interface ClickupResult {
    url?: string;
}

export const clickup = async (data: ClickupData) => post<ClickupData, ClickupResult>('clickup', data);


export interface Organization {
    organization_id: number;
    organization_name: string;
}





export enum BusinessStatusType {
    PENDING = 0,
    ACTIVE = 1,
    INACTIVE = 2
}
export interface SearchBusinessAccountsData {
    lang: string;
    search?: string;
    active?: boolean;
    pending?: boolean;
    inactive?: boolean;
}

export enum BusinessAccountStatus {
    PENDING = 0,
    ACTIVE = 1,
    INACTIVE = 2
}
export interface BusinessAccountData {
    business_id: number;
    business_name?: string;
    business_registeration?: string;
    business_email?: string;
    contact_name?: string;
    contact_email?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
    phone?: string;
    mobile?: string;
    accepted_terms_of_service?: number;
    status: BusinessAccountStatus;
}
export interface SearchBusinessAccountsResult {
    business_accounts: BusinessAccountData[];
}


export const searchBusinessAccounts = async (data: SearchBusinessAccountsData) => {

    return post<SearchBusinessAccountsData, SearchBusinessAccountsResult>('searchbusinessaccounts', data);
}

export interface FindBusinessAccountData {
    business_id: number;
    active?: boolean;
    lang: string;
}
export interface FindBusinessAccountResult {
    business_id: number;
    business_name?: string;
    business_registeration?: string;
    business_email?: string;
    contact_name?: string;
    contact_email?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
    phone?: string;
    mobile?: string;
    status: BusinessAccountStatus;
    organizations: BusinessOrganizationData[];
}

export const findBusinessAccount = async (data: FindBusinessAccountData) => post<FindBusinessAccountData, FindBusinessAccountResult>('findbusinessaccount', data);

export interface SaveBusinessAccountData {
    business_id: number;
    lang?: string;
    business_name?: string;
    business_registeration?: string;
    business_email?: string;
    contact_name?: string;
    contact_email?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
    phone?: string;
    mobile?: string;
    status: BusinessAccountStatus;
}

export interface SaveBusinessAccountResult {
    business_id: number;
}

export const saveBusinessAccount = async (data: SaveBusinessAccountData) => post<SaveBusinessAccountData, SaveBusinessAccountResult>('savebusinessaccount', data);


export interface RemoveBusinessAccountData {
    business_id: number;
}

export const removeBusinessAccount = async (data: RemoveBusinessAccountData) => post<RemoveBusinessAccountData>('removebusinessaccount', data);

export interface UpgradeDatabaseData {
    lang?: string;
    username?: string;
    password?: string;
}

export interface UpgradeDatabaseResult {
    completed: boolean;
}

export const upgradeDatabase = async (data: SignInData) => post<UpgradeDatabaseData, UpgradeDatabaseResult>('upgrade', data)

export interface CreateAccountData {
    organization_email?: string;
    organization_name?: string;
    business_registeration?: string;
    user_name?: string;
    email?: string;
    password?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
    phone?: string;
    mobile?: string;
    accepted_terms_of_service?: number;
}

export interface CreateAccountResult {
    token?: string;
}

export const createAccount = async (account: CreateAccountData) => post<CreateAccountData, CreateAccountResult>('createaccount', account);

export interface VerifyData {
    activation_code?: string;
    activation_token?: string;
}

export const verify = async (data: VerifyData) => post<VerifyData>('verifyaccount', data);

export interface SearchUser {
    id: number;
    user_id: number;
    media_id?: number;
    user_name?: string;
    user_email: string;
    invitations?: number;
    disabled: number;
}

export interface SearchUsersData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface SearchUsersResult {
    users: SearchUser[];
}

export const searchUsers = async (data: SearchUsersData) => post<SearchUsersData, SearchUsersResult>('searchusers', data);

export interface FindUserData {
    user_id: number;
    lang: string;
}
export interface RoleData {
    role_id: number;
    role_name?: string;
    role_description?: string;
}

export interface BranchData {
    branch_id: number;
    branch_name?: string;
    branch_email?: string;
    main_branch?: number;
    address1?: string;
    address2?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    phone?: string;
    mobile?: string;
    postal_code?: string;
    goods_receipt_account?: string;
    goods_in_transit_account?: string;
    sales_account?: string;
    returned_sales_account?: string;
    tax_account?: string;
    sales_tax_account?: string;
    discount_account?: string;
    purchasing_account?: string;
    returned_purchases_account?: string;
    purchase_tax_account?: string;
    purchase_discount_account?: string;
    purchased_assets_account?: string;
    sold_assets_account?: string;
    cogs_account?: string;
    manufacturing_account?: string;
    cheques_portofolio_account?: string;
    disabled: number;
}

export interface UserBranchData {
    branch_id: number;
    branch_name?: string;
}
export interface FindUserResult {
    user_id: number;
    user_name?: string;
    user_email?: string;
    invitations?: number;
    media_id?: number;
    disabled: number;
    roles?: RoleData[];
}

export const findUser = async (data: FindUserData) => post<FindUserData, FindUserResult>('finduser', data);

export interface InviteUserData {
    user_id: number;
    lang: string;
}
export interface InviteUserResult {
    invitations?: number;
}

export const inviteUser = async (data: InviteUserData) => post<InviteUserData, InviteUserResult>('inviteuser', data);

export interface Branch {
    branch_id: number;
}

export interface SaveUserData {
    user_id?: number;
    user_email?: string;
    disabled: number;
    user_name?: string;
    lang?: string;
    roles?: number[];
    files?: File[];
}

export interface SaveUserResult {
    user_id: number;
    organization_id?: number;
    media_id?: number;
}

export const saveUser = async (data: SaveUserData) => postMP<SaveUserData, SaveUserResult>('saveuser', data);

export interface SaveUserProfileData {
    user_id?: number;
    lang: string;
    user_email?: string;
    user_name?: string;
    files?: File[];
}

export interface SaveUserProfileResult {
    user_id?: number;
    media_id?: number;
}

export const saveUserProfile = async (data: SaveUserProfileData) => postMP<SaveUserProfileData, SaveUserProfileResult>('saveuserprofile', data);

export interface RemoveUserData {
    user_id: number;
}

export const removeUser = async (data: RemoveUserData) => post<RemoveUserData>('removeuser', data);

export interface ChangePasswordData {
    current_password: string;
    password: string;
}

export const changePassword = async (data: ChangePasswordData) => post<ChangePasswordData>('changepasssword', data);
export interface SelectFinancialYearData {
    financial_year_id: number;
}
export const selectFinancialYear = async (data: SelectFinancialYearData) => post<SelectFinancialYearData>('selectfinancialyear', data);
export interface SearchRolesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface SearchRolesResult {
    roles: RoleData[];
}

export const searchRoles = async (data: SearchRolesData) => post<SearchRolesData, SearchRolesResult>('searchroles', data);
export interface FindRoleData {
    role_id: number;
    lang: string;
}
export enum AccessType {
    NONE = 0,
    VIEW = 1,
    WRITE = 2
}
export interface RoleAccess {
    adc_dashboard: number;
    adc_permenantcustody: number;
    adc_stpermenantcustodypg: number;
    adc_stpermenantcustodywadifood: number;
    adc_stpermenantcustodychevron: number;
    adc_stpermenantcustodydomty: number;
    adc_temporarycustody: number;
    adc_closetemporarycustodypg: number;
    adc_closetemporarycustodywadifood: number;
    adc_closetemporarycustodychevron: number;
    adc_closetemporarycustodydomty: number;
    adc_othersuppliers: number;
    adc_mainsupplierspg: number;
    adc_mainsupplierswadifood: number;
    adc_mainsupplierschevron: number;
    adc_mainsuppliersdomty: number;
    adc_prepaidexpenses: number;
    adc_assetcreate: number;
    adc_payroll: number;
    adc_bounus: number;
    adc_rundepr: number;
    adc_monthlyaccrual: number;
    adc_ke5z: number;
    adc_reportrequest: number;
    adc_employeelayoff: number;
    afb_dashboard: number;
    afb_permenantcustody: number;
    afb_stpermenantcustodynestle: number;
    afb_temporarycustody: number;
    afb_closetemporarycustodynestle: number;
    afb_othersuppliers: number;
    afb_mainsuppliersnestle: number;
    afb_prepaidexpenses: number;
    afb_assetcreate: number;
    afb_payroll: number;
    afb_bounus: number;
    afb_rundepr: number;
    afb_monthlyaccrual: number;
    afb_ke5z: number;
    afb_reportrequest: number;
    asc_dashboard: number;
    asc_permenantcustody: number;
    asc_stpermenantcustodyenergizer: number;
    asc_stpermenantcustodyjohnson: number;
    asc_temporarycustody: number;
    asc_closetemporarycustodyenergizer: number;
    asc_closetemporarycustodyjohnson: number;
    asc_othersuppliers: number;
    asc_mainsuppliersjohnson: number;
    asc_mainsuppliersenergizer: number;
    asc_prepaidexpenses: number;
    asc_assetcreate: number;
    asc_payroll: number;
    asc_bounus: number;
    asc_rundepr: number;
    asc_monthlyaccrual: number;
    asc_ke5z: number;
    asc_reportrequest: number;
}
export enum RoleType {
    USER = 0,
    ADMIN = 1
}
export interface FindRoleResult extends RoleAccess {
    role_id: number;
    role_name?: string;
    role_description?: string;
    role_type: number;
    disabled: number;
}

export const findRole = async (data: FindRoleData) => post<FindRoleData, FindRoleResult>('findrole', data);

export interface SaveRoleData extends RoleAccess {
    role_id?: number;
    lang: string;
    role_name?: string;
    role_description?: string;
    role_type: number;
    disabled: number;
}

export interface SaveRoleResult {
    role_id: number;
}

export const saveRole = async (data: SaveRoleData) => post<SaveRoleData, SaveRoleResult>('saverole', data);
export interface RemoveRoleData {
    role_id: number;
}

export const removeRole = async (data: RemoveRoleData) => post<RemoveRoleData>('removerole', data);

export interface SearchBranchesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface SearchBranchesResult {
    branches: BranchData[];
}

export const searchBranches = async (data: SearchRolesData) => post<SearchBranchesData, SearchBranchesResult>('searchbranches', data);
export interface FindBranchData {
    branch_id: number;
    lang: string;
}
export interface FindBranchResult {
    branch_id: number;
    branch_name?: string;
    branch_email?: string;
    main_branch?: number;
    address1?: string;
    address2?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    phone?: string;
    mobile?: string;
    postal_code?: string;
    goods_receipt_account?: string;
    goods_in_transit_account?: string;
    sales_account?: string;
    returned_sales_account?: string;
    tax_account?: string;
    sales_tax_account?: string;
    discount_account?: string;
    purchasing_account?: string;
    returned_purchases_account?: string;
    purchase_tax_account?: string;
    purchase_discount_account?: string;
    purchased_assets_account?: string;
    sold_assets_account?: string;
    cogs_account?: string;
    manufacturing_account?: string;
    cheques_portofolio_account?: string;
    disabled: number;
}

export const findBranch = async (data: FindBranchData) => post<FindBranchData, FindBranchResult>('findbranch', data);

export interface SaveBranchData {
    branch_id?: number;
    lang: string;
    branch_name?: string;
    branch_email?: string;
    main_branch?: number;
    address1?: string;
    address2?: string;
    city_id?: number;
    governerate_id?: number;
    country_id?: number;
    phone?: string;
    mobile?: string;
    postal_code?: string;
    goods_receipt_account?: string;
    goods_in_transit_account?: string;
    sales_account?: string;
    returned_sales_account?: string;
    tax_account?: string;
    sales_tax_account?: string;
    discount_account?: string;
    purchasing_account?: string;
    returned_purchases_account?: string;
    purchase_tax_account?: string;
    purchase_discount_account?: string;
    purchased_assets_account?: string;
    sold_assets_account?: string;
    cogs_account?: string;
    manufacturing_account?: string;
    cheques_portofolio_account?: string;
    disabled: number;
}

export interface SaveBranchResult {
    branch_id: number;
}

export const saveBranch = async (data: SaveBranchData) => post<SaveBranchData, SaveBranchResult>('savebranch', data);
export interface RemoveBranchData {
    branch_id: number;
}

export const removeBranch = async (data: RemoveBranchData) => post<RemoveBranchData>('removebranch', data);

export interface SearchBusinessOrganizationData {
    search?: string;
    business_active?: boolean;
    active?: boolean;
    pending?: boolean;
    inactive?: boolean;
    lang: string;
    business_id: number;
}
export interface BusinessOrganizationData {
    organization_id: number;
    organization_name?: string;
    organization_email?: string;
}
export interface SearchBusinessOrganizationResult {
    organizations: BusinessOrganizationData[];
}

export const searchBusinessOrganizations = async (data: SearchBusinessOrganizationData) => post<SearchBusinessOrganizationData, SearchBusinessOrganizationResult>('searchbusinessorganizations', data);
export interface FindBusinessOrganizationData {
    organization_id: number;
    lang: string;
}
export interface FindBusinessOrganizationResult {
    organization_id: number;
    organization_name?: string;
    business_id?: number;
    organization_email?: string;
    disabled: number;
}

export const findBusinessOrganization = async (data: FindBusinessOrganizationData) => post<FindBusinessOrganizationData, FindBusinessOrganizationResult>('findbusinessorganization', data);

export interface SaveBusinessOrganizationData {
    organization_id: number;
    organization_name?: string;
    business_id?: number;
    organization_email?: string;
    lang: string;
    disabled: number;
}

export interface SaveBusinessOrganizationResult {
    organization_id: number;
}

export const saveBusinessOrganization = async (data: SaveBusinessOrganizationData) => post<SaveBusinessOrganizationData, SaveBusinessOrganizationResult>('savebusinessorganization', data);
export interface RemoveBusinessOrganizationData {
    organization_id: number;
}

export const removeBusinessOrganization = async (data: RemoveBusinessOrganizationData) => post<RemoveBusinessOrganizationData>('removebusinessorganization', data);

export interface CurrencyData {
    currency_code: string;
    currency_name?: string;
    currency_symbol?: string;
    fraction_digits: number;
}

export interface SearchCurrenciesData {
    search?: string;
    active: boolean;
    lang: string;
    exclude?: string[];
}

export interface SearchCurrenciesResult {
    currencies: CurrencyData[];
}

export const searchCurrencies = async (data: SearchCurrenciesData) => post<SearchCurrenciesData, SearchCurrenciesResult>('searchcurrencies', data);
export interface FindCurrencyData {
    currency_code: string;
    lang: string;
}
export interface FindCurrencyResult {
    currency_code: string;
    currency_name: string;
    currency_symbol: string;
    fraction_digits: number;
    iso_code: number;
    disabled: number;
}

export const findCurrency = async (data: FindCurrencyData) => post<FindCurrencyData, FindCurrencyResult>('findcurrency', data);

export interface SaveCurrencyData {
    op?: string;
    currency_code?: string;
    lang?: string;
    currency_name?: string;
    currency_symbol?: string;
    fraction_digits?: number;
    iso_code?: number;
    disabled: number;
}

export interface SaveCurrencyResult {
    currency_code?: string;
}

export const saveCurrency = async (data: SaveCurrencyData) => post<SaveCurrencyData, SaveCurrencyResult>('savecurrency', data);
export interface RemoveCurrencyData {
    currency_code: string;
}

export const removeCurrency = async (data: RemoveCurrencyData) => post<RemoveCurrencyData>('removecurrency', data);

export interface ExchangeRateData {
    currency_code: string;
    currency_name?: string;
    currency_symbol?: string;
    fraction_digits: number;
    exchange_date: Date;
    exchange_rate: Decimal;
}

export interface SearchExchangeRatesData {
    search?: string;
    lang: string;
    currency_code?: string;
}

export interface SearchExchangeRatesResult {
    exchange_rates: ExchangeRateData[];
}

export const searchExchangeRates = async (data: SearchExchangeRatesData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return date(value);
        }
        if (key === 'exchange_rate') {
            return decimal(value);
        }
        return value;
    };

    return post<SearchExchangeRatesData, SearchExchangeRatesResult>('searchexchangerates', data, undefined, reviver);
}
export interface FindExchangeRateData {
    currency_code: string;
    exchange_date: Date;
    lang: string;
}
export interface FindExchangeRateResult {
    currency_code: string;
    currency_name: string;
    currency_symbol: string;
    fraction_digits: number;
    exchange_date: Date;
    exchange_rate: Decimal;
}

export const findExchangeRate = async (data: FindExchangeRateData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return isoDate(value);
        }
        if (key === 'exchange_rate') {
            return value.toString();
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return date(value);
        }
        if (key === 'exchange_rate') {
            return decimal(value);
        }

        return value;
    };

    return post<FindExchangeRateData, FindExchangeRateResult>('findexchangerate', data, replacer, reviver);
}

export const findExchangeRateAt = async (data: FindExchangeRateData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return isoDate(value);
        }
        if (key === 'exchange_rate') {
            return value.toString();
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return date(value);
        }
        if (key === 'exchange_rate') {
            return decimal(value);
        }
        return value;
    };
    return post<FindExchangeRateData, FindExchangeRateResult>('findexchangerateat', data, replacer, reviver);
}
export interface SaveExchangeRateData {
    op?: string;
    currency_code?: string;
    lang?: string;
    exchange_date?: Date;
    exchange_rate?: Decimal;
}

export interface SaveExchangeRateResult {
    currency_code?: string;
}

export const saveExchangeRate = async (data: SaveExchangeRateData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return isoDate(value);
        }
        if (key === 'exchange_rate') {
            return value.toString();
        }
        return value;
    };

    return post<SaveExchangeRateData, SaveExchangeRateResult>('saveexchangerate', data, replacer);
}
export interface RemoveExchangeRateData {
    currency_code: string;
    exchange_date: Date;
}

export const removeExchangeRate = async (data: RemoveExchangeRateData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'exchange_date') {
            return isoDate(value);
        }
        return value;
    };

    return post<RemoveExchangeRateData>('removeexchangerate', data, replacer);
}

export interface CityData {
    country_id: number;
    country_name: string;
    governerate_id: number;
    governerate_name: string;
    city_id: number;
    city_name?: string;
    disabled: number;
}

export interface SearchCitiesData {
    country_id?: number;
    governerate_id?: number;
    search?: string;
    active: boolean;
    lang: string;
}

export interface SearchCitiesResult {
    cities: CityData[];
}

export const searchCities = async (data: SearchCitiesData) => post<SearchCitiesData, SearchCitiesResult>('searchcities', data);
export interface FindCityData {
    city_id: number;
    lang: string;
    active: boolean;
}
export interface FindCityResult {
    country_id: number;
    country_name: string;
    governerate_id: number;
    governerate_name: string;
    city_id: number;
    city_name?: string;
    disabled: number;
}

export const findCity = async (data: FindCityData) => post<FindCityData, FindCityResult>('findcity', data);

export interface SaveCityData {
    city_id?: number;
    governerate_id?: number;
    lang: string;
    city_name?: string;
    disabled: number;
}

export interface SaveCityResult {
    city_id: number;
}

export const saveCity = async (data: SaveCityData) => post<SaveCityData, SaveCityResult>('savecity', data);
export interface RemoveCityData {
    city_id: number;
}

export const removeCity = async (data: RemoveCityData) => post<RemoveCityData>('removecity', data);
export interface GovernerateData {
    country_id: number;
    country_name: string;
    governerate_id: number;
    governerate_name: string;
    cities?: CityData[];
    disabled: number;
}

export interface SearchGoverneratesData {
    country_id?: number;
    search?: string;
    active: boolean;
    lang: string;
    all?: boolean;
}

export interface SearchGoverneratesResult {
    governerates: GovernerateData[];
}

export const searchGovernerates = async (data: SearchGoverneratesData) => post<SearchGoverneratesData, SearchGoverneratesResult>('searchgovernerates', data);
export interface FindGovernerateData {
    governerate_id: number;
    lang: string;
    active: boolean;
    all?: boolean;
}
export interface FindGovernerateResult {
    country_id: number;
    country_name: string;
    governerate_id: number;
    governerate_name?: string;
    cities?: CityData[];
    disabled: number;
}

export const findGovernerate = async (data: FindGovernerateData) => post<FindGovernerateData, FindGovernerateResult>('findgovernerate', data);

export interface SaveGovernerateData {
    governerate_id?: number;
    country_id?: number;
    lang: string;
    governerate_name?: string;
    disabled: number;
}

export interface SaveGovernerateResult {
    governerate_id: number;
}

export const saveGovernerate = async (data: SaveGovernerateData) => post<SaveGovernerateData, SaveGovernerateResult>('savegovernerate', data);
export interface RemoveGovernerateData {
    governerate_id: number;
}

export const removeGovernerate = async (data: RemoveGovernerateData) => post<RemoveGovernerateData>('removegovernerate', data);

export interface CountryData {
    country_id: number;
    country_name: string;
    nationality: string;
    governerates?: GovernerateData[];
    disabled: number;
}

export interface SearchCountriesData {
    search?: string;
    active: boolean;
    lang: string;
    all?: boolean;
}

export interface SearchCountriesResult {
    countries: CountryData[];
}

export const searchCountries = async (data: SearchCountriesData) => post<SearchCountriesData, SearchCountriesResult>('searchcountries', data);
export interface FindCountryData {
    country_id: number;
    lang: string;
    active: boolean;
    all?: boolean;
}
export interface FindCountryResult {
    country_id: number;
    country_name?: string;
    nationality?: string;
    governerates: GovernerateData[];
    disabled: number;
}

export const findCountry = async (data: FindCountryData) => post<FindCountryData, FindCountryResult>('findcountry', data);

export interface SaveCountryData {
    country_id?: number;
    lang: string;
    country_name?: string;
    nationality?: string;
    disabled: number;
}

export interface SaveCountryResult {
    country_id: number;
}

export const saveCountry = async (data: SaveCountryData) => post<SaveCountryData, SaveCountryResult>('savecountry', data);
export interface RemoveCountryData {
    country_id: number;
}

export const removeCountry = async (data: RemoveCountryData) => post<RemoveCountryData>('removecountry', data);


export interface SearchCustomerTypesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface CustomerTypeData {
    customer_type_id: number;
    customer_type_name?: string;
    rank?: number;
    customer_type_description?: string;
}
export interface SearchCustomerTypesResult {
    customer_types: CustomerTypeData[];
}

export const searchCustomerTypes = async (data: SearchCustomerTypesData) => post<SearchCustomerTypesData, SearchCustomerTypesResult>('searchcustomerTypes', data);
export interface FindCustomerTypeData {
    customer_type_id: number;
    lang: string;
}
export interface FindCustomerTypeResult {
    customer_type_id: number;
    customer_type_name?: string;
    rank?: number;
    customer_type_description?: string;
    disabled: number;
}

export const findCustomerType = async (data: FindCustomerTypeData) => post<FindCustomerTypeData, FindCustomerTypeResult>('findcustomerType', data);

export interface SaveCustomerTypeData {
    customer_type_id?: number;
    rank?: number;
    lang: string;
    customer_type_name?: string;
    customer_type_description?: string;
    disabled: number;
}

export interface SaveCustomerTypeResult {
    customer_type_id: number;
}

export const saveCustomerType = async (data: SaveCustomerTypeData) => post<SaveCustomerTypeData, SaveCustomerTypeResult>('savecustomerType', data);
export interface RemoveCustomerTypeData {
    customer_type_id: number;
}

export const removeCustomerType = async (data: RemoveCustomerTypeData) => post<RemoveCustomerTypeData>('removecustomerType', data);

export interface SearchVendorTypesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface VendorTypeData {
    vendor_type_id: number;
    vendor_type_name?: string;
    vendor_type_description?: string;
}
export interface SearchVendorTypesResult {
    vendor_types: VendorTypeData[];
}

export const searchVendorTypes = async (data: SearchVendorTypesData) => post<SearchVendorTypesData, SearchVendorTypesResult>('searchvendortypes', data);
export interface FindVendorTypeData {
    vendor_type_id: number;
    lang: string;
}
export interface FindVendorTypeResult {
    vendor_type_id: number;
    vendor_type_name?: string;
    vendor_type_description?: string;
    disabled: number;
}

export const findVendorType = async (data: FindVendorTypeData) => post<FindVendorTypeData, FindVendorTypeResult>('findvendortype', data);

export interface SaveVendorTypeData {
    vendor_type_id: number;
    lang: string;
    vendor_type_name: string;
    vendor_type_description?: string;
    disabled: number;
}

export interface SaveVendorTypeResult {
    vendor_type_id: number;
}

export const saveVendorType = async (data: SaveVendorTypeData) => post<SaveVendorTypeData, SaveVendorTypeResult>('savevendortype', data);
export interface RemoveVendorTypeData {
    vendor_type_id: number;
}

export const removeVendorType = async (data: RemoveVendorTypeData) => post<RemoveVendorTypeData>('removevendortype', data);

export enum WarehouseUsageType {
    PUBLIC = 0,
    DISTRIBUTION = 1,
    PARTS = 2,
    PRIVATE = 3,
    SCRAP = 4
}
export interface SearchWarehouseTypesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface WarehouseTypeData {
    warehouse_type_id: number;
    warehouse_type_name?: string;
    warehouse_type_description?: string;
    warehouse_usage: WarehouseUsageType;
}
export interface SearchWarehouseTypesResult {
    warehouse_types: WarehouseTypeData[];
}

export const searchWarehouseTypes = async (data: SearchWarehouseTypesData) => post<SearchWarehouseTypesData, SearchWarehouseTypesResult>('searchwarehousetypes', data);
export interface FindWarehouseTypeData {
    warehouse_type_id: number;
    lang: string;
}
export interface FindWarehouseTypeResult {
    warehouse_type_id: number;
    warehouse_type_name?: string;
    warehouse_type_description?: string;
    warehouse_usage: WarehouseUsageType;
    disabled: number;
}

export const findWarehouseType = async (data: FindWarehouseTypeData) => post<FindWarehouseTypeData, FindWarehouseTypeResult>('findwarehousetype', data);

export interface SaveWarehouseTypeData {
    warehouse_type_id?: number;
    lang: string;
    warehouse_type_name?: string;
    warehouse_type_description?: string;
    warehouse_usage: WarehouseUsageType;
    disabled: number;
}

export interface SaveWarehouseTypeResult {
    warehouse_type_id: number;
}

export const saveWarehouseType = async (data: SaveWarehouseTypeData) => post<SaveWarehouseTypeData, SaveWarehouseTypeResult>('savewarehousetype', data);
export interface RemoveWarehouseTypeData {
    warehouse_type_id: number;
}

export const removeWarehouseType = async (data: RemoveWarehouseTypeData) => post<RemoveWarehouseTypeData>('removewarehousetype', data);

export interface SearchTransactionTypesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface TransactionTypeData {
    transaction_type_id: number;
    transaction_type_name?: string;
    transaction_type_description?: string;
}
export interface SearchTransactionTypesResult {
    transaction_types: TransactionTypeData[];
}

export const searchTransactionTypes = async (data: SearchTransactionTypesData) => post<SearchTransactionTypesData, SearchTransactionTypesResult>('searchtransactiontypes', data);
export interface FindTransactionTypeData {
    transaction_type_id: number;
    lang: string;
}
export interface FindTransactionTypeResult {
    transaction_type_id: number;
    transaction_type_name?: string;
    transaction_type_description?: string;
    disabled: number;
}

export const findTransactionType = async (data: FindTransactionTypeData) => post<FindTransactionTypeData, FindTransactionTypeResult>('findtransactiontype', data);

export interface SaveTransactionTypeData {
    transaction_type_id?: number;
    lang: string;
    transaction_type_name?: string;
    transaction_type_description?: string;
    disabled: number;
}

export interface SaveTransactionTypeResult {
    transaction_type_id: number;
}

export const saveTransactionType = async (data: SaveTransactionTypeData) => post<SaveTransactionTypeData, SaveTransactionTypeResult>('savetransactiontype', data);
export interface RemoveTransactionTypeData {
    transaction_type_id: number;
}

export const removeTransactionType = async (data: RemoveTransactionTypeData) => post<RemoveTransactionTypeData>('removetransactiontype', data);

export interface SearchUnitTypesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface UnitTypeConversionData {
    to_unit_type_id: number;
    to_unit_type_name?: string;
    to_unit_type_symbol?: string;
    fraction?: number;
    ratio?: Decimal;
}
export interface UnitTypeData {
    unit_type_id: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    unit_type_description?: string;
}
export interface SearchUnitTypesResult {
    unit_types: UnitTypeData[];
}

export const searchUnitTypes = async (data: SearchUnitTypesData) => post<SearchUnitTypesData, SearchUnitTypesResult>('searchunittypes', data);
export interface FindUnitTypeData {
    unit_type_id: number;
    lang: string;
}
export interface FindUnitTypeResult {
    unit_type_id: number;
    unit_type_name?: string;
    unit_type_description?: string;
    unit_type_symbol?: string;
    disabled: number;
    unit_type_conversions?: UnitTypeConversionData[];
}

export const findUnitType = async (data: FindUnitTypeData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'ratio') {
            return decimal(value);
        }
        return value;
    };
    return post<FindUnitTypeData, FindUnitTypeResult>('findunittype', data, undefined, reviver);
}

export interface SaveUnitTypeConversion {
    to_unit_type_id: number;
    fraction: number;
    ratio: Decimal;
}
export interface SaveUnitTypeData {
    unit_type_id?: number;
    lang: string;
    unit_type_name?: string;
    unit_type_description?: string;
    unit_type_symbol?: string;
    disabled: number;
    unit_type_conversions?: SaveUnitTypeConversion[];
}

export interface SaveUnitTypeResult {
    unit_type_id: number;
}

export const saveUnitType = async (data: SaveUnitTypeData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'ratio') {
            return value.toString();
        }
        return value;
    };
    return post<SaveUnitTypeData, SaveUnitTypeResult>('saveunittype', data, replacer);
}
export interface RemoveUnitTypeData {
    unit_type_id: number;
}

export const removeUnitType = async (data: RemoveUnitTypeData) => post<RemoveUnitTypeData>('removeunittype', data);

export interface SearchSubJournalsData {
    search?: string;
    active: boolean;
    subjournal_type?: number;
    lang: string;
}

export enum SubJournalType {
    GENERAL = 1,
    TREASURY = 2,
    PURCHASING = 3,
    SALES = 4,
    INVENTORY = 5,
    OPEN_BALANCE = 6
}
export interface SubJournalData {
    subjournal_id: number;
    subjournal_name?: string;
    subjournal_description?: string;
    allow_manual_transactions: number;
    subjournal_type?: SubJournalType;

}
export interface SearchSubJournalsResult {
    subjournals: SubJournalData[];
}

export const searchSubJournals = async (data: SearchSubJournalsData) => post<SearchSubJournalsData, SearchSubJournalsResult>('searchsubjournals', data);
export interface FindSubJournalData {
    subjournal_id: number;
    lang: string;
}
export interface FindSubJournalResult {
    subjournal_id: number;
    subjournal_name?: string;
    subjournal_type?: SubJournalType;
    subjournal_description?: string;
    allow_manual_transactions: number;
    disabled: number;
}

export const findSubJournal = async (data: FindSubJournalData) => post<FindSubJournalData, FindSubJournalResult>('findsubjournal', data);

export interface SaveSubJournalData {
    subjournal_id?: number;
    lang: string;
    subjournal_name?: string;
    subjournal_description?: string;
    subjournal_type?: SubJournalType;
    allow_manual_transactions: number;
    disabled: number;
}

export interface SaveSubJournalResult {
    subjournal_id: number;
}

export const saveSubJournal = async (data: SaveSubJournalData) => post<SaveSubJournalData, SaveSubJournalResult>('savesubjournal', data);
export interface RemoveSubJournalData {
    subjournal_id: number;
}

export const removeSubJournal = async (data: RemoveSubJournalData) => post<RemoveSubJournalData>('removesubjournal', data);

export interface SearchBanksData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface BankData {
    bank_id: number;
    bank_account?: string;
    cheques_under_collection?: string;
    bank_name?: string;
    bank_description?: string;
}
export interface SearchBanksResult {
    banks: BankData[];
}

export const searchBanks = async (data: SearchBanksData) => post<SearchBanksData, SearchBanksResult>('searchbanks', data);
export interface FindBankData {
    bank_id: number;
    bank_account?: string;
    cheques_under_collection?: string;
    lang: string;
}
export interface FindBankResult {
    bank_id: number;
    bank_name?: string;
    bank_account?: string;
    bank_account_name?: string;
    cheques_under_collection?: string;
    cheques_under_collection_name?: string;
    bank_description?: string;
    disabled: number;
}

export const findBank = async (data: FindBankData) => post<FindBankData, FindBankResult>('findbank', data);

export interface SaveBankData {
    bank_id?: number;
    bank_account?: string;
    cheques_under_collection?: string;
    lang: string;
    bank_name?: string;
    bank_description?: string;
    disabled: number;
}

export interface SaveBankResult {
    bank_id: number;
    bank_account?: string;
    cheques_under_collection?: string;
}

export const saveBank = async (data: SaveBankData) => post<SaveBankData, SaveBankResult>('savebank', data);
export interface RemoveBankData {
    bank_id: number;
}

export const removeBank = async (data: RemoveBankData) => post<RemoveBankData>('removebank', data);

export interface SearchVendorsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface VendorData {
    vendor_id: number;
    vendor_no?: number;
    vendor_name?: string;
    vendor_description?: string;
    branch_id?: number;
    branch_name?: string;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    tax_id?: number;
    tax_name?: string;
    vendor_type_id?: number;
    vendor_type_name?: string;
    job_position?: string;
    commercial_registration?: string;
    tax_card?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    website?: string;
    purchaserep_id: number;
    purchaserep_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    nationality_id?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    payable_account?: string;
    return_account?: string;
    currency_code?: string;
    bank_account?: string;
    goods_in_transit_account?: string;
}
export interface SearchVendorsResult {
    vendors: VendorData[];
}

export const searchVendors = async (data: SearchVendorsData) => post<SearchVendorsData, SearchVendorsResult>('searchvendors', data);
export interface FindVendorData {
    vendor_id: number;
    lang: string;
}
export interface FindVendorResult {
    vendor_id: number;
    vendor_no?: number;
    vendor_name?: string;
    vendor_description?: string;
    branch_id?: number;
    branch_name?: string;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    tax_id?: number;
    tax_name?: string;
    vendor_type_id?: number;
    vendor_type_name?: string;
    job_position?: string;
    commercial_registration?: string;
    tax_card?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    website?: string;
    purchaserep_id?: number;
    purchaserep_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    payable_account?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    nationality_id?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    return_account?: string;
    currency_code?: string;
    bank_account?: string;
    goods_in_transit_account?: string;
    disabled: number;
    media: Media[];
    notes?: NoteData[];

}

export const findVendor = async (data: FindVendorData) => post<FindVendorData, FindVendorResult>('findvendor', data);

export interface SaveVendorData {
    vendor_id?: number;
    vendor_no?: number;
    lang: string;
    vendor_name?: string;
    vendor_description?: string;
    branch_id?: number;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    tax_id?: number;
    tax_name?: string;
    vendor_type_id?: number;
    vendor_type_name?: string;
    job_position?: string;
    commercial_registration?: string;
    tax_card?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    website?: string;
    purchaserep_id?: number;
    purchaserep_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    nationality_id?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    goods_in_transit_account?: string;
    country_id?: number;
    country_name?: string;
    payable_account?: string;
    return_account?: string;
    currency_code?: string;
    bank_account?: string;
    disabled: number;
    media: Media[];
    files?: File[];
}

export interface SaveVendorResult {
    vendor_id: number;
    vendor_no: number;
    media: Media[];
}

export const saveVendor = async (data: SaveVendorData) => postMP<SaveVendorData, SaveVendorResult>('savevendor', data);

export interface SaveVendorNote {
    vendor_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveVendorNote = async (data: SaveVendorNote) => {
    return post<SaveVendorNote, SaveNoteResult>('savevendornote', data);
};

export interface RemoveVendorData {
    vendor_id: number;
}

export const removeVendor = async (data: RemoveVendorData) => post<RemoveVendorData>('removevendor', data);

export const removeVendorNote = async (data: RemoveNote) => post<RemoveNote>('removevendornote', data);

export interface SearchCustomersData {
    search?: string;
    active: boolean;
    lang: string;

}
export interface NoteData {
    note_id?: number;
    user_id?: number;
    note?: string;
}

export interface Notes {
    notes?: NoteData[];
}
export interface SearchCustomersData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface CustomerData {
    customer_id: number;
    customer_no?: number;
    branch_id?: number;
    branch_name?: string;
    customer_name?: string;
    customer_description?: string;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    tax_id?: number;
    tax_name?: string;
    credit_limit?: Decimal;
    customer_type_id?: number;
    customer_type_name?: string;
    job_position?: string;
    commercial_registration?: string;
    tax_card?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    website?: string;
    salesrep_id?: number;
    salesrep_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    nationality_id?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    receivable_account?: string;
    return_account?: string;
    currency_code?: string;
    bank_account?: string;
}
export interface SearchCustomersResult {
    customers: CustomerData[];
}

export const searchCustomers = async (data: SearchCustomersData) => post<SearchCustomersData, SearchCustomersResult>('searchcustomers', data);


export const searchCredit_Limits = async (data: SearchCustomersData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'credit_limit') {
            return decimal(value);
        }
        return value;
    };

    return post<SearchCustomersData, SearchCustomersResult>('searchcustomers', data, undefined, reviver);
}

export interface FindCustomerData {
    customer_id: number;
    lang: string;
}
export interface FindCustomerResult {
    customer_id: number;
    customer_no?: number;
    customer_name?: string;
    branch_id?: number;
    branch_name?: string;
    customer_description?: string;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    tax_id?: number;
    tax_name?: string;
    credit_limit?: Decimal;
    customer_type_id?: number;
    customer_type_name?: string;
    job_position?: string;
    commercial_registration?: string;
    tax_card?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    website?: string;
    salesrep_id?: number;
    salesrep_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    nationality_id?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    receivable_account?: string;
    return_account?: string;
    currency_code?: string;
    bank_account?: string;
    disabled: number;
    media: Media[];
    notes?: NoteData[];

}

export const findCustomer = async (data: FindCustomerData) => post<FindCustomerData, FindCustomerResult>('findcustomer', data);

export interface SaveCustomerData {
    customer_id?: number;
    customer_no?: number;
    branch_id?: number;
    lang: string;
    customer_name?: string;
    customer_description?: string;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    tax_id?: number;
    tax_name?: string;
    credit_limit?: Decimal;
    customer_type_id?: number;
    customer_type_name?: string;
    job_position?: string;
    commercial_registration?: string;
    tax_card?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    website?: string;
    salesrep_id?: number;
    salesrep_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    nationality_id?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    receivable_account?: string;
    return_account?: string;
    currency_code?: string;
    bank_account?: string;
    disabled: number;
    media: Media[];
    files?: File[];

}

export interface SaveCustomerResult {
    customer_id: number;
    customer_no: number;
    media: Media[];

}

export interface SaveCustomerNote {
    customer_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveCustomerNote = async (data: SaveCustomerNote) => {
    return post<SaveCustomerNote, SaveNoteResult>('savecustomernote', data);
};

export const saveCustomer = async (data: SaveCustomerData) => postMP<SaveCustomerData, SaveCustomerResult>('savecustomer', data);

export const removeCustomerNote = async (data: RemoveNote) => post<RemoveNote>('removecustomernote', data);

export interface RemoveCustomerData {
    customer_id: number;
}

export const removeCustomer = async (data: RemoveCustomerData) => post<RemoveCustomerData>('removecustomer', data);


export interface CustomerBalanceData {
    customer_id?: number;
    customer_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_id?: number;
    document_type?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    debit?: Decimal;
    credit?: Decimal;

}

export interface SearchCustomerBalancesData {
    customer_id?: number;
    currency_code?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    lang?: string;
}

export interface SearchCustomerBalancesResult {
    customer_balances?: CustomerBalanceData[];
}


export const searchCustomerBalances = async (data: SearchCustomerBalancesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };

    return post<SearchCustomerBalancesData, SearchCustomerBalancesResult>('searchcustomerbalances', data, replacer, reviver);

}
export interface FindCustomerBalanceData {
    customer_id?: number;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    details?: CustomerChequesData[];
    trx_details?: CustomerBalanceData[];
    trx_cheques?: ChequesData[];
    lang?: string;
    active: boolean;
}
export interface FindCustomerBalanceResult {
    customer_id?: number;
    customer_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    debit?: Decimal;
    credit?: Decimal;


    details?: CustomerChequesData[];
    trx_details?: CustomerBalanceData[];
    trx_cheques?: ChequesData[];
}
export interface CustomerChequesData {
    customer_id?: number;
    customer_name?: string;
    currency_code?: string;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;

}


export const findCustomerBalance = async (data: FindCustomerBalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };
    return post<FindCustomerBalanceData, FindCustomerBalanceResult>('findcustomerbalance', data, replacer, reviver);
}

export interface Vendor_BalanceData {
    vendor_id?: number;
    vendor_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_id?: number;
    document_type?: number;

    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    debit?: Decimal;
    credit?: Decimal;

}

export interface SearchVendor_BalancesData {
    vendor_id?: number;
    currency_code?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    lang?: string;
}

export interface SearchVendor_BalancesResult {
    vendor_balances?: Vendor_BalanceData[];
}


export const searchVendor_Balances = async (data: SearchVendor_BalancesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };

    return post<SearchVendor_BalancesData, SearchVendor_BalancesResult>('searchvendor_balances', data, replacer, reviver);

}
export interface FindVendor_BalanceData {
    vendor_id?: number;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    details?: Vendor_ChequesData[];
    trx_details?: Vendor_BalanceData[];
    trx_cheques?: ChequesData[];
    lang?: string;
    active: boolean;
}
export interface FindVendor_BalanceResult {
    vendor_id?: number;
    vendor_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;

    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    debit?: Decimal;
    credit?: Decimal;


    details?: Vendor_ChequesData[];
    trx_details?: Vendor_BalanceData[];
    trx_cheques?: ChequesData[];
}
export interface Vendor_ChequesData {
    vendor_id?: number;
    vendor_name?: string;
    currency_code?: string;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;

}


export const findVendor_Balance = async (data: FindVendor_BalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };
    return post<FindVendor_BalanceData, FindVendor_BalanceResult>('findvendor_balance', data, replacer, reviver);
}


export interface SearchServicesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface ServiceData {
    service_id: number;
    service_name?: string;
    service_description?: string;
}
export interface SearchServicesResult {
    services: ServiceData[];
}

export const searchServices = async (data: SearchServicesData) => post<SearchServicesData, SearchServicesResult>('searchservices', data);
export interface FindServiceData {
    service_id: number;
    lang: string;
}
export interface FindServiceResult {
    service_id: number;
    service_name?: string;
    service_description?: string;
    disabled: number;
}

export const findService = async (data: FindServiceData) => post<FindServiceData, FindServiceResult>('findservice', data);

export interface SaveServiceData {
    service_id?: number;
    lang: string;
    service_name?: string;
    service_description?: string;
    disabled: number;
}

export interface SaveServiceResult {
    service_id: number;
}

export const saveService = async (data: SaveServiceData) => post<SaveServiceData, SaveServiceResult>('saveservice', data);
export interface RemoveServiceData {
    service_id: number;
}

export const removeService = async (data: RemoveServiceData) => post<RemoveServiceData>('removeservice', data);

export interface SearchWarehousesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface WarehouseData {
    warehouse_id: number;
    warehouse_no?: number;
    warehouse_name?: string;
    warehouse_description?: string;
    warehouse_type_id?: number;
    warehouse_type_name?: string;
    branch_id?: number;
    branch_name?: string;
    account_code?: string;
    account_name?: string;
    purchasing_account?: string;
    purchasing_name?: string;
    cogs_account?: string;
    cogs_account_name?: string;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;

}
export interface SearchWarehousesResult {
    warehouses: WarehouseData[];
}

export const searchWarehouses = async (data: SearchWarehousesData) => post<SearchWarehousesData, SearchWarehousesResult>('searchwarehouses', data);
export interface FindWarehouseData {
    warehouse_id: number;
    products?: boolean;
    includeZero?: boolean;
    search?: string;
    lang: string;
}

export interface FindWarehouseProductData {
    warehouse_id: number;
    product_code: string;

    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;

    lang: string;
}

export interface WarehouseProductData {
    product_code: string;
    product_name?: string;
    replenishment?: Decimal;
    issued?: Decimal;
    available?: Decimal;
    category_id?: number;
    category_name?: string;
    store_unit_type_id?: number;
    store_unit_type_name?: number;
    store_unit_type_symbol?: number;
    media_id?: number;
}

export interface BatchDetailData {
    batch?: string;
    replenishment?: Decimal;
    issued?: Decimal;
}
export interface SerialDetailData {
    product_serial?: string;
    replenishment?: Decimal;
    issued?: Decimal;
}
export interface ExpiryDetailData {
    expiry_date?: Date;
    replenishment?: Decimal;
    issued?: Decimal;
}
export interface FindProductDetailsData {
    warehouse_id: number;
    product_code: string;
    lang: string;
}
export interface SearchSerialData {
    warehouse_id?: number;
    product_code?: string;
    search?: string;
}

export interface SearchSerialResult {
    serials: SerialDetailData[];
}

export const searchSerials = async (data: SearchSerialData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'replenishment' || key === 'issued') {
            return decimal(value);
        }
        return value;
    };
    return post<SearchSerialData, SearchSerialResult>('searchserials', data, undefined, reviver);
}


export interface SearchIssuedBatchData {
    warehouse_id?: number;
    product_code?: string;
    search?: string;
}

export interface SearchIssuedBatchResult {
    batches: BatchDetailData[];
}

export const searchIssuedBatches = async (data: SearchIssuedBatchData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'replenishment' || key === 'issued') {
            return decimal(value);
        }
        return value;
    };
    return post<SearchIssuedBatchData, SearchIssuedBatchResult>('searchissuedbatches', data, undefined, reviver);
}


export interface SearchIssuedExpiryDatesData {
    warehouse_id?: number;
    product_code?: string;
    search?: string;
}

export interface SearchIssuedExpiryDateResult {
    expiry_dates: ExpiryDetailData[];
}

export const searchIssuedExpiryDates = async (data: SearchIssuedExpiryDatesData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'replenishment' || key === 'issued') {
            return decimal(value);
        }
        if (key === 'expiry_date') {
            return value ? date(value) : value;
        }

        return value;
    };
    return post<SearchIssuedExpiryDatesData, SearchIssuedExpiryDateResult>('searchissuedexpirydates', data, undefined, reviver);
}


export interface FindProductDetailsResult {
    batches?: BatchDetailData[];
    serials?: SerialDetailData[];
    expiry_dates?: ExpiryDetailData[];
}

export const findWarehouseProductDetails = async (data: FindProductDetailsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'replenishment' || key === 'issued') {
            return decimal(value);
        }

        if (key === 'expiry_date') {
            return date(value);
        }

        return value;
    };

    return post<FindProductDetailsData, FindProductDetailsResult>('findwarehouseproductdetails', data, undefined, reviver)
};

export interface WarehouseProductTrxData {
    replenishment_id?: number;
    issued_id?: number;
    type?: number;
    product_code: string;
    product_name?: string;
    trxdate?: Date;
    trx_no?: number;
    trxtype?: number;
    openbalance?: Decimal;
    replenishment?: Decimal;
    issued?: Decimal;
    balance?: Decimal;
}
export interface FindWarehouseResult {
    warehouse_id: number;
    warehouse_no?: number;
    warehouse_type_id?: number;
    warehouse_type_name?: string;
    warehouse_name?: string;
    warehouse_description?: string;
    branch_id?: number;
    branch_name?: string;
    account_code?: string;
    disabled: number;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;

    warehouse_products?: WarehouseProductData[];
}

export const findWarehouse = async (data: FindWarehouseData) => post<FindWarehouseData, FindWarehouseResult>('findwarehouse', data);

export interface FindWarehouseProductResult {
    warehouse_id: number;
    warehouse_no?: number;
    warehouse_name?: string;
    warehouse_description?: string;
    product_code?: string;
    product_name?: string;
    store_unit_type_id?: number;
    store_unit_type_name?: number;
    store_unit_type_symbol?: number;

    warehouse_products?: WarehouseProductTrxData[];
}

export const findWarehouseProduct = async (data: FindWarehouseProductData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'from_date' || key === 'to_date') {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'trxdate') {
            return value ? date(value) : value;
        }
        if (key === 'openbalance' || key === 'replenishment' || key === 'issued' || key === 'balance') {
            return decimal(value);
        }
        return value;
    };

    return post<FindWarehouseProductData, FindWarehouseProductResult>('findwarehouseproduct', data, replacer, reviver);
}
export interface SaveWarehouseData {
    warehouse_id?: number;
    warehouse_no?: number;
    warehouse_type_id?: number;
    warehouse_type_name?: string;
    branch_id?: number;
    branch_name?: string;
    account_code?: string;
    lang: string;
    warehouse_name?: string;
    warehouse_description?: string;
    disabled: number;
    contact_name?: string;
    contact_address?: string;
    contact_address1?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    postal_code?: string;
    companyphone?: string;
    companymobile?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;

}

export interface SaveWarehouseResult {
    warehouse_id: number;
    warehouse_no?: number;
}

export const saveWarehouse = async (data: SaveWarehouseData) => post<SaveWarehouseData, SaveWarehouseResult>('savewarehouse', data);
export interface RemoveWarehouseData {
    warehouse_id: number;
}

export const removeWarehouse = async (data: RemoveWarehouseData) => post<RemoveWarehouseData>('removewarehouse', data);



export interface SearchCategoriesData {
    search?: string;
    active: boolean;
    lang: string;
    parent_category_id?: number;
}

export interface CategoryData {
    category_id: number;
    category_name?: string;
    category_description?: string;
    parent_category_id?: number;
    parent_category_name?: string;
    children?: CategoryData[];
    account_code?: string;
}
export interface SearchCategoriesResult {
    categories: CategoryData[];
}

export const searchCategories = async (data: SearchCategoriesData) => post<SearchCategoriesData, SearchCategoriesResult>('searchcategories', data);
export interface FindCategoryData {
    category_id: number;
    active: boolean;
    lang: string;
}
export interface FindCategoryResult {
    category_id: number;
    category_name?: string;
    category_description?: string;
    disabled: number;
    parent_category_id?: number;
    parent_category_name?: string;
    children?: CategoryData[];
    account_code?: string;
}

export const findCategory = async (data: FindCategoryData) => post<FindCategoryData, FindCategoryResult>('findcategory', data);

export interface SaveCategoryData {
    category_id?: number;
    parent_category_id?: number;
    lang: string;
    category_name?: string;
    category_description?: string;
    account_code?: string;
    disabled: number;
}

export interface SaveCategoryResult {
    category_id: number;
}

export const saveCategory = async (data: SaveCategoryData) => post<SaveCategoryData, SaveCategoryResult>('savecategory', data);
export interface RemoveCategoryData {
    category_id: number;
}

export const removeCategory = async (data: RemoveCategoryData) => post<RemoveCategoryData>('removecategory', data);

export interface SearchPromotionsData {
    search?: string;
    active: boolean;
    lang: string;
}

export enum PromotionType {
    FIXED_AMOUNT = 0,
    FIXED_AMOUNT_AFTER_TAX = 1,
    PERCENTAGE_OF_PRICE = 2,
    PERCENTAGE_OF_PRICE_AFTER_TAX = 3
}
export interface PromotionData {
    promotion_id?: number;
    promotion_name?: string;
    promotion_description?: string;
    applies_to?: AppliesToType;
    promotion_type?: PromotionType;
    amount?: Decimal;
    promotion_account?: string;
    purchasing_account?: string;
    disabled: number;
}
export interface SearchPromotionsResult {
    promotions: PromotionData[];
}

export const searchPromotions = async (data: SearchPromotionsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }
        return value;
    };
    return post<SearchPromotionsData, SearchPromotionsResult>('searchpromotions', data, undefined, reviver);
}
export interface FindPromotionData {
    promotion_id: number;
    lang: string;
}
export const findPromotion = async (data: FindPromotionData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }

        return value;
    };
    return post<FindPromotionData, PromotionData>('findpromotion', data, undefined, reviver);
}

export interface SavePromotionData extends PromotionData {
    lang: string;
}

export interface SavePromotionResult {
    promotion_id: number;
}

export const savePromotion = async (data: SavePromotionData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }
        return value;
    };
    return post<SavePromotionData, SavePromotionResult>('savepromotion', data, undefined, reviver);
}
export interface RemovePromotionData {
    promotion_id: number;
}

export const removePromotion = async (data: RemovePromotionData) => post<RemovePromotionData>('removepromotion', data);

export interface SearchCostCentersData {
    search?: string;
    active: boolean;
    lang: string;
    parent_costcenter_code?: string;
    leafOnly?: boolean;

}

export interface CostCenterData {
    costcenter_code: string;
    costcenter_name?: string;
    costcenter_description?: string;
    parent_costcenter_code?: string;
    parent_costcenter_name?: string;
    closing_account?: string;
    children?: CostCenterData[];
}
export interface SearchCostCentersResult {
    costcenters: CostCenterData[];
}

export const searchCostCenters = async (data: SearchCostCentersData) => post<SearchCostCentersData, SearchCostCentersResult>('searchcostcenters', data);
export interface FindCostCenterData {
    costcenter_code?: string;
    parent_costcenter_code?: string;
    active?: boolean;
    lang: string;
}
export interface FindCostCenterResult {
    costcenter_code: string;
    costcenter_name?: string;
    costcenter_description?: string;
    disabled: number;
    parent_costcenter_code?: string;
    parent_costcenter_name?: string;
    closing_account?: string;
    children?: CostCenterData[];
}

export const findCostCenter = async (data: FindCostCenterData) => post<FindCostCenterData, FindCostCenterResult>('findcostcenter', data);

export interface SaveCostCenterData {
    op?: string;

    costcenter_code?: string;
    parent_costcenter_code?: string;
    parent_costcenter_name?: string;
    lang: string;
    costcenter_name?: string;
    costcenter_description?: string;
    closing_account?: string;
    disabled: number;
}

export interface SaveCostCenterResult {
    costcenter_code: string;
}

export const saveCostCenter = async (data: SaveCostCenterData) => post<SaveCostCenterData, SaveCostCenterResult>('savecostcenter', data);
export interface RemoveCostCenterData {
    costcenter_code: string;
}

export const removeCostCenter = async (data: RemoveCostCenterData) => post<RemoveCostCenterData>('removecostcenter', data);

export interface AccountData {
    account_code: string;
    account_name?: string;
    parent_account_code?: string;
    parent_account_name?: string;
    account_description?: string;
    closing_account?: string;
    account_nature?: number;
    account_type?: number;
    account_children?: number;
    costcenter_required?: number;
    revalue_currency?: number;
    children?: AccountData[];
    costcenters?: AccountCostCenterData[];
}

export interface SearchAccountsData {
    search?: string;
    active: boolean;
    lang: string;
    exclude?: string[];
    parent_account_code?: string;
    leafOnly?: boolean;
}

export interface SearchAccountsResult {
    accounts: AccountData[];
}

export const searchAccounts = async (data: SearchAccountsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'distribute_pct') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchAccountsData, SearchAccountsResult>('searchaccounts', data, undefined, reviver);
}
export interface FindAccountData {
    account_code: string;
    parent_account_code?: string;
    active?: boolean;
    lang: string;
}

export interface FindAccountResult {
    account_code: string;
    account_name: string;
    parent_account_code?: string;
    parent_account_name?: string;
    account_description?: string;
    account_type: number;
    account_nature: number;
    revalue_currency: number;
    closing_account?: string;
    costcenter_required: number;
    costcenters: AccountCostCenterData[];
    lang?: string;
    disabled: number;
    children?: AccountData[];
}

export const findAccount = async (data: FindAccountData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'distribute_pct') {
            return decimal(value);
        }

        return value;
    };

    return post<FindAccountData, FindAccountResult>('findaccount', data, undefined, reviver);
}

export interface SaveAccountCostCenterData {

    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface AccountCostCenterData extends SaveAccountCostCenterData {
    costcenter_name?: string;
}



export interface SaveAccountData {
    op?: string;
    account_code?: string;
    lang?: string;
    account_name?: string;
    parent_account_code?: string;
    parent_account_name?: string;
    account_description?: string;
    account_type: number;
    account_nature: number;
    revalue_currency: number;
    closing_account?: string;
    costcenter_required?: number;
    costcenters: SaveAccountCostCenterData[];
    disabled: number;
}

export interface SaveAccountResult {
    account_code?: string;
    costcenters?: SaveAccountCostCenterData[];
}

export const saveAccount = async (data: SaveAccountData) => post<SaveAccountData, SaveAccountResult>('saveaccount', data);
export interface RemoveAccountData {
    account_code: string;
}

export const removeAccount = async (data: RemoveAccountData) => post<RemoveAccountData>('removeaccount', data);


export enum EmployeeRole {
    ALL = 0,
    INVENTORY_AGENT = 1,
    CASHIER = 2,
    CUSTODIAN = 3,
    SALES_REP = 4,
    PURCHASING_AGENT = 5
}
export interface SearchEmployeesData {
    search?: string;
    active: boolean;
    employee_role: EmployeeRole;

    lang: string;
}

export interface EmployeeData {
    employee_id: number;
    employee_number: number;
    media_id?: number;
    employee_email?: string;
    employee_name?: string;
    phone?: string;
    mobile?: string;
    inventory_agent: number;
    cashier: number;
    custodian: number;
    sales_rep: number;
    purchasing_agent: number;
    jobposition_id?: number;
    jobposition_name?: string;
    department_id?: number;
    department_name?: string;
    employee_type_id?: number;
    employee_type_name?: string;
    manager_employee?: number;
    manager_employee_name?: string;
    account_code?: string;
}

export interface SearchEmployeesResult {
    employees: EmployeeData[];
}

export const searchEmployees = async (data: SearchEmployeesData) => post<SearchEmployeesData, SearchEmployeesResult>('searchemployees', data);
export interface FindEmployeeData {
    employee_id: number;
    lang: string;
}

export interface NoteData {
    note_id?: number;
    user_id?: number;
    note?: string;
}

export interface Notes {
    notes?: NoteData[];
}
export interface FindEmployeeResult {
    employee_id: number;
    employee_name?: string;
    employee_email?: string;
    employee_number?: number;
    media_id?: number;
    date_of_birth?: Date;
    national_id?: string;
    nationality_id?: number;
    nationality?: string;
    gender?: number;
    address1?: string;
    address2?: string;
    city_id?: number;
    city_name?: string;
    governerate_id?: number;
    governerate_name?: string;
    country_id?: number;
    country_name?: string;
    salesteam_id: number;
    salesteam_name: string;
    postal_code?: string;
    phone?: string;
    mobile?: string;
    inventory_agent: number;
    cashier: number;
    custodian: number;
    sales_rep: number;
    purchasing_agent: number;
    jobposition_id?: number;
    jobposition_name?: string;
    department_id?: number;
    department_name?: string;
    employee_type_id?: number;
    employee_type_name?: string;
    manager_employee?: number;
    manager_employee_name?: string;
    notes?: NoteData[];
    account_code?: string;
    disabled: number;
    media: Media[];
}

export const findEmployee = async (data: FindEmployeeData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'date_of_birth') {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'date_of_birth') {
            return date(value);
        }
        return value;
    };

    return post<FindEmployeeData, FindEmployeeResult>('findemployee', data, replacer, reviver);
}

export interface Media {
    media_id: number;
    file_name: string;
    mime_type: string;
    file_size: number;
}
export interface SaveEmployeeData {
    employee_id?: number;
    lang: string;
    employee_name?: string;
    employee_email?: string;
    employee_number?: number;
    media_id?: number;
    date_of_birth?: Date;
    national_id?: string;
    nationality_id?: number;
    nationality?: string;
    gender?: number;
    address1?: string;
    address2?: string;
    city_id?: number;
    governerate_id?: number;
    country_id?: number;
    salesteam_id?: number;
    postal_code?: string;
    phone?: string;
    mobile?: string;
    inventory_agent: number;
    cashier: number;
    custodian: number;
    sales_rep: number;
    purchasing_agent: number;
    jobposition_id?: number;
    jobposition_name?: string;
    department_id?: number;
    department_name?: string;
    employee_type_id?: number;
    employee_type_name?: string;
    manager_employee?: number;
    manager_employee_name?: string;
    account_code?: string;
    disabled: number;
    media?: Media[];
    upload_picture: boolean;
    files?: File[];
}

export interface SaveEmployeeResult {
    employee_id: number;
    media_id: number;
    media: Media[],
    employee_number: number;
}

export const saveEmployee = async (data: SaveEmployeeData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'date_of_birth') {
            return isoDate(value);
        }
        return value;
    };

    return postMP<SaveEmployeeData, SaveEmployeeResult>('saveemployee', data, replacer);
};

export interface SaveEmployeeNote {
    employee_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveEmployeeNote = async (data: SaveEmployeeNote) => {
    return post<SaveEmployeeNote, SaveNoteResult>('saveemployeenote', data);
};

export interface RemoveEmployeeData {
    employee_id: number;
}

export const removeEmployee = async (data: RemoveEmployeeData) => post<RemoveEmployeeData>('removeemployee', data);

export interface RemoveNote {
    note_id: number;
}

export const removeEmployeeNote = async (data: RemoveNote) => post<RemoveNote>('removeemployeenote', data);

export interface SearchRegionsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface RegionData {
    region_id: number;
    region_name?: string;
    region_description?: string;
}
export interface SearchRegionsResult {
    regions: RegionData[];
}

export const searchRegions = async (data: SearchRegionsData) => post<SearchRegionsData, SearchRegionsResult>('searchregions', data);
export interface FindRegionData {
    region_id: number;
    lang: string;
}
export interface FindRegionResult {
    region_id: number;
    region_name?: string;
    region_description?: string;
    disabled: number;
}

export const findRegion = async (data: FindRegionData) => post<FindRegionData, FindRegionResult>('findregion', data);

export interface SaveRegionData {
    region_id: number;
    lang: string;
    region_name: string;
    region_description?: string;
    disabled: number;
}

export interface SaveRegionResult {
    region_id: number;
}

export const saveRegion = async (data: SaveRegionData) => post<SaveRegionData, SaveRegionResult>('saveregion', data);
export interface RemoveRegionData {
    region_id: number;
}

export const removeRegion = async (data: RemoveRegionData) => post<RemoveRegionData>('removeregion', data);

export interface SearchOrganizationsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface OrganizationData {
    organization_id: number;
    organization_name?: string;
    organization_email?: string;
    organization_description?: string;
}
export interface SearchOrganizationsResult {
    organizations: OrganizationData[];
}

export const searchOrganizations = async (data: SearchOrganizationsData) => post<SearchOrganizationsData, SearchOrganizationsResult>('searchorganizations', data);
export interface FindOrganizationData {
    organization_id: number;
    lang: string;
    active: boolean;
}
export interface FindOrganizationResult {
    organization_id: number;
    organization_name?: string;
    business_registeration?: string;
    currency_code?: string;
    currency_name?: string;
    currency_symbol?: string;
    fraction_digits?: number;
    financial_year?: number;
    start_month?: number;
    gl_account_pattern?: string;
    costcenter_pattern?: string;
    sc_subjournal_id?: number;
    sc_subjournal_name?: string;
    sc_posting_method?: number;
    cb_subjournal_id?: number;
    cb_subjournal_name?: string;
    ar_subjournal_id?: number;
    ar_subjournal_name?: string;
    ar_posting_method?: number;
    ap_subjournal_id?: number;
    ap_subjournal_name?: string;
    ap_posting_method?: number;
    branches?: BranchData[];
    media_id?: number;
    media: Media[];
}

export const findOrganization = async (data: FindOrganizationData) => post<FindOrganizationData, FindOrganizationResult>('findorganization', data);

export interface SaveOrganizationData {
    organization_id: number;
    lang: string;
    organization_name?: string;
    business_registeration?: string;
    currency_code?: string;
    financial_year?: number;
    start_month?: number;
    gl_account_pattern?: string;
    costcenter_pattern?: string;
    sc_subjournal_id?: number;
    sc_posting_method?: number;
    cb_subjournal_id?: number;
    ar_subjournal_id?: number;
    ar_posting_method?: number;
    ap_subjournal_id?: number;
    ap_posting_method?: number;
    media_id?: number;
    media: Media[];
    upload_picture: boolean;
    files?: File[];
}

export interface SaveOrganizationResult {
    organization_id: number;
    media_id: number;
    media: Media[]
}

export const saveOrganization = async (data: SaveOrganizationData) => postMP<SaveOrganizationData, SaveOrganizationResult>('saveorganization', data);

export interface RemoveOrganizationData {
    organization_id: number;
}

export const removeOrganization = async (data: RemoveOrganizationData) => post<RemoveOrganizationData>('removeorganization', data);

export interface SearchBankAccountsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface BankAccountData {
    bank_account_id: number;
    bank_account_number?: string;
    bank_identifier_code?: string;
    bank_account_name?: string;
    bank_account_description?: string;
    bank_id?: number;
    bank_name?: string;
    bank_account?: string;
    bank_account_code_name?: string;
    cheques_under_collection?: string;
    cheques_under_collection_name?: string;
    credit_cheques?: string;
    credit_cheques_name?: string;
    outgoing_account?: string;
    outgoing_account_name?: string;
}
export interface SearchBankAccountsResult {
    bank_accounts: BankAccountData[];
}

export const searchBankAccounts = async (data: SearchBankAccountsData) => post<SearchBankAccountsData, SearchBankAccountsResult>('searchbank_accounts', data);
export interface FindBankAccountData {
    bank_account_id: number;
    lang: string;
}
export interface FindBankAccountResult {
    bank_account_id: number;
    bank_account_name?: string;
    bank_account_description?: string;
    bank_account_number?: string;
    bank_identifier_code?: string;
    bank_id?: number;
    bank_name?: string;
    bank_account?: string;
    bank_account_code_name?: string;
    cheques_under_collection?: string;
    cheques_under_collection_name?: string;
    credit_cheques?: string;
    credit_cheques_name?: string;
    outgoing_account?: string;
    outgoing_account_name?: string;
    disabled: number;
}

export const findBankAccount = async (data: FindBankAccountData) => post<FindBankAccountData, FindBankAccountResult>('findbank_account', data);

export interface SaveBankAccountData {
    bank_account_id?: number;
    lang: string;
    bank_account_name?: string;
    bank_account_description?: string;
    bank_account_number?: string;
    bank_identifier_code?: string;
    bank_id?: number;
    bank_account?: string;
    cheques_under_collection?: string;
    credit_cheques?: string;
    outgoing_account?: string;
    disabled: number;
}

export interface SaveBankAccountResult {
    bank_account_id: number;
}

export const saveBankAccount = async (data: SaveBankAccountData) => post<SaveBankAccountData, SaveBankAccountResult>('savebank_account', data);
export interface RemoveBankAccountData {
    bank_account_id: number;
}

export const removeBankAccount = async (data: RemoveBankAccountData) => post<RemoveBankAccountData>('removebank_account', data);


export interface SearchCashBoxesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface CashBoxData {
    cashbox_id: number;
    cashbox_name?: string;
    cashbox_description?: string;
    cash_account?: string;
    cash_account_name?: string;
    cheques_account?: string;
    cheques_account_name?: string;
}
export interface SearchCashBoxesResult {
    cashboxes: CashBoxData[];
}

export const searchCashBoxes = async (data: SearchCashBoxesData) => post<SearchCashBoxesData, SearchCashBoxesResult>('searchcashboxes', data);
export interface FindCashBoxData {
    cashbox_id: number;
    cash_account?: string;
    cash_account_name?: string;
    cheques_account?: string;
    cheques_account_name?: string;
    lang: string;
}
export interface FindCashBoxResult {
    cashbox_id: number;
    cashbox_name?: string;
    cashbox_description?: string;
    cash_account?: string;
    cash_account_name?: string;
    cheques_account?: string;
    cheques_account_name?: string;
    disabled: number;
}

export const findCashBox = async (data: FindCashBoxData) => post<FindCashBoxData, FindCashBoxResult>('findcashbox', data);

export interface SaveCashBoxData {
    cashbox_id?: number;
    cashbox_name?: string;
    cashbox_description?: string;
    cash_account?: string;
    cheques_account?: string;
    lang: string;
    disabled: number;
}

export interface SaveCashBoxResult {
    cashbox_id: number;
}

export const saveCashBox = async (data: SaveCashBoxData) => post<SaveCashBoxData, SaveCashBoxResult>('savecashbox', data);
export interface RemoveCashBoxData {
    cashbox_id: number;
}

export const removeCashBox = async (data: RemoveCashBoxData) => post<RemoveCashBoxData>('removecashbox', data);

export enum PropertyType {
    TEXT = 0,
    NUMBER = 1,
    DATE = 2,
    LOOKUP = 3
}
export interface SearchPropertiesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface PropertyValueData {
    property_value_id?: number;
    property_value_name?: string;
}
export interface PropertyData {
    property_id: number;
    property_name?: string;
    property_description?: string;
    property_type?: PropertyType;
}
export interface SearchPropertiesResult {
    properties: PropertyData[];
}

export const searchProperties = async (data: SearchPropertiesData) => post<SearchPropertiesData, SearchPropertiesResult>('searchproperties', data);

export interface SearchPropertyValuesData {
    property_id: number;
    search?: string;
    lang: string;
}

export interface SearchPropertyValuesResult {
    property_values: PropertyValueData[];
}

export const searchPropertyValues = async (data: SearchPropertyValuesData) => post<SearchPropertyValuesData, SearchPropertyValuesResult>('searchpropertyvalues', data);

export interface FindPropertyData {
    property_id: number;
    lang: string;
}


export interface FindPropertyResult {
    property_id: number;
    property_name?: string;
    property_description?: string;
    property_type?: PropertyType;
    property_values?: PropertyValueData[];
    disabled: number;
}

export const findProperty = async (data: FindPropertyData) => post<FindPropertyData, FindPropertyResult>('findproperty', data);

export interface SavePropertyData {
    property_id?: number;
    lang: string;
    property_name?: string;
    property_description?: string;
    property_type?: PropertyType;
    property_values?: PropertyValueData[];
    disabled: number;
}

export interface SavePropertyResult {
    property_id: number;
    property_values?: PropertyValueData[];
}

export const saveProperty = async (data: SavePropertyData) => post<SavePropertyData, SavePropertyResult>('saveproperty', data);
export interface RemovePropertyData {
    property_id: number;
}

export const removeProperty = async (data: RemovePropertyData) => post<RemovePropertyData>('removeproperty', data);

export enum ProductType {
    ITEM = 0,
    SERVICE = 1,
    COLLECTED = 2,
}



export enum ProductUsage {
    SELLABLE = 0,
    CONSUMABLE = 1,
    ASSET = 2
}
export interface ProductData {
    product_code: string;
    product_name?: string;
    product_description?: string;
    category_id?: number;
    category_name?: string;
    product_type?: ProductType;
    product_usage?: ProductUsage;
    store_unit_type_id?: number;
    store_unit_type_name?: string;
    store_unit_type_symbol?: string;
    sales_unit_type_id?: number;
    sales_unit_type_name?: string;
    sales_unit_type_symbol?: string;
    purchase_unit_type_id?: number;
    purchase_unit_type_name?: string;
    purchase_unit_type_symbol?: string;
    tax_id?: number;
    tax_name?: string;
    use_serial?: number;
    use_expiry?: number;
    use_batch?: number;
    cost?: Decimal;
    avg_cost?: Decimal;
    reorder_level?: Decimal;
    max_level?: Decimal;
    min_level?: Decimal;
    media?: ProductMedia[];
    product_vendors?: ProductVendorsData[];
    product_components?: ProductComponentData[];
    selling_amount?: Decimal;
    pricelist_id?: number;
    customer_type_id?: number;

}


export interface SearchWarehouseProductData extends ProductData {
    available?: Decimal;
}


export interface NoteData {
    note_id?: number;
    user_id?: number;
    note?: string;
}

export interface Notes {
    notes?: NoteData[];
}

export enum VendorRole {
    DISTRIBUTOR = 0,
    MANUFACTURER = 1,
}


export interface ProductVendorsData {
    product_vendor_id?: number;
    vendor_id?: number;
    vendor_name?: string;
    vendor_no?: number;
    vendor_role?: VendorRole;
    vendor_product_code?: string;
}

export interface ProductComponentData {
    product_component_id?: number;
    main_product_code?: string;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_symbol?: string;
    unit_type_name?: string;
    qty?: Decimal;
}

export interface SearchProductsData {
    search?: string;
    active: boolean;
    lang: string;
    item?: boolean;
    service?: boolean;
    collected?: boolean;
    sellable?: boolean;
    consumable?: boolean;
    asset?: boolean;
    exclude?: string[];
    use_serial?: number;
    use_expiry?: number;
    use_batch?: number;
    avg_cost?: Decimal;
    reorder_level?: Decimal;
    max_level?: Decimal;
    min_level?: Decimal;
    customer_id?: number;
    currency_code?: string;
    customer_type_id?: number;
}
export interface SearchWarehouseProductsData extends SearchProductsData {
    warehouse_id: number;
}
export interface SearchProductsResult {
    products: ProductData[];
    product_vendors?: ProductVendorsData[];

}


export interface SearchWarehouseProductsResult {
    products: SearchWarehouseProductData[];
}


export const searchProducts = async (data: SearchProductsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'available' || key === 'avg_cost' || key === 'cost' || key === 'selling_amount') {
            return decimal(value);
        }
        return value;
    }
    return post<SearchProductsData, SearchProductsResult>('searchproducts', data, undefined, reviver);
}
export const searchWarehouseProducts = async (data: SearchWarehouseProductsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'available' || key === 'avg_cost' || key === 'cost' || key === 'selling_amount') {
            return decimal(value);
        }
        return value;
    }
    return post<SearchWarehouseProductsData, SearchWarehouseProductsResult>('searchwarehouseproducts', data, undefined, reviver);
}


export interface SearchPriceProductData extends ProductData {
    available?: Decimal;
}

export interface SearchPriceProductsData extends SearchProductsData {
    customer_type_id?: number;
    product_code?: string;
    unit_type_id?: number;
}

export interface SearchPriceProductsResult {
    products: SearchPriceProductData[];
}

export const searchPriceProduct = async (data: SearchPriceProductsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'available' || key === 'avg_cost' || key === 'cost' || key === 'selling_amount') {
            return decimal(value);
        }
        return value;
    }
    return post<SearchPriceProductsData, SearchPriceProductsResult>('searchpriceproducts', data, undefined, reviver);
}



export enum MediaUsage {
    IMAGE = 0,
    ATTACHMENT = 1
}
export interface ProductMedia extends Media {
    media_usage: MediaUsage;
    default_image: number;
}
export interface FindProductData {
    product_code: string;
    lang: string;
    product_vendors?: ProductVendorsData[];
    product_components?: ProductComponentData[];
}
export interface ProductPropertyData extends PropertyData {
    product_property_id?: number;
    property_value_id?: number;
    property_value_date?: Date;
    property_value_number?: number;
    property_value_name?: string;
    property_value_text?: string;
}
export interface FindProductResult extends ProductData {
    disabled: number;
    use_serial?: number;
    use_expiry?: number;
    use_batch?: number;
    notes?: NoteData[];
    avg_cost?: Decimal;
    reorder_level?: Decimal;
    max_level?: Decimal;
    min_level?: Decimal;
    valuation_method: ValuationMethod;
    properties?: ProductPropertyData[];
    product_vendors?: ProductVendorsData[];
    product_components?: ProductComponentData[];
    warehouse_products?: FindWarehouseProductBalances[];
}


export interface FindWarehouseProductBalances {
    warehouse_id?: number;
    warehouse_name?: string;
    replenishment?: Decimal;
    issued?: Decimal;
    available?: Decimal;
}
export const findProduct = async (data: FindProductData) => {
    const reviver = (key: string, value: any): any => {
        if ((key === 'replenishment' || key === 'issued' || key === 'available' || key === 'reorder_level' || key === 'avg_cost' ||
            key === 'max_level' || key === 'min_level') && value) {
            return decimal(value);
        }
        return value;
    };

    return post<FindProductData, FindProductResult>('findproduct', data, undefined, reviver);
}


export interface SaveProductPropertyData {
    product_property_id?: number;
    property_id?: number;
    property_type?: PropertyType;
    property_value_id?: number;
    property_value_date?: Date;
    property_value_number?: number;
    property_value_text?: string;
}

export enum ValuationMethod {
    AVERAGE = 0,
    FIFO = 1,
    LIFO = 2
}
export interface SaveProductData {
    op?: string;
    product_code?: string;
    lang?: string;
    product_name?: string;
    product_description?: string;
    category_id?: number;
    product_type?: ProductType;
    product_usage?: ProductUsage;
    store_unit_type_id?: number;
    sales_unit_type_id?: number;
    purchase_unit_type_id?: number;
    tax_id?: number;
    use_serial?: number;
    use_expiry?: number;
    use_batch?: number;
    avg_cost?: Decimal;
    reorder_level?: Decimal;
    max_level?: Decimal;
    min_level?: Decimal;
    valuation_method: ValuationMethod;
    disabled?: number;
    media?: ProductMedia[];
    files?: File[];
    image_count: number;
    properties?: SaveProductPropertyData[];
    product_vendors?: ProductVendorsData[];
    product_components?: ProductComponentData[];
}

export interface SaveProductResult {
    product_code?: string;
    category_id?: number;
    unit_type_id?: number;
    tax_id?: number;
    use_serial?: number;
    use_expiry?: number;
    use_batch?: number;
    avg_cost?: Decimal;
    reorder_level?: Decimal;
    max_level?: Decimal;
    min_level?: Decimal;
    media?: ProductMedia[];
    properties?: ProductPropertyData[];
    product_vendors?: ProductVendorsData[];
    product_components?: ProductComponentData[];
}

export interface SaveProductNote {
    product_code: string;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveProductNote = async (data: SaveProductNote) => {
    return post<SaveProductNote, SaveNoteResult>('saveproductnote', data);
};

export const saveProduct = async (data: SaveProductData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'property_value_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'property_value_date' && value) {
            return date(value);
        }
        return value;
    };
    return postMP<SaveProductData, SaveProductResult>('saveproduct', data, replacer, reviver);
}
export interface RemoveProductData {
    product_code: string;
}

export const removeProductNote = async (data: RemoveNote) => post<RemoveNote>('removeproductnote', data);

export const removeProduct = async (data: RemoveProductData) => post<RemoveProductData>('removeproduct', data);

export interface SearchDepartmentsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface DepartmentData {
    department_id: number;
    department_name?: string;
    department_description?: string;
}
export interface SearchDepartmentsResult {
    departments: DepartmentData[];
}

export const searchDepartments = async (data: SearchDepartmentsData) => post<SearchDepartmentsData, SearchDepartmentsResult>('searchdepartments', data);
export interface FindDepartmentData {
    department_id: number;
    lang: string;
}
export interface FindDepartmentResult {
    department_id: number;
    department_name?: string;
    department_description?: string;
    disabled: number;
}

export const findDepartment = async (data: FindDepartmentData) => post<FindDepartmentData, FindDepartmentResult>('finddepartment', data);

export interface SaveDepartmentData {
    department_id?: number;
    lang: string;
    department_name?: string;
    department_description?: string;
    disabled: number;
}

export interface SaveDepartmentResult {
    department_id: number;
}

export const saveDepartment = async (data: SaveDepartmentData) => post<SaveDepartmentData, SaveDepartmentResult>('savedepartment', data);
export interface RemoveDepartmentData {
    department_id: number;
}

export const removeDepartment = async (data: RemoveDepartmentData) => post<RemoveDepartmentData>('removedepartment', data);

export interface SearchJobPositionsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface JobPositionData {
    jobposition_id: number;
    jobposition_name?: string;
    jobposition_description?: string;
}
export interface SearchJobPositionsResult {
    jobpositions: JobPositionData[];
}

export const searchJobPositions = async (data: SearchJobPositionsData) => post<SearchJobPositionsData, SearchJobPositionsResult>('searchjobpositions', data);
export interface FindJobPositionData {
    jobposition_id: number;
    lang: string;
}
export interface FindJobPositionResult {
    jobposition_id: number;
    jobposition_name?: string;
    jobposition_description?: string;
    disabled: number;
}

export const findJobPosition = async (data: FindJobPositionData) => post<FindJobPositionData, FindJobPositionResult>('findjobposition', data);

export interface SaveJobPositionData {
    jobposition_id?: number;
    lang: string;
    jobposition_name?: string;
    jobposition_description?: string;
    disabled: number;
}

export interface SaveJobPositionResult {
    jobposition_id: number;
}

export const saveJobPosition = async (data: SaveJobPositionData) => post<SaveJobPositionData, SaveJobPositionResult>('savejobposition', data);
export interface RemoveJobPositionData {
    jobposition_id: number;
}

export const removeJobPosition = async (data: RemoveJobPositionData) => post<RemoveJobPositionData>('removejobposition', data);


export interface SearchEmployee_TypesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface Employee_TypeData {
    employee_type_id: number;
    employee_type_name?: string;
    employee_type_description?: string;
}
export interface SearchEmployee_TypesResult {
    employee_types: Employee_TypeData[];
}

export const searchEmployee_Types = async (data: SearchEmployee_TypesData) => post<SearchEmployee_TypesData, SearchEmployee_TypesResult>('searchemployee_types', data);
export interface FindEmployee_TypeData {
    employee_type_id: number;
    lang: string;
}
export interface FindEmployee_TypeResult {
    employee_type_id: number;
    employee_type_name?: string;
    employee_type_description?: string;
    disabled: number;
}

export const findEmployee_Type = async (data: FindEmployee_TypeData) => post<FindEmployee_TypeData, FindEmployee_TypeResult>('findemployee_type', data);

export interface SaveEmployee_TypeData {
    employee_type_id?: number;
    lang: string;
    employee_type_name?: string;
    employee_type_description?: string;
    disabled: number;
}

export interface SaveEmployee_TypeResult {
    employee_type_id: number;
}

export const saveEmployee_Type = async (data: SaveEmployee_TypeData) => post<SaveEmployee_TypeData, SaveEmployee_TypeResult>('saveemployee_type', data);
export interface RemoveEmployee_TypeData {
    employee_type_id: number;
}

export const removeEmployee_Type = async (data: RemoveEmployee_TypeData) => post<RemoveEmployee_TypeData>('removeemployee_type', data);


export interface SearchTaxesData {
    search?: string;
    active: boolean;
    lang: string;
}

export enum AppliesToType {
    PRODUCT = 0,
    DOCUMENT = 1
}
export enum TaxType {
    FIXED_AMOUNT = 0,
    PERCENTAGE_OF_PRICE = 1,
    PERCENTAGE_OF_PRICE_TAX_INCLUDED = 2
}
export interface TaxData {
    tax_id?: number;
    tax_name?: string;
    tax_description?: string;
    applies_to?: AppliesToType;
    tax_type?: TaxType;
    amount?: Decimal;
    included_in_price?: number;
    purchase_tax_account?: string;
    sales_tax_account?: string;
    disabled: number
}
export interface SearchTaxesResult {
    taxes: TaxData[];
}

export const searchTaxes = async (data: SearchTaxesData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }
        return value;
    };
    return post<SearchTaxesData, SearchTaxesResult>('searchtaxes', data, undefined, reviver);
}
export interface FindTaxData {
    tax_id: number;
    lang: string;
}

export const findTax = async (data: FindTaxData) => {

    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }

        return value;
    };


    return post<FindTaxData, TaxData>('findtax', data, undefined, reviver);
}
export interface SaveTaxData extends TaxData {
    lang: string;
}

export interface SaveTaxResult {
    tax_id: number;
}

export const saveTax = async (data: SaveTaxData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return value.toString();
        }
        return value;
    };

    return post<SaveTaxData, SaveTaxResult>('savetax', data, replacer);
};

export interface RemoveTaxData {
    tax_id: number;
}

export const removeTax = async (data: RemoveTaxData) => post<RemoveTaxData>('removetax', data);

export interface SearchPaymentTermsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface PaymentTermData {
    payment_term_id: number;
    payment_term_name?: string;
    payment_term_description?: string;
    payment_term?: number;
    creditdays?: number;
    downpayment?: Decimal;
    number_of_days?: number;
    number_of_installments?: number;
    installment_duration?: number;
    day_of_month?: Date;
    lang?: string;

}
export interface SearchPaymentTermsResult {
    payment_terms: PaymentTermData[];
}

export const searchPaymentTerms = async (data: SearchPaymentTermsData) => post<SearchPaymentTermsData, SearchPaymentTermsResult>('searchpayment_terms', data);
export interface FindPaymentTermData {
    payment_term_id: number;
    lang: string;
}
export interface FindPaymentTermResult {
    payment_term_id: number;
    payment_term_name?: string;
    payment_term_description?: string;
    payment_term?: number;
    creditdays?: number;
    downpayment?: Decimal;
    number_of_days?: number;
    number_of_installments?: number;
    installment_duration?: number;
    day_of_month?: Date;
    lang?: string;
    disabled: number;
}

export const findPaymentTerm = async (data: FindPaymentTermData) => post<FindPaymentTermData, FindPaymentTermResult>('findpayment_term', data);

export interface SavePaymentTermData {
    payment_term_id?: number;
    lang: string;
    payment_term_name?: string;
    payment_term_description?: string;
    payment_term?: number;
    creditdays?: number;
    downpayment?: Decimal;
    number_of_days?: number;
    number_of_installments?: number;
    installment_duration?: number;
    day_of_month?: Date;
    disabled: number;
}

export interface SavePaymentTermResult {
    payment_term_id: number;
}

export const savePaymentTerm = async (data: SavePaymentTermData) => post<SavePaymentTermData, SavePaymentTermResult>('savepayment_term', data);
export interface RemovePaymentTermData {
    payment_term_id: number;
}

export const removePaymentTerm = async (data: RemovePaymentTermData) => post<RemovePaymentTermData>('removepayment_term', data);


export interface SalesteamMember {
    employee_id: number;
    employee_name?: string;
}
export interface SalesteamData {
    employee_id: number;
    employee_name: string;
    salesteam_id: number;
    salesteam_name?: string;
    salesteam_description?: string;
    members?: SalesteamMember[];
    disabled: number;
}

export interface SearchSalesteamsData {
    salesteam_id?: number;
    employee_id?: number;
    search?: string;
    active: boolean;
    lang: string;
}

export interface SearchSalesteamsResult {
    salesteams: SalesteamData[];
}

export const searchSalesteams = async (data: SearchSalesteamsData) => post<SearchSalesteamsData, SearchSalesteamsResult>('searchsalesteams', data);
export interface FindSalesteamData {
    salesteam_id: number;
    lang: string;
    active: boolean;
}
export interface FindSalesteamResult {
    employee_id: number;
    employee_name: string;
    salesteam_id: number;
    salesteam_name?: string;
    salesteam_description?: string;
    members?: SalesteamMember[];
    disabled: number;
}

export const findSalesteam = async (data: FindSalesteamData) => post<FindSalesteamData, FindSalesteamResult>('findsalesteam', data);

export interface SaveSalesteamData {
    salesteam_id?: number;
    employee_id?: number;

    lang: string;
    salesteam_name?: string;
    salesteam_description?: string;
    members?: number[];
    disabled: number;
}

export interface SaveSalesteamResult {
    salesteam_id: number;
    employee_id: number;
}

export const saveSalesteam = async (data: SaveSalesteamData) => post<SaveSalesteamData, SaveSalesteamResult>('savesalesteam', data);
export interface RemoveSalesteamData {
    salesteam_id: number;

}

export const removeSalesteam = async (data: RemoveSalesteamData) => post<RemoveSalesteamData>('removesalesteam', data);

export interface SearchActivitiesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface ActivityData {
    activity_id: number;
    activity_name?: string;
    activity_description?: string;
}
export interface SearchActivitiesResult {
    activities: ActivityData[];
}

export const searchActivities = async (data: SearchActivitiesData) => post<SearchActivitiesData, SearchActivitiesResult>('searchactivities', data);
export interface FindActivityData {
    activity_id: number;
    lang: string;
}
export interface FindActivityResult {
    activity_id: number;
    activity_name?: string;
    activity_description?: string;
    disabled: number;
}

export const findActivity = async (data: FindActivityData) => post<FindActivityData, FindActivityResult>('findactivity', data);

export interface SaveActivityData {
    activity_id?: number;
    lang: string;
    activity_name?: string;
    activity_description?: string;
    disabled: number;
}

export interface SaveActivityResult {
    activity_id: number;
}

export const saveActivity = async (data: SaveActivityData) => post<SaveActivityData, SaveActivityResult>('saveactivity', data);
export interface RemoveActivityData {
    activity_id: number;
}

export const removeActivity = async (data: RemoveActivityData) => post<RemoveActivityData>('removeactivity', data);


export interface SearchDeferredRevenuesData {
    search?: string;
    active: boolean;
    lang: string;
    today: Date;
}
export enum DeferredRevenueStatusType {
    DONE = 0,
    READY = 1,
    PENDING = 2
}

export interface DeferredRevenueData {
    deferred_revenue_id: number;
    deferred_revenue_no: number;
    deferred_revenue_name?: string;
    deferred_revenue_description?: string;
    revenue_type: RevenueType;
    start_date: Date;
    interval_type: number;
    currency_code: string;
    revenue_amount: Decimal;
    number_of_periods: number;
    period_type: number;
    status: DeferredRevenueStatusType;
    disabled: number;
    in_use?: number;
}
export interface SearchDeferredRevenuesResult {
    deferredrevenues: DeferredRevenueData[];
}

export const searchDeferredRevenues = async (data: SearchDeferredRevenuesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today') {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'revenue_amount') {
            return decimal(value);
        }
        if (key === 'start_date') {
            return date(value);
        }
        return value;
    };
    return post<SearchDeferredRevenuesData, SearchDeferredRevenuesResult>('searchdeferredrevenues', data, replacer, reviver);
}
export interface FindDeferredRevenueData {
    deferred_revenue_id: number;
    lang: string;
}


export enum RevenueType {
    DEFERRED = 0,
    ACCRUD = 1
}
export interface DeferredRevenueDetail {
    deferred_revenue_detail_id?: number;
    revenue_date?: Date;
    revenue_amount?: Decimal;
    journal_id?: number;
    journal_no?: number;
}
export interface DeferredRevenueJournalDetailCostCenter {
    deferred_revenue_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface DeferredRevenueJournalDetail {
    deferred_revenue_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: DeferredRevenueJournalDetailCostCenter[];
}
export interface FindDeferredRevenueResult {
    deferred_revenue_id: number;
    deferred_revenue_no: number;
    deferred_revenue_name?: string;
    deferred_revenue_description?: string;
    revenue_type?: RevenueType;
    start_date?: Date;
    interval_type?: IntervalType,
    currency_code?: string;
    revenue_amount?: Decimal;
    number_of_periods?: number;
    period_type?: PeriodType;
    subjournal_id?: number;
    subjournal_name?: string;
    subjournal_type?: number;
    disabled: number;
    details?: DeferredRevenueDetail[];
    journal_details?: DeferredRevenueJournalDetail[];
    notes?: NoteData[];
    media: Media[];
    in_use?: number;
    total_amount?: Decimal;
}

export const findDeferredRevenue = async (data: FindDeferredRevenueData) => {

    const reviver = (key: string, value: any): any => {
        if (key === 'revenue_amount' || key === 'debit' || key === 'credit' || key === 'distribute_pct'
            || key === 'total_amount') {
            return decimal(value);
        }
        if (key === 'start_date' || key === 'revenue_date') {
            return date(value);
        }

        return value;
    };


    return post<FindDeferredRevenueData, FindDeferredRevenueResult>('finddeferredrevenue', data, undefined, reviver);
}
export interface SaveDeferredRevenueDetail {
    deferred_revenue_detail_id?: number;
    revenue_date?: Date;
    revenue_amount?: Decimal;
}
export interface SaveDeferredRevenueJournalDetailCostCenter {
    deferred_revenue_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveDeferredRevenueJournalDetail {
    deferred_revenue_journal_detail_id?: number;
    account_code?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveDeferredRevenueJournalDetailCostCenter[];
}
export interface SaveDeferredRevenueData {
    deferred_revenue_id?: number;
    deferred_revenue_no?: number;
    lang: string;
    revenue_type?: RevenueType;
    deferred_revenue_name?: string;
    deferred_revenue_description?: string;
    start_date?: Date;
    interval_type?: IntervalType;
    currency_code?: string;
    revenue_amount?: Decimal;
    number_of_periods?: number;
    period_type?: PeriodType;
    subjournal_id?: number;
    disabled: number;
    details?: SaveDeferredRevenueDetail[];
    journal_details?: SaveDeferredRevenueJournalDetail[];
    media: Media[];
    files?: File[];
    financial_year_id?: number;
}

export interface SaveDeferredRevenueResult {
    deferred_revenue_id: number;
    deferred_revenue_no: number;
    details?: DeferredRevenueDetail[];
    journal_details?: DeferredRevenueJournalDetail[];
    media: Media[];
}

export const saveDeferredRevenue = async (data: SaveDeferredRevenueData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'revenue_amount' || key === 'debit' || key === 'credit' || key === 'distribute_pct') {
            return decimal(value);
        }
        if (key === 'start_date' || key === 'revenue_date') {
            return date(value);
        }
        return value;
    };
    const replacer = (key: string, value: any): any => {
        if (key === 'revenue_amount' || key === 'debit' || key === 'credit' || key === 'distribute_pct') {
            return value.toString();
        }
        if (key === 'start_date' || key === 'revenue_date') {
            return isoDate(value);
        }
        return value;
    };

    return postMP<SaveDeferredRevenueData, SaveDeferredRevenueResult>('savedeferredrevenue', data, replacer, reviver);
};
export interface SaveDeferredRevenueNote {
    deferred_revenue_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveDeferredRevenueNote = async (data: SaveDeferredRevenueNote) => {
    return post<SaveDeferredRevenueNote, SaveNoteResult>('savedeferredrevenuenote', data);
};

export interface RemoveDeferredRevenueData {
    deferred_revenue_id: number;
}
export const removeDeferredRevenue = async (data: RemoveDeferredRevenueData) => post<RemoveDeferredRevenueData>('removedeferredrevenue', data);
export const removeDeferredRevenueNote = async (data: RemoveNote) => post<RemoveNote>('removedeferredrevenuenote', data);

export interface SearchDeferredExpensesData {
    search?: string;
    active: boolean;
    lang: string;
    today: Date;
}
export enum DeferredExpenseStatusType {
    DONE = 0,
    READY = 1,
    PENDING = 2
}

export interface DeferredExpenseData {
    deferred_expense_id: number;
    deferred_expense_no: number;
    deferred_expense_name?: string;
    deferred_expense_description?: string;
    expense_type: ExpenseType;
    start_date: Date;
    interval_type: number;
    currency_code: string;
    expense_amount: Decimal;
    number_of_periods: number;
    period_type: number;
    status: DeferredExpenseStatusType;
    disabled: number;
    in_use?: number;
}
export interface SearchDeferredExpensesResult {
    deferredexpenses: DeferredExpenseData[];
}

export const searchDeferredExpenses = async (data: SearchDeferredExpensesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today') {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'expense_amount') {
            return decimal(value);
        }
        if (key === 'start_date') {
            return date(value);
        }
        return value;
    };
    return post<SearchDeferredExpensesData, SearchDeferredExpensesResult>('searchdeferredexpenses', data, replacer, reviver);
}
export interface FindDeferredExpenseData {
    deferred_expense_id: number;
    lang: string;
}

export enum IntervalType {
    CONSTATNT,
    CUSTOM
}

export enum PeriodType {
    YEAR = 0,
    MONTH = 1,
    WEEK = 2,
    DAY = 3
}
export enum ExpenseType {
    DEFERRED = 0,
    ACCRUD = 1
}
export interface DeferredExpenseDetail {
    deferred_expense_detail_id?: number;
    expense_date?: Date;
    expense_amount?: Decimal;
    journal_id?: number;
    journal_no?: number;
}
export interface DeferredExpenseJournalDetailCostCenter {
    deferred_expense_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface DeferredExpenseJournalDetail {
    deferred_expense_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: DeferredExpenseJournalDetailCostCenter[];
}
export interface FindDeferredExpenseResult {
    deferred_expense_id: number;
    deferred_expense_no: number;
    deferred_expense_name?: string;
    deferred_expense_description?: string;
    expense_type?: ExpenseType;
    start_date?: Date;
    interval_type?: IntervalType,
    currency_code?: string;
    expense_amount?: Decimal;
    number_of_periods?: number;
    period_type?: PeriodType;
    subjournal_id?: number;
    subjournal_name?: string;
    subjournal_type?: number;
    disabled: number;
    details?: DeferredExpenseDetail[];
    journal_details?: DeferredExpenseJournalDetail[];
    notes?: NoteData[];
    media: Media[];
    in_use?: number;
    total_amount?: Decimal;
}

export const findDeferredExpense = async (data: FindDeferredExpenseData) => {

    const reviver = (key: string, value: any): any => {
        if (key === 'expense_amount' || key === 'debit' || key === 'credit' || key === 'distribute_pct'
            || key === 'total_amount') {
            return decimal(value);
        }
        if (key === 'start_date' || key === 'expense_date') {
            return date(value);
        }

        return value;
    };


    return post<FindDeferredExpenseData, FindDeferredExpenseResult>('finddeferredexpense', data, undefined, reviver);
}
export interface SaveDeferredExpenseDetail {
    deferred_expense_detail_id?: number;
    expense_date?: Date;
    expense_amount?: Decimal;
}
export interface SaveDeferredExpenseJournalDetailCostCenter {
    deferred_expense_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveDeferredExpenseJournalDetail {
    deferred_expense_journal_detail_id?: number;
    account_code?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveDeferredExpenseJournalDetailCostCenter[];
}
export interface SaveDeferredExpenseData {
    deferred_expense_id?: number;
    lang: string;
    deferred_expense_no?: number;
    expense_type?: ExpenseType;
    deferred_expense_name?: string;
    deferred_expense_description?: string;
    start_date?: Date;
    interval_type?: IntervalType;
    currency_code?: string;
    expense_amount?: Decimal;
    number_of_periods?: number;
    period_type?: PeriodType;
    subjournal_id?: number;
    disabled: number;
    details?: SaveDeferredExpenseDetail[];
    journal_details?: SaveDeferredExpenseJournalDetail[];
    media: Media[];
    files?: File[];
    financial_year_id?: number;
}

export interface SaveDeferredExpenseResult {
    deferred_expense_id: number;
    deferred_expense_no?: number;
    details?: DeferredExpenseDetail[];
    journal_details?: DeferredExpenseJournalDetail[];
    media: Media[];
}

export const saveDeferredExpense = async (data: SaveDeferredExpenseData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'expense_amount' || key === 'debit' || key === 'credit' || key === 'distribute_pct') {
            return decimal(value);
        }
        if (key === 'start_date' || key === 'expense_date') {
            return date(value);
        }
        return value;
    };
    const replacer = (key: string, value: any): any => {
        if (key === 'expense_amount' || key === 'debit' || key === 'credit' || key === 'distribute_pct') {
            return value.toString();
        }
        if (key === 'start_date' || key === 'expense_date') {
            return isoDate(value);
        }
        return value;
    };

    return postMP<SaveDeferredExpenseData, SaveDeferredExpenseResult>('savedeferredexpense', data, replacer, reviver);
};
export interface SaveDeferredExpenseNote {
    deferred_expense_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveDeferredExpenseNote = async (data: SaveDeferredExpenseNote) => {
    return post<SaveDeferredExpenseNote, SaveNoteResult>('savedeferredexpensenote', data);
};

export interface RemoveDeferredExpenseData {
    deferred_expense_id: number;
}
export const removeDeferredExpense = async (data: RemoveDeferredExpenseData) => post<RemoveDeferredExpenseData>('removedeferredexpense', data);
export const removeDeferredExpenseNote = async (data: RemoveNote) => post<RemoveNote>('removedeferredexpensenote', data);

export interface SearchAssetsData {
    search?: string;
    active: boolean;
    lang: string;
}
export interface AssetData {
    asset_id: number;
    asset_name?: string;
    asset_description?: string;
    asset_type?: number;
    acquisition_date: Date;
    method: number;
    notdepreciableamount?: Decimal;
    depreciableamount?: Decimal;
    orginamount?: Decimal;
    durationnumber?: number;
    durationperiod?: number;
    journal?: number;
    depreciation_account?: string;
    asset_account?: string;
    disabled: number;
}
export interface SearchAssetsResult {
    assets: AssetData[];
}

export const searchAssets = async (data: SearchAssetsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'depreciableamount') {
            return decimal(value);
        }
        if (key === 'orginamount') {
            return decimal(value);
        }
        return value;
    };
    return post<SearchAssetsData, SearchAssetsResult>('searchassets', data, undefined, reviver);
}
export interface FindAssetData {
    asset_id: number;
    lang: string;
}
export interface FindAssetResult {
    asset_id: number;
    asset_name?: string;
    asset_description?: string;
    asset_type?: number;
    acquisition_date: Date;
    method: number;
    notdepreciableamount?: Decimal;
    depreciableamount?: Decimal;
    orginamount?: Decimal;
    durationnumber?: number;
    durationperiod?: number;
    journal?: number;
    depreciation_account?: string;
    asset_account?: string;
    disabled: number;
    notes?: NoteData[];
    media: Media[];
}

export const findAsset = async (data: FindAssetData) => {

    const reviver = (key: string, value: any): any => {
        if (key === 'depreciableamount') {
            return decimal(value);
        }

        return value;
    };


    return post<FindAssetData, FindAssetResult>('findasset', data, undefined, reviver);
}
export interface SaveAssetData {
    asset_id?: number;
    lang: string;
    asset_name?: string;
    asset_description?: string;
    asset_type?: number;
    acquisition_date: Date;
    method: number;
    notdepreciableamount?: Decimal;
    depreciableamount?: Decimal;
    orginamount?: Decimal;
    durationnumber?: number;
    durationperiod?: number;
    journal?: number;
    depreciation_account?: string;
    asset_account?: string;
    disabled: number;
    media: Media[];
    files?: File[];
}

export interface SaveAssetResult {
    asset_id: number;
    media: Media[];
}

export const saveAsset = async (data: SaveAssetData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'depreciableamount') {
            return value.toString();
        }
        if (key === 'orginamount') {
            return value.toString();
        }

        return value;
    };

    return postMP<SaveAssetData, SaveAssetResult>('saveasset', data, replacer);
};
export interface SaveAssetNote {
    asset_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveAssetNote = async (data: SaveAssetNote) => {
    return post<SaveAssetNote, SaveNoteResult>('saveassetnote', data);
};

export interface RemoveAssetData {
    asset_id: number;
}
export const removeAsset = async (data: RemoveAssetData) => post<RemoveAssetData>('removeasset', data);
export const removeAssetNote = async (data: RemoveNote) => post<RemoveNote>('removeassetnote', data);

export interface PricelistMember {
    pricelist_detail_id?: number;
    product_code: string;
    product_name?: string;
    sales_unit_type_id?: number;
    sales_unit_type_name?: string;
    sales_unit_type_symbol?: string;
    costing_amount?: Decimal;
    selling_amount?: Decimal;
}
export interface savePricelistMember {
    pricelist_detail_id?: number;
    product_code: string;
    sales_unit_type_id?: number;
    costing_amount?: Decimal;
    selling_amount?: Decimal;
}

export interface PricelistData {
    pricelist_id: number;
    pricelist_no?: number;
    customer_type_id?: number;
    customer_type_name?: string;
    currency_code?: string;
    pricelist_name?: string;
    starting_date?: Date;
    ending_date?: Date;
    pricelist_description?: string;

    members?: PricelistMember[];
    disabled: number;
}

export interface SearchPricelistsData {
    customer_type_id?: number;
    starting_date?: Date;
    ending_date?: Date;

    search?: string;
    active: boolean;
    lang: string;
}

export interface SearchPricelistsResult {
    pricelists: PricelistData[];
}

export const searchPricelists = async (data: SearchPricelistsData) => post<SearchPricelistsData, SearchPricelistsResult>('searchpricelists', data);
export interface FindPricelistData {
    pricelist_id: number;
    lang: string;
    active: boolean;
}
export interface FindPricelistResult {
    pricelist_id: number;
    pricelist_no?: number;
    customer_type_id: number;
    customer_type_name: string;
    currency_code: string;
    pricelist_name?: string;
    pricelist_description?: string;
    starting_date?: Date;
    ending_date?: Date;

    members?: PricelistMember[];
    disabled: number;
}

export const findPricelist = async (data: FindPricelistData) => post<FindPricelistData, FindPricelistResult>('findpricelist', data);

export interface SavePricelistData {
    pricelist_id?: number;
    pricelist_no?: number;
    customer_type_id?: number;
    currency_code?: string;
    lang: string;
    pricelist_name?: string;
    pricelist_description?: string;
    starting_date?: Date;
    ending_date?: Date;

    members: savePricelistMember[];
    disabled: number;
}

export interface SavePricelistResult {
    pricelist_id: number;
    pricelist_no?: number;
    customer_type_id: number;
    starting_date?: Date;
    ending_date?: Date;
    members?: savePricelistMember[];


}

export const savePricelist = async (data: SavePricelistData) => post<SavePricelistData, SavePricelistResult>('savepricelist', data);
export interface RemovePricelistData {
    pricelist_id: number;

}

export const removePricelist = async (data: RemovePricelistData) => post<RemovePricelistData>('removepricelist', data);

export interface SaveCostListMember {
    cost_list_member_id?: number;
    product_code: string;

    cost?: Decimal;
}
export interface CostListMember extends SaveCostListMember {
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
}

export interface CostListData {
    cost_list_id: number;
    cost_list_no?: number;
    cost_list_name?: string;
    starting_date?: Date;
    cost_list_description?: string;

    members?: CostListMember[];
    disabled: number;
}

export interface SearchCostListsData {
    lang: string;

    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
}

export interface SearchCostListsResult {
    cost_lists: CostListData[];
}

export const searchCostLists = async (data: SearchCostListsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'starting_date' && value) {
            return date(value);
        }
        return value;
    };
    return post<SearchCostListsData, SearchCostListsResult>('searchcostlists', data, replacer, reviver);
}
export interface FindCostListData {
    cost_list_id: number;
    lang: string;
}


export const findCostList = async (data: FindCostListData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'starting_date' && value) {
            return date(value);
        }
        if (key === 'cost' && value) {
            return decimal(value);
        }
        return value;
    };
    return post<FindCostListData, CostListData>('findcostlist', data, undefined, reviver);
}

export interface SaveCostListData {
    cost_list_id?: number;
    cost_list_no?: number;
    lang: string;
    cost_list_name?: string;
    cost_list_description?: string;
    starting_date?: Date;

    members: SaveCostListMember[];
    disabled: number;
}

export interface SaveCostListResult {
    cost_list_id: number;
    cost_list_no?: number;
    members?: SaveCostListMember[];
}

export const saveCostList = async (data: SaveCostListData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'starting_date' && value) {
            return isoDate(value);
        }
        return value;
    }

    const reviver = (key: string, value: any): any => {
        if (key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return post<SaveCostListData, SaveCostListResult>('savecostlist', data, replacer, reviver);
}
export interface RemoveCostListData {
    cost_list_id: number;

}

export const removeCostList = async (data: RemoveCostListData) => post<RemoveCostListData>('removecostlist', data);

export enum QuotationStatus {
    PARKED = 0,
    ACTIVE = 1
}
export interface QuotationDetail {
    quotation_detail_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
}
export interface SaveQuotationDetail {
    quotation_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    pricelist_id?: number;
    promotion_id?: number;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    tax_amount?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
}

export interface QuotationData {
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    quotation_id: number;
    quotation_no?: number;
    quotation_date?: Date;
    delivery_date?: Date;
    validity_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    quotation_description?: string;
    quotation_status?: QuotationStatus;

    details?: QuotationDetail[];
}

export interface SearchQuotationsData {
    quotation_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;

}

export interface SearchQuotationResult {
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    currency_code?: string;
    quotation_id: number;
    quotation_no: number;
    quotation_date: Date;
    delivery_date: Date;
    validity_date: Date;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    quotation_description?: string;
    quotation_status?: QuotationStatus;

}
export interface SearchQuotationsResult {
    quotations: SearchQuotationResult[];
}


export const searchQuotations = async (data: SearchQuotationsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchQuotationsData, SearchQuotationsResult>('searchquotations', data, replacer);
}
export interface FindQuotationData {
    quotation_id: number;
    lang?: string;
    active?: boolean;

}
export interface FindQuotationResult {
    customer_id?: number;
    customer_name?: string;
    currency_code: string;
    quotation_id: number;
    employee_id?: number;
    employee_name?: string;
    quotation_no: number;
    quotation_date: Date;
    delivery_date: Date;
    validity_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    tax_amount?: Decimal;
    lang?: string;
    quotation_description?: string;
    quotation_status?: QuotationStatus;
    disable_edit?: boolean;
    details?: QuotationDetail[];
    notes?: NoteData[];

}
export interface SearchQuotationProducts {
    quotation_id?: number;
    lang: string;
    search?: string;
}

export interface SearchQuotationProductsResult {
    quotation_details?: QuotationDetail[];
}

export const searchQuotationProducts = async (data: SearchQuotationProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price' || key === 'discount_amount' || key === 'tax_amount' || key === 'total_tax') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchQuotationProducts, SearchQuotationProductsResult>('searchquotationproducts', data, undefined, reviver);
}

export const findQuotation = async (data: FindQuotationData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }

        if (key === 'discount_amount' || key === 'tax_amount' || key === 'total_tax') {
            return decimal(value);
        }


        return value;
    };

    return post<FindQuotationData, FindQuotationResult>('findquotation', data, undefined, reviver);
}

export interface SaveQuotationData {
    quotation_id?: number;
    quotation_no?: number;
    customer_id?: number;
    employee_id?: number;
    delivery_date?: Date;
    validity_date?: Date;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    quotation_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    quotation_description?: string;
    quotation_status?: QuotationStatus;

    details?: SaveQuotationDetail[];
}

export interface SaveQuotationResult {
    quotation_id: number;
    quotation_no: number;
    quotation_status?: QuotationStatus;
    disable_edit?: boolean;

    details?: SaveQuotationDetail[];
}

export const saveQuotation = async (data: SaveQuotationData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'quotation_date' || key === 'validity_date' || key === 'delivery_date') {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }
        if (key === 'total_discount' || key === 'tax_amount' || key === 'discount_amount' || key === 'total_tax') {
            return decimal(value);
        }

        return value;
    };

    return post<SaveQuotationData, SaveQuotationResult>('savequotation', data, replacer, reviver);
}

export interface SaveQuotationNote {
    quotation_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveQuotationNote = async (data: SaveQuotationNote) => {
    return post<SaveQuotationNote, SaveNoteResult>('savequotationnote', data);
};
export const removeQuotationNote = async (data: RemoveNote) => post<RemoveNote>('removequotationnote', data);

export interface RemoveQuotationData {
    quotation_id: number;

}

export const removeQuotation = async (data: RemoveQuotationData) => post<RemoveQuotationData>('removequotation', data);


export interface SalesOrderDetail {
    sales_order_detail_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
}
export interface SaveSalesOrderDetail {
    sales_order_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_amount?: Decimal;
    pricelist_id?: number;
    promotion_id?: number;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
}


export enum SalesOrderType {
    QUOTATION = 1,
    NON_QUOTATION = 2,
}
export interface SalesOrderData {
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    sales_order_type?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    sales_order_id: number;
    sales_order_no?: number;
    quotation_type?: number;
    quotation_id?: number;
    quotation_no?: number;
    sales_order_date?: Date;
    delivery_date?: Date;
    validity_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    sales_order_description?: string;
    sales_order_status?: RecordStatus;
    details?: SalesOrderDetail[];
}

export interface SearchSalesOrdersData {
    sales_order_id?: number;
    sales_order_no?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active?: boolean;
    parked?: boolean;
}

export interface SearchSalesOrderResult {
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    quotation_type?: number;
    sales_order_type?: SalesOrderType;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    disabled?: number;
    currency_code?: string;
    sales_order_id: number;
    sales_order_no: number;
    sales_order_date: Date;
    delivery_date: Date;
    validity_date: Date;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    sales_order_description?: string;
    quotation_id?: number;
    quotation_no?: number;
    sales_order_status?: RecordStatus;
}
export interface SearchSalesOrdersResult {
    sales_orders?: SearchSalesOrderResult[];
}


export interface SearchSalesOrderProducts {
    sales_order_id?: number;
    lang: string;
    search?: string;
}

export interface SearchSalesOrderProductsResult {
    sales_order_details?: SalesOrderDetail[];
}

export const searchSalesOrderProducts = async (data: SearchSalesOrderProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchSalesOrderProducts, SearchSalesOrderProductsResult>('searchsalesorderproducts', data, undefined, reviver);
}

export const searchSales_Orders = async (data: SearchSalesOrdersData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'total_amount') {
            return decimal(value);
        }

        if (key === 'total_discount') {
            return decimal(value);
        }
        if (key === 'total_tax') {
            return decimal(value);
        }

        return value;

    };


    return post<SearchSalesOrdersData, SearchSalesOrdersResult>('searchsalesorders', data, replacer, reviver);
}
export interface FindSalesOrderData {
    sales_order_id: number;
    lang?: string;
    active?: boolean;
    parked?: boolean;
}
export interface FindSalesOrderResult {
    customer_id?: number;
    customer_name?: string;
    currency_code: string;
    sales_order_id: number;
    sales_order_type?: SalesOrderType;
    employee_id: number;
    employee_name: string;
    sales_order_no: number;
    sales_order_date: Date;
    delivery_date: Date;
    validity_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    sales_order_description?: string;
    quotation_type?: number;
    quotation_id?: number;
    quotation_no?: number;
    sales_order_status?: RecordStatus;
    notes?: NoteData[];
    disable_edit?: boolean;

    details?: SalesOrderDetail[];
}


export interface SaveSalesOrderNote {
    sales_order_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveSalesOrderNote = async (data: SaveSalesOrderNote) => {
    return post<SaveSalesOrderNote, SaveNoteResult>('savesalesordernote', data);
};
export const removeSalesOrderNote = async (data: RemoveNote) => post<RemoveNote>('removesalesOrdernote', data);



export const findSalesOrder = async (data: FindSalesOrderData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }
        if (key === 'discount' || key === 'tax_amount') {
            return decimal(value);
        }

        return value;
    };

    return post<FindSalesOrderData, FindSalesOrderResult>('findsalesorder', data, undefined, reviver);
}

export interface SaveSalesOrderData {
    sales_order_id?: number;
    sales_order_no?: number;
    quotation_type?: number;
    quotation_id?: number;
    quotation_no?: number;
    customer_id?: number;
    employee_id?: number;
    sales_order_type?: SalesOrderType;
    delivery_date?: Date;
    validity_date?: Date;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    sales_order_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    sales_order_description?: string;
    disable_edit?: boolean;
    sales_order_status?: RecordStatus;

    details?: SaveSalesOrderDetail[];
}

export interface SaveSalesOrderResult {
    sales_order_id: number;
    sales_order_no: number;
    sales_order_status?: RecordStatus;

    disable_edit?: boolean;
    details?: SaveSalesOrderDetail[];
}

export const saveSalesOrder = async (data: SaveSalesOrderData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'sales_order_date' || key === 'validity_date' || key === 'delivery_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }

        if (key === 'total_discount' || key === 'total_amount' || key === 'total_tax' || key === 'due_amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SaveSalesOrderData, SaveSalesOrderResult>('savesalesorder', data, replacer, reviver);
}

export interface RemoveSalesOrderData {
    sales_order_id: number;

}

export const removeSalesOrder = async (data: RemoveSalesOrderData) => post<RemoveSalesOrderData>('removesalesorder', data);



export interface SalesInvoiceJournalData {
    sales_invoice_id?: number;
    branch_id?: number;
    customer_id?: number;
    employee_id?: number;
    currency_code?: string;
    sales_invoice_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    lang?: string;
    sales_invoice_status?: RecordStatus;
    financial_year_id?: number;
    details?: SaveSalesInvoiceDetail[];
    journal_details?: SaveSalesInvoiceJournalDetail[];
    use_currencies?: number;

}

export interface SaveSalesInvoiceJournalDetailCostCenter {
    sales_invoice_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveSalesInvoiceJournalDetail {
    sales_invoice_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveSalesInvoiceJournalDetailCostCenter[];
}

export interface SalesInvoiceJournalResult {
    journal_details?: SalesInvoiceJournalDetail[];
}

export const salesInvoiceJournalEntries = async (data: SalesInvoiceJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'sales_invoice_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SalesInvoiceJournalData, SalesInvoiceJournalResult>('salesinvoicejournalentries', data, replacer, reviver);
}
export interface SalesInvoiceJournalDetailCostCenter {
    sales_invoice_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface SalesInvoiceJournalDetail {
    sales_invoice_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: SalesInvoiceJournalDetailCostCenter[];

}

export enum RowType {
    PRODUCT=0,
    PROMOTION=1,
    TAX=2
}

export interface SaveSalesInvoiceDetail {
    sales_invoice_detail_id?: number;
    row_type: RowType;
    product_code?: string;
    unit_type_id?: number;
    quantity?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_type?: TaxType;
    tax_amount?: Decimal;
    included_in_price?: number;
    promotion_id?: number;
    promotion_type?: PromotionType;
    promotion_amount?: Decimal;
    discount?: Decimal;
}

export interface SalesInvoiceDetail extends SaveSalesInvoiceDetail {
    sales_invoice_id?: number;
    product_name?: string;
    unit_type_name?: string;
    unit_type_symbol?: string;
    tax_name?: string;
    tax_amount?: Decimal;
    promotion_name?: string;
    return_discount?: Decimal;
    vat?: Decimal;
    return_vat?: Decimal;
    total_after_discount?: Decimal;
    return_total_after_discount?: Decimal;
    total_amount?: Decimal;
    return_total_amount?: Decimal;
    due_amount?: Decimal;
    return_due_amount?: Decimal;
}

export interface SaveSalesInvoiceNote {
    sales_invoice_id?: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}
export const saveSalesInvoiceNote = async (data: SaveSalesInvoiceNote) => {
    return post<SaveSalesInvoiceNote, SaveNoteResult>('savesalesinvoicenote', data);
};
export const removeSalesInvoiceNote = async (data: RemoveNote) => post<RemoveNote>('removesalesInvoicenote', data);


export interface SearchSalesInvoicesData {
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
    customer_id?: number;
}

export interface SearchSalesInvoiceResult {
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    sales_order_id?: number;
    sales_order_no?: number;
    branch_id?: number;
    branch_name?: string;
    payment_term_id?: number;
    payment_term_name?: string;
    same_as_billing?: number;
    billing_address?: string;
    billing_address1?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    currency_code?: string;
    currency_symbol?: string;
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_vat?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    sales_invoice_description?: string;
    sales_invoice_status?: RecordStatus;
    use_currencies?: number;


}
export interface SearchSalesInvoicesResult {
    sales_invoices: SearchSalesInvoiceResult[];
}

export const searchSalesInvoices = async (data: SearchSalesInvoicesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'sales_invoice_date') {
            return  date(value);
        }
        if (key === 'total_amount' || key === 'due_amount' 
            || key === 'total_discount' || key === 'total_tax'
            || key === 'total_vat' || key === 'total_paid'  
            || key === 'total_refund' || key === 'total_remaining') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchSalesInvoicesData, SearchSalesInvoicesResult>('searchsalesinvoices', data, replacer, reviver);
}
export interface FindSalesInvoiceData {
    sales_invoice_id: number;
    lang?: string;

}
export interface FindSalesInvoiceResult extends SaveSalesInvoiceData {
    sales_order_no?: number;
    branch_name?: string;
    subjournal_name?: string;
    batch_no?: number;
    batch_name?: string;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    customer_name?: string;
    employee_name?: string;
    currency_symbol?: string;
    currency_name?: string;
    use_currencies?: number;
    payment_term_name?: string;
    billing_city_name?: string;
    billing_governerate_name?: string;
    billing_country_name?: string;
    delivery_city_name?: string;
    delivery_governerate_name?: string;
    delivery_country_name?: string;

    total_amount?: Decimal;
    due_amount?: Decimal;
    remaining?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_after_discount?: Decimal;
    total_vat?: Decimal;
    total_paid?: Decimal;
    total_return_amount?: Decimal;
    due_return_amount?: Decimal;
    total_return_discount?: Decimal;
    total_return_tax?: Decimal;
    total_return_vat?: Decimal;
    total_refund?: Decimal;


    details?: SalesInvoiceDetail[];
    journal_details?: SaveSalesInvoiceJournalDetail[];
    notes?: NoteData[];
}
export interface SearchSalesInvoiceProducts {
    sales_invoice_id?: number;
    warehouse_id?: number;
    lang: string;
    search?: string;
}

export interface SearchSalesInvoiceProductsResult {
    sales_invoice_details?: SalesInvoiceDetail[];

}

export const searchSalesInvoiceProducts = async (data: SearchSalesInvoiceProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchSalesInvoiceProducts, SearchSalesInvoiceProductsResult>('searchsalesinvoiceproducts', data, undefined, reviver);
}

export const findSalesInvoice = async (data: FindSalesInvoiceData) => {
    const reviver = (key: string, value: any): any => {

        if ((key === 'quantity' || key === 'price' || key === 'promo_amount'
            || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };

    return post<FindSalesInvoiceData, FindSalesInvoiceResult>('findsalesinvoice', data, undefined, reviver);
}

export interface SaveSalesInvoiceData {
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_invoice_reference?: string;
    sales_order_id?: number;
    branch_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    sales_invoice_date?: Date;
    customer_id?: number;
    employee_id?: number;
    currency_code?: string;
    use_currencies?: number;
    payment_term_id?: number;
    same_as_billing?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_governerate_id?: number;
    billing_country_id?: number;

    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    delivery_city_id?: number;
    delivery_governerate_id?: number;
    delivery_country_id?: number;

    lang?: string;
    sales_invoice_description?: string;
    sales_invoice_description_en?: string;
    sales_invoice_description_ar?: string;

    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_address?: string;
    billing_address1?: string;

    sales_invoice_status?: RecordStatus;

    details?: SaveSalesInvoiceDetail[];
    journal_details?: SaveSalesInvoiceJournalDetail[];


}
export interface SaveSalesInvoiceJournalDetailCostCenter {
    sales_invoice_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveSalesInvoiceJournalDetail {
    sales_invoice_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveSalesInvoiceJournalDetailCostCenter[];
}

export interface SaveSalesInvoiceResult {
    sales_invoice_id: number;
    sales_invoice_no: number;
    sales_invoice_status?: RecordStatus;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    details?: SaveSalesInvoiceDetail[];
    journal_details?: SaveSalesInvoiceJournalDetail[];
    media?: Media[];
    use_currencies?: number;
}

export const saveSalesInvoice = async (data: SaveSalesInvoiceData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'sales_invoice_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'quantity' || key === 'price' || key === 'tax_amount'
            || key === 'promotion_amount' || key === 'discount' || key === 'return_discount'
            || key === 'vat' || key === 'return_vat'  
            || key === 'return_total_after_discount' || key === 'total_amount' 
            || key === 'return_total_amount' || key === 'due_amount' || key === 'return_due_amount'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SaveSalesInvoiceData, SaveSalesInvoiceResult>('savesalesinvoice', data, replacer, reviver);
}
export interface RemoveSalesInvoiceData {
    sales_invoice_id: number;

}

export const removeSalesInvoice = async (data: RemoveSalesInvoiceData) => post<RemoveSalesInvoiceData>('removesalesinvoice', data);

export interface Purchase_OrderDetail {
    purchase_order_detail_id?: number;
    purchase_order_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
}
export interface SavePurchase_OrderDetail {
    purchase_order_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_amount?: Decimal;
    pricelist_id?: number;
    promotion_id?: number;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
}

export enum PurchaseOrderStatus {
    PARKED = 0,
    ACTIVE = 1
}
export enum PurchaseOrderType {
    QUOTATION = 1,
    NON_QUOTATION = 2,
}
export interface Purchase_OrderData {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    purchase_order_id: number;
    purchase_order_no?: number;
    purchase_quotation_type?: number;
    purchase_quotation_id?: number;
    purchase_quotation_no?: number;
    purchase_order_date?: Date;
    delivery_date?: Date;
    validity_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_order_description?: string;
    purchase_order_status?: PurchaseOrderStatus;

    details?: Purchase_OrderDetail[];
    disabled: number;
}

export interface SearchPurchase_OrdersData {
    purchase_order_id?: number;
    purchase_order_no?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked?: boolean;
}

export interface SearchPurchase_OrderResult {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    purchase_quotation_type?: number;
    purchase_order_type?: PurchaseOrderType;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    disabled?: number;
    currency_code?: string;
    purchase_order_id: number;
    purchase_order_no: number;
    purchase_order_date: Date;
    delivery_date: Date;
    validity_date: Date;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_order_description?: string;
    purchase_quotation_id?: number;
    purchase_quotation_no?: number;
    purchase_order_status?: PurchaseOrderStatus;
}
export interface SearchPurchase_OrdersResult {
    purchase_orders: SearchPurchase_OrderResult[];
}


export const searchPurchase_Orders = async (data: SearchPurchase_OrdersData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'total_amount') {
            return decimal(value);
        }

        if (key === 'total_discount') {
            return decimal(value);
        }
        if (key === 'total_tax') {
            return decimal(value);
        }

        return value;
    };
    return post<SearchPurchase_OrdersData, SearchPurchase_OrdersResult>('searchpurchase_orders', data, replacer, reviver);
}
export interface FindPurchase_OrderData {
    purchase_order_id: number;
    lang?: string;

    active: boolean;
}
export interface FindPurchase_OrderResult {
    vendor_id?: number;
    vendor_name?: string;
    currency_code: string;
    purchase_order_id: number;
    purchase_order_type?: PurchaseOrderType;
    employee_id: number;
    employee_name: string;
    purchase_order_no: number;
    purchase_order_date: Date;
    delivery_date: Date;
    validity_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_order_description?: string;
    purchase_quotation_type?: number;
    purchase_quotation_id?: number;
    purchase_quotation_no?: number;
    purchase_order_status?: PurchaseOrderStatus;
    notes?: NoteData[];
    disable_edit?: boolean;
    media?: Media[];

    details?: Purchase_OrderDetail[];
    disabled: number;
}

export interface SearchPurchaseOrderProducts {
    purchase_order_id?: number;
    lang: string;
    search?: string;
}

export interface SearchPurchaseOrderProductsResult {
    purchase_order_details?: Purchase_OrderDetail[];
}

export const searchPurchaseOrderProducts = async (data: SearchPurchaseOrderProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchPurchaseOrderProducts, SearchPurchaseOrderProductsResult>('searchpurchaseorderproducts', data, undefined, reviver);
}

export interface SearchWorkOrderProducts {
    work_order_id?: number;
    lang: string;
    search?: string;
}
export interface SearchWorkOrderProductsResult {
    work_order_details?: Work_OrderDetail[];
}
export const searchWorkOrderProducts = async (data: SearchWorkOrderProducts) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price') {
            return decimal(value);
        }
        return value;
    };
    return post<SearchWorkOrderProducts, SearchWorkOrderProductsResult>('searchworkorderproducts', data, undefined, reviver);
}
export const findPurchase_Order = async (data: FindPurchase_OrderData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }


        return value;
    };

    return post<FindPurchase_OrderData, FindPurchase_OrderResult>('findpurchase_order', data, undefined, reviver);
}

export interface SavePurchase_OrderData {
    purchase_order_id?: number;
    purchase_order_no?: number;
    purchase_quotation_id?: number;
    purchase_quotation_no?: number;
    vendor_id?: number;
    employee_id?: number;
    delivery_date?: Date;
    validity_date?: Date;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    purchase_order_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_order_description?: string;
    media?: Media[];
    files?: File[];
    disable_edit?: boolean;
    purchase_order_status?: PurchaseOrderStatus;
    purchase_order_type?: number;
    purchase_order_description_en?: string;
    purchase_order_description_ar?: string;

    details?: SavePurchase_OrderDetail[];
    disabled: number;
}
export interface SavePurchase_OrderResult {
    purchase_order_id: number;
    purchase_order_no: number;
    purchase_order_status?: PurchaseOrderStatus;
    disable_edit?: boolean;
    details?: SavePurchase_OrderDetail[];
    media?: Media[];
}

export const savePurchase_Order = async (data: SavePurchase_OrderData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'validity_date' && value) {
            return isoDate(value);
        }
        else if (key === 'delivery_date' && value) {
            return isoDate(value);
        }
        else if (key === 'purchase_order_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }

        if (key === 'total_discount' || key === 'total_amount' || key === 'total_tax' || key === 'due_amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SavePurchase_OrderData, SavePurchase_OrderResult>('savepurchase_order', data, replacer, reviver);
}
export interface RemovePurchase_OrderData {
    purchase_order_id: number;

}

export const removePurchase_Order = async (data: RemovePurchase_OrderData) => post<RemovePurchase_OrderData>('removepurchase_order', data);

export enum PurchaseInvoiceStatus {
    PARKED = 0,
    ACTIVE = 1
}
export enum PurchaseInvoiceType {
    SALES_ORDER = 1,
    NON_SALES_ORDER = 2,
}


export interface PurchaseInvoiceJournalData {
    purchase_invoice_id?: number;
    vendor_id?: number;
    branch_id?: number;
    employee_id?: number;
    currency_code?: string;
    purchase_invoice_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    lang?: string;
    purchase_invoice_status?: PurchaseInvoiceStatus;
    purchase_invoice_type?: number;
    financial_year_id?: number;
    details?: SavePurchase_InvoiceDetail[];
    journal_details?: SavePurchaseInvoiceJournalDetail[];
    use_currencies?: number;
}

export interface SavePurchaseInvoiceJournalDetailCostCenter {
    purchase_invoice_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SavePurchaseInvoiceJournalDetail {
    purchase_invoice_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SavePurchaseInvoiceJournalDetailCostCenter[];
}

export interface PurchaseInvoiceJournalResult {
    journal_details?: PurchaseInvoiceJournalDetail[];
}

export const purchaseInvoiceJournalEntries = async (data: PurchaseInvoiceJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'purchase_invoice_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<PurchaseInvoiceJournalData, PurchaseInvoiceJournalResult>('purchaseinvoicejournalentries', data, replacer, reviver);
}
export interface PurchaseInvoiceJournalDetailCostCenter {
    purchase_invoice_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface PurchaseInvoiceJournalDetail {
    purchase_invoice_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: PurchaseInvoiceJournalDetailCostCenter[];

}
export interface Purchase_InvoiceDetail {
    purchase_invoice_detail_id?: number;
    purchase_invoice_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    available?: Decimal;
    use_currencies?: number;
}
export interface SavePurchase_InvoiceDetail {
    purchase_invoice_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    pricelist_id?: number;
    promotion_id?: number;
    promo_amount?: Decimal;
    tax_amount?: Decimal;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
}

export interface SavePurchaseInvoiceNote {
    purchase_invoice_id?: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}
export const savePurchaseInvoiceNote = async (data: SavePurchaseInvoiceNote) => {
    return post<SavePurchaseInvoiceNote, SaveNoteResult>('savepurchaseinvoicenote', data);
};
export const removePurchaseInvoiceNote = async (data: RemoveNote) => post<RemoveNote>('removepurchaseInvoicenote', data);

export interface Purchase_InvoiceData {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_order_id?: number;
    purchase_order_no?: number;
    purchase_invoice_date?: Date;
    delivery_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_invoice_description?: string;
    purchase_invoice_status?: PurchaseInvoiceStatus;
    purchase_invoice_type?: number;
    use_currencies?: number;

    details?: Purchase_InvoiceDetail[];
    disabled: number;
}

export interface SearchPurchase_InvoicesData {
    purchase_invoice_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
    vendor_type_id?: number;
}

export interface SearchPurchase_InvoiceResult {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    disabled?: number;
    currency_code?: string;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    delivery_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_invoice_description?: string;
    purchase_order_id?: number;
    purchase_order_no?: number;
    purchase_invoice_status?: PurchaseInvoiceStatus;
    purchase_invoice_type?: number;
    disable_edit?: boolean;
    vendor_type_id?: number;
    use_currencies?: number;


}
export interface SearchPurchase_InvoicesResult {
    purchase_invoices: SearchPurchase_InvoiceResult[];
}

export const searchPurchase_Invoices = async (data: SearchPurchase_InvoicesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'total_amount') {
            return decimal(value);
        }

        if (key === 'total_discount') {
            return decimal(value);
        }
        if (key === 'total_tax' || key === 'total_due_amount' || key === 'due_amount') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchPurchase_InvoicesData, SearchPurchase_InvoicesResult>('searchpurchase_invoices', data, replacer, reviver);
}
export interface FindPurchase_InvoiceData {
    purchase_invoice_id: number;
    lang?: string;

    active?: boolean;
    parked?: boolean;
}
export interface FindPurchase_InvoiceResult {
    vendor_id?: number;
    vendor_name?: string;
    currency_code: string;
    purchase_invoice_id: number;
    employee_id: number;
    employee_name: string;
    purchase_invoice_no: number;
    purchase_invoice_date: Date;
    delivery_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    branch_id?: number;
    branch_name?: string;
    purchase_invoice_description?: string;
    purchase_order_id?: number;
    purchase_order_no?: number;
    media?: Media[];
    purchase_invoice_status?: PurchaseInvoiceStatus;
    purchase_invoice_type?: number;
    disable_edit?: boolean;
    notes?: NoteData[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: PurchaseInvoiceJournalDetail[];
    use_currencies?: number;

    details?: Purchase_InvoiceDetail[];
    disabled: number;
    vendor_type_id?: number;
}
export interface SearchPurchaseInvoiceProducts {
    purchase_invoice_id?: number;
    warehouse_id?: number;
    lang: string;
    search?: string;
}

export interface SearchPurchaseInvoiceProductsResult {
    purchase_invoice_details?: Purchase_InvoiceDetail[];

}

export const searchPurchaseInvoiceProducts = async (data: SearchPurchaseInvoiceProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchPurchaseInvoiceProducts, SearchPurchaseInvoiceProductsResult>('searchpurchaseinvoiceproducts', data, undefined, reviver);
}

export const findPurchase_Invoice = async (data: FindPurchase_InvoiceData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if ((key === 'price' || key === 'promo_amount'
            || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };

    return post<FindPurchase_InvoiceData, FindPurchase_InvoiceResult>('findpurchase_invoice', data, undefined, reviver);
}

export interface SavePurchase_InvoiceData {
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    purchase_order_id?: number;
    vendor_id?: number;
    employee_id?: number;
    delivery_date?: Date;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    purchase_invoice_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_invoice_description?: string;
    media?: Media[];
    files?: File[];
    purchase_invoice_status?: PurchaseInvoiceStatus;
    purchase_invoice_type?: number;
    disable_edit?: boolean;
    financial_year_id?: number;
    details?: SavePurchase_InvoiceDetail[];
    disabled: number;
    journal_details?: SavePurchaseInvoiceJournalDetail[];
    transaction_type_id?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_name?: string;
    journal_id?: number;
    use_currencies?: number;
    purchase_invoice_description_en?: string;
    purchase_invoice_description_ar?: string;

}
export interface SavePurchaseInvoiceJournalDetailCostCenter {
    purchase_invoice_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SavePurchaseInvoiceJournalDetail {
    purchase_invoice_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SavePurchaseInvoiceJournalDetailCostCenter[];
}

export interface SavePurchase_InvoiceResult {
    purchase_invoice_id: number;
    purchase_invoice_no: number;
    purchase_invoice_status?: PurchaseInvoiceStatus;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    disable_edit?: boolean;
    details?: SavePurchase_InvoiceDetail[];
    journal_details?: SavePurchaseInvoiceJournalDetail[];
    use_currencies?: number;
    media?: Media[];
}

export const savePurchase_Invoice = async (data: SavePurchase_InvoiceData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'purchase_invoice_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'qty' || key === 'price' || key === 'discount'
            || key === 'pending' || key === 'paid' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SavePurchase_InvoiceData, SavePurchase_InvoiceResult>('savepurchase_invoice', data, replacer, reviver);
}
export interface RemovePurchase_InvoiceData {
    purchase_invoice_id: number;

}

export const removePurchase_Invoice = async (data: RemovePurchase_InvoiceData) => post<RemovePurchase_InvoiceData>('removepurchase_invoice', data);


export enum Purchase_QuotationStatus {
    PARKED = 0,
    ACTIVE = 1
}
export interface Purchase_QuotationDetail {
    purchase_quotation_detail_id?: number;
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
}
export interface SavePurchase_QuotationDetail {
    purchase_quotation_detail_id?: number;
    product_code: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    pricelist_id?: number;
    promotion_id?: number;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    tax_amount?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
}

export interface Purchase_QuotationData {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    purchase_quotation_id: number;
    purchase_quotation_no?: number;
    purchase_quotation_date?: Date;
    delivery_date?: Date;
    validity_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_quotation_description?: string;
    purchase_quotation_status?: Purchase_QuotationStatus;

    details?: Purchase_QuotationDetail[];
}

export interface SearchPurchase_QuotationsData {
    purchase_quotation_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;

}

export interface SearchPurchase_QuotationResult {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    currency_code?: string;
    purchase_quotation_id: number;
    purchase_quotation_no: number;
    purchase_quotation_date: Date;
    delivery_date: Date;
    validity_date: Date;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_quotation_description?: string;
    purchase_quotation_status?: Purchase_QuotationStatus;


}
export interface SearchPurchase_QuotationsResult {
    purchase_quotations: SearchPurchase_QuotationResult[];
}


export const searchPurchase_Quotations = async (data: SearchPurchase_QuotationsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchPurchase_QuotationsData, SearchPurchase_QuotationsResult>('searchpurchasequotations', data, replacer);
}
export interface FindPurchase_QuotationData {
    purchase_quotation_id: number;
    lang?: string;
    active?: boolean;
}
export interface FindPurchase_QuotationResult {
    vendor_id?: number;
    vendor_name?: string;
    currency_code: string;
    purchase_quotation_id: number;
    employee_id?: number;
    employee_name?: string;
    purchase_quotation_no: number;
    purchase_quotation_date: Date;
    delivery_date: Date;
    validity_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    tax_amount?: Decimal;
    lang?: string;
    purchase_quotation_description?: string;
    purchase_quotation_status?: Purchase_QuotationStatus;
    disable_edit?: boolean;

    details?: Purchase_QuotationDetail[];
    notes?: NoteData[];
}
export interface SearchPurchase_QuotationProducts {
    purchase_quotation_id?: number;
    lang: string;
    search?: string;
}

export interface SearchPurchase_QuotationProductsResult {
    purchase_quotation_details?: Purchase_QuotationDetail[];
}

export const searchPurchase_QuotationProducts = async (data: SearchPurchase_QuotationProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price' || key === 'discount_amount' || key === 'tax_amount' || key === 'total_tax') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchPurchase_QuotationProducts, SearchPurchase_QuotationProductsResult>('searchpurchasequotationproducts', data, undefined, reviver);
}

export const findPurchase_Quotation = async (data: FindPurchase_QuotationData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }

        if (key === 'discount_amount' || key === 'tax_amount' || key === 'total_tax') {
            return decimal(value);
        }


        return value;
    };

    return post<FindPurchase_QuotationData, FindPurchase_QuotationResult>('findpurchasequotation', data, undefined, reviver);
}

export interface SavePurchase_QuotationData {
    purchase_quotation_id?: number;
    purchase_quotation_no?: number;
    vendor_id?: number;
    employee_id?: number;
    delivery_date?: Date;
    validity_date?: Date;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    purchase_quotation_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_quotation_description?: string;
    purchase_quotation_status?: Purchase_QuotationStatus;

    details?: SavePurchase_QuotationDetail[];
}

export interface SavePurchase_QuotationResult {
    purchase_quotation_id: number;
    purchase_quotation_no: number;
    purchase_quotation_status?: Purchase_QuotationStatus;
    disable_edit?: boolean;

    details?: SavePurchase_QuotationDetail[];
}

export const savePurchase_Quotation = async (data: SavePurchase_QuotationData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'purchase_quotation_date' || key === 'validity_date' || key === 'delivery_date') {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'price') {
            return decimal(value);
        }
        if (key === 'total_discount' || key === 'tax_amount' || key === 'discount_amount' || key === 'total_tax') {
            return decimal(value);
        }

        return value;
    };

    return post<SavePurchase_QuotationData, SavePurchase_QuotationResult>('savepurchasequotation', data, replacer, reviver);
}
export interface SavePurchase_QuotationNote {
    purchase_quotation_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const savePurchase_QuotationNote = async (data: SavePurchase_QuotationNote) => {
    return post<SavePurchase_QuotationNote, SaveNoteResult>('savepurchasequotationnote', data);
};
export const removePurchase_QuotationNote = async (data: RemoveNote) => post<RemoveNote>('removepurchasequotationnote', data);

export interface RemovePurchase_QuotationData {
    purchase_quotation_id: number;

}

export const removePurchase_Quotation = async (data: RemovePurchase_QuotationData) => post<RemovePurchase_QuotationData>('removepurchasequotation', data);


export enum SalesReturnType {
    INVOICE = 1,
    NON_INVOICE = 2,
}


export interface SalesReturnJournalData {
    sales_return_id?: number;
    branch_id?: number;
    customer_id?: number;
    employee_id?: number;
    currency_code?: string;
    sales_return_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    lang?: string;
    sales_return_status?: RecordStatus;
    sales_return_type?: number;
    financial_year_id?: number;
    details?: SaveSalesReturnDetail[];
    journal_details?: SaveSalesReturnJournalDetail[];
    use_currencies?: number;

}

export interface SaveSalesReturnJournalDetailCostCenter {
    sales_return_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveSalesReturnJournalDetail {
    sales_return_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveSalesReturnJournalDetailCostCenter[];
}

export interface SalesReturnJournalResult {
    journal_details?: SalesReturnJournalDetail[];
}

export const salesReturnJournalEntries = async (data: SalesReturnJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'sales_return_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SalesReturnJournalData, SalesReturnJournalResult>('salesreturnjournalentries', data, replacer, reviver);
}
export interface SalesReturnJournalDetailCostCenter {
    sales_return_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface SalesReturnJournalDetail {
    sales_return_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: SalesReturnJournalDetailCostCenter[];
}
export interface SalesReturnDetail {
    sales_return_detail_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    use_currencies?: number;
}
export interface SaveSalesReturnDetail {
    sales_return_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_amount?: Decimal;
    pricelist_id?: number;
    promotion_id?: number;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
}

export interface SalesReturnData {
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    sales_return_id: number;
    sales_return_no?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_return_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    sales_return_description?: string;
    sales_return_type?: number;
    sales_return_status?: RecordStatus;
    disable_edit?: boolean;
    details?: SalesReturnDetail[];
}

export interface SearchSalesReturnsData {
    sales_return_id?: number;
    sales_return_no?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active?: boolean;
    parked?: boolean;
}

export interface SearchSalesReturnResult {
    customer_id?: number;
    customer_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    disabled?: number;
    currency_code?: string;
    sales_return_id: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    sales_return_type?: number;
    sales_return_status?: RecordStatus;
    sales_return_description?: string;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    disable_edit?: boolean;
    use_currencies?: number;
}
export interface SearchSalesReturnsResult {
    sales_returns: SearchSalesReturnResult[];
}


export const searchSalesReturns = async (data: SearchSalesReturnsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchSalesReturnsData, SearchSalesReturnsResult>('searchsalesreturns', data, replacer);
}


export interface SearchSalesReturnProducts {
    sales_return_id?: number;
    lang: string;
    search?: string;
}

export interface SearchSalesReturnProductsResult {
    sales_return_details?: SalesReturnDetail[];
}

export const searchSalesReturnProducts = async (data: SearchSalesReturnProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price' || key === 'discount_amount' || key === 'tax_amount') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchSalesReturnProducts, SearchSalesReturnProductsResult>('searchsalesreturnproducts', data, undefined, reviver);
}

export interface FindSalesReturnData {
    sales_return_id: number;
    lang?: string;
    active?: boolean;
    parked?: boolean;
}
export interface FindSalesReturnResult {
    customer_id?: number;
    customer_name?: string;
    currency_code: string;
    sales_return_id: number;
    employee_id?: number;
    employee_name?: string;
    sales_return_no: number;
    sales_return_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    financial_year_id?: number;
    sales_return_description?: string;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_return_type?: number;
    sales_return_status?: RecordStatus;
    customer_type_id?: number;
    disable_edit?: boolean;
    notes?: NoteData[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    total_debit?: Decimal;
    total_credit?: Decimal;
    journal_status?: JournalStatusType,
    journal_details?: SalesReturnJournalDetail[];
    details?: SalesReturnDetail[];
    use_currencies?: number;
}

export const findSalesReturn = async (data: FindSalesReturnData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        else if ((key === 'price' || key === 'amount'
            || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };

    return post<FindSalesReturnData, FindSalesReturnResult>('findsalesreturn', data, undefined, reviver);
}

export interface SaveSalesReturnData {
    sales_return_id?: number;
    sales_return_no?: number;
    sales_invoice_id?: number;
    customer_id?: number;
    employee_id?: number;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    sales_return_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    financial_year_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: SalesReturnJournalDetail[];
    sales_return_type?: number;
    sales_return_status?: RecordStatus;
    sales_return_description?: string;
    sales_return_description_en?: string;
    sales_return_description_ar?: string;
    use_currencies?: number;
    details?: SaveSalesReturnDetail[];
    disable_edit?: boolean;
}

export interface SaveSalesReturnNote {
    sales_return_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}
export const saveSalesReturnNote = async (data: SaveSalesReturnNote) => {
    return post<SaveSalesReturnNote, SaveNoteResult>('savesalesreturnnote', data);
};
export const removeSalesReturnNote = async (data: RemoveNote) => post<RemoveNote>('removesalesreturnnote', data);
export interface SaveSalesReturnResult {
    sales_return_id: number;
    sales_return_no: number;
    sales_return_type?: number;
    sales_return_status?: RecordStatus;
    subjournal_id?: number;
    batch_id?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: SalesReturnJournalDetail[];
    disable_edit?: boolean;
    details?: SaveSalesReturnDetail[];
    use_currencies?: number;
}

export const saveSalesReturn = async (data: SaveSalesReturnData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'sales_return_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'qty' || key === 'price' || key === 'discount'
            || key === 'pending' || key === 'paid' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SaveSalesReturnData, SaveSalesReturnResult>('savesalesreturn', data, replacer, reviver);
}
export interface RemoveSalesReturnData {
    sales_return_id: number;

}

export const removeSalesReturn = async (data: RemoveSalesReturnData) => post<RemoveSalesReturnData>('removesalesreturn', data);

export enum PurchaseReturnStatus {
    PARKED = 0,
    ACTIVE = 1
}
export enum PurchaseReturnType {
    INVOICE = 1,
    NON_INVOICE = 2,
}

export interface PurchaseReturnJournalData {
    purchase_return_id?: number;
    vendor_id?: number;
    branch_id?: number;
    employee_id?: number;
    currency_code?: string;
    purchase_return_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    lang?: string;
    purchase_return_status?: PurchaseReturnStatus;
    purchase_return_type?: number;
    financial_year_id?: number;
    details?: SavePurchase_ReturnDetail[];
    journal_details?: SavePurchaseReturnJournalDetail[];
    use_currencies?: number;
}

export interface SavePurchaseReturnJournalDetailCostCenter {
    purchase_return_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SavePurchaseReturnJournalDetail {
    purchase_return_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SavePurchaseReturnJournalDetailCostCenter[];
}

export interface PurchaseReturnJournalResult {
    journal_details?: PurchaseReturnJournalDetail[];
}

export const purchaseReturnJournalEntries = async (data: PurchaseReturnJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'purchase_return_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }
        return value;
    };
    return post<PurchaseReturnJournalData, PurchaseReturnJournalResult>('purchasereturnjournalentries', data, replacer, reviver);
}
export interface PurchaseReturnJournalDetailCostCenter {
    purchase_return_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface PurchaseReturnJournalDetail {
    purchase_return_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: PurchaseReturnJournalDetailCostCenter[];
}
export interface Purchase_ReturnDetail {
    purchase_return_detail_id?: number;
    purchase_return_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_name?: string;
    tax_amount?: Decimal;
    pricelist_id?: number;
    pricelist_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    use_currencies?: number;
}
export interface SavePurchase_ReturnDetail {
    purchase_return_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
    price?: Decimal;
    tax_id?: number;
    tax_amount?: Decimal;
    pricelist_id?: number;
    promotion_id?: number;
    promo_amount?: Decimal;
    discount_amount?: Decimal;
    total_before_tax?: Decimal;
    total_after_tax?: Decimal;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    use_currencies?: number;
}

export interface Purchase_ReturnData {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    purchase_return_id: number;
    purchase_return_no?: number;
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    purchase_return_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_return_description?: string;
    purchase_return_type?: number;
    purchase_return_status?: PurchaseReturnStatus;
    disable_edit?: boolean;
    details?: Purchase_ReturnDetail[];
    disabled: number;
    use_currencies?: number;
}

export interface SearchPurchase_ReturnsData {
    purchase_return_id?: number;
    purchase_return_no?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active?: boolean;
    parked?: boolean;
}

export interface SearchPurchase_ReturnResult {
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    paymentterm_id?: number;
    paymentterm_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    disabled?: number;
    currency_code?: string;
    purchase_return_id: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    purchase_return_type?: number;
    purchase_return_status?: PurchaseReturnStatus;
    purchase_return_description?: string;
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    disable_edit?: boolean;
    use_currencies?: number;
}
export interface SearchPurchase_ReturnsResult {
    purchase_returns: SearchPurchase_ReturnResult[];
}


export const searchPurchase_Returns = async (data: SearchPurchase_ReturnsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchPurchase_ReturnsData, SearchPurchase_ReturnsResult>('searchpurchase_returns', data, replacer);
}


export interface SearchPurchaseReturnProducts {
    purchase_return_id?: number;
    lang: string;
    search?: string;
}

export interface SearchPurchaseReturnProductsResult {
    purchase_return_details?: Purchase_ReturnDetail[];
}

export const searchPurchaseReturnProducts = async (data: SearchPurchaseReturnProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'qty' || key === 'price' || key === 'discount_amount' || key === 'tax_amount') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchPurchaseReturnProducts, SearchPurchaseReturnProductsResult>('searchpurchasereturnproducts', data, undefined, reviver);
}

export interface FindPurchase_ReturnData {
    purchase_return_id: number;
    lang?: string;
    active?: boolean;
    parked?: boolean;
}
export interface FindPurchase_ReturnResult {
    vendor_id?: number;
    vendor_name?: string;
    currency_code: string;
    purchase_return_id: number;
    employee_id?: number;
    employee_name?: string;
    purchase_return_no: number;
    purchase_return_date: Date;
    paymentterm_id?: number;
    paymentterm_name?: string;
    promotion_id?: number;
    promotion_name?: string;
    pricelist_id?: number;
    pricelist_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    total_amount: Decimal;
    due_amount: Decimal;
    total_discount: Decimal;
    total_tax: Decimal;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    financial_year_id?: number;
    purchase_return_description?: string;
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    purchase_return_type?: number;
    purchase_return_status?: PurchaseReturnStatus;
    vendor_type_id?: number;
    disable_edit?: boolean;
    notes?: NoteData[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    total_debit?: Decimal;
    total_credit?: Decimal;
    journal_status?: JournalStatusType,
    journal_details?: PurchaseReturnJournalDetail[];
    details?: Purchase_ReturnDetail[];
    disabled: number;
    use_currencies?: number;
    branch_id?: number;
}

export const findPurchase_Return = async (data: FindPurchase_ReturnData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        else if ((key === 'price' || key === 'amount'
            || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };

    return post<FindPurchase_ReturnData, FindPurchase_ReturnResult>('findpurchase_return', data, undefined, reviver);
}

export interface SavePurchase_ReturnData {
    purchase_return_id?: number;
    purchase_return_no?: number;
    purchase_invoice_id?: number;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    paymentterm_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    purchase_return_date?: Date;
    pricelist_id?: number;
    unit_type_id?: number;
    total_amount?: Decimal;
    due_amount?: Decimal;
    total_discount?: Decimal;
    total_tax?: Decimal;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    financial_year_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: PurchaseReturnJournalDetail[];
    purchase_return_type?: number;
    purchase_return_status?: PurchaseReturnStatus;
    purchase_return_description?: string;
    use_currencies?: number;
    details?: SavePurchase_ReturnDetail[];
    disabled: number;
    disable_edit?: boolean;
    purchase_return_description_en?: string;
    purchase_return_description_ar?: string;
}

export interface SavePurchaseReturnNote {
    purchase_return_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}
export const savePurchaseReturnNote = async (data: SavePurchaseReturnNote) => {
    return post<SavePurchaseReturnNote, SaveNoteResult>('savepurchasereturnnote', data);
};
export const removePurchaseReturnNote = async (data: RemoveNote) => post<RemoveNote>('removepurchasereturnnote', data);
export interface SavePurchase_ReturnResult {
    purchase_return_id: number;
    purchase_return_no: number;
    purchase_return_type?: number;
    purchase_return_status?: PurchaseReturnStatus;
    subjournal_id?: number;
    batch_id?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: PurchaseReturnJournalDetail[];
    disable_edit?: boolean;
    details?: SavePurchase_ReturnDetail[];
    use_currencies?: number;
}

export const savePurchase_Return = async (data: SavePurchase_ReturnData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'purchase_return_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'qty' || key === 'price' || key === 'discount'
            || key === 'pending' || key === 'paid' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };
    return post<SavePurchase_ReturnData, SavePurchase_ReturnResult>('savepurchase_return', data, replacer, reviver);
}
export interface RemovePurchase_ReturnData {
    purchase_return_id: number;

}

export const removePurchase_Return = async (data: RemovePurchase_ReturnData) => post<RemovePurchase_ReturnData>('removepurchase_return', data);


export enum DeliveryInvoiceStatus {
    PARKED = 0,
    ACTIVE = 1
}


export interface Delivery_InvoiceDetail {
    delivery_invoice_detail_id?: number;
    row_type: RowType,
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    qty?: Decimal;
}
export interface SaveDelivery_InvoiceDetail {
    delivery_invoice_detail_id?: number;
    row_type:RowType;
    product_code?: string;
    unit_type_id?: number;
    qty?: Decimal;
}


export interface SaveDeliveryInvoiceNote {
    delivery_invoice_id?: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveDeliveryInvoiceNote = async (data: SaveDeliveryInvoiceNote) => {
    return post<SaveDeliveryInvoiceNote, SaveNoteResult>('savedeliveryinvoicenote', data);
};
export const removeDeliveryInvoiceNote = async (data: RemoveNote) => post<RemoveNote>('removedeliveryInvoicenote', data);


export interface Delivery_InvoiceData {
    customer_id?: number;
    customer_name?: string;
    customer_description?: string;
    salesrep_id?: number;
    salesrep_name?: string;
    delivery_invoice_id: number;
    delivery_invoice_no?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    warehouse_id?: number;
    warehouse_name?: string;
    delivery_invoice_date?: Date;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    delivery_invoice_description?: string;
    disable_edit?: boolean;
    delivery_invoice_status?: DeliveryInvoiceStatus;

    details?: Delivery_InvoiceDetail[];
    disabled: number;
}

export interface SearchDelivery_InvoicesData {
    delivery_invoice_id?: number;
    lang: string;
    search?: string;
    active: boolean;
    parked?: boolean;

}

export interface SearchDelivery_InvoiceResult {
    customer_id?: number;
    customer_name?: string;
    customer_description?: string;
    warehouse_id?: number;
    warehouse_name?: string;
    salesrep_id?: number;
    salesrep_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    disabled?: number;
    delivery_invoice_id: number;
    delivery_invoice_no: number;
    delivery_invoice_date: Date;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    disable_edit?: boolean;
    delivery_invoice_status?: DeliveryInvoiceStatus;
    delivery_invoice_description?: string;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    qty?: number;
}
export interface SearchDelivery_InvoicesResult {
    delivery_invoices: SearchDelivery_InvoiceResult[];
}


export const searchDelivery_Invoices = async (data: SearchDelivery_InvoicesData) => post<SearchDelivery_InvoicesData, SearchDelivery_InvoicesResult>('searchdelivery_invoices', data);
export interface FindDelivery_InvoiceData {
    delivery_invoice_id: number;
    lang?: string;

    active: boolean;
}
export interface FindDelivery_InvoiceResult {
    customer_id?: number;
    customer_name?: string;
    delivery_invoice_id: number;
    warehouse_id?: number;
    warehouse_name?: string;
    salesrep_id: number;
    salesrep_name: string;
    delivery_invoice_no: number;
    delivery_invoice_date: Date;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    contact_address?: string;
    contact_address1?: string;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    delivery_invoice_description?: string;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    disable_edit?: boolean;
    delivery_invoice_status?: DeliveryInvoiceStatus;
    notes?: NoteData[];

    details?: Delivery_InvoiceDetail[];
    disabled: number;
}

export const findDelivery_Invoice = async (data: FindDelivery_InvoiceData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        return value;
    };

    return post<FindDelivery_InvoiceData, FindDelivery_InvoiceResult>('finddelivery_invoice', data, undefined, reviver);
}
export interface SaveDelivery_InvoiceData {
    delivery_invoice_id?: number;
    delivery_invoice_no?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    warehouse_id?: number;
    customer_id?: number;
    salesrep_id?: number;
    branch_id?: number;
    billing_postal_code?: string;
    billing_phone?: string;
    billing_mobile?: string;
    billing_city_id?: number;
    billing_city_name?: string;
    billing_governerate_id?: number;
    billing_governerate_name?: string;
    billing_country_id?: number;
    billing_country_name?: string;
    delivery_postal_code?: string;
    delivery_phone?: string;
    delivery_mobile?: string;
    nationality_id?: string;
    delivery_city_id?: number;
    delivery_city_name?: string;
    delivery_governerate_id?: number;
    delivery_governerate_name?: string;
    delivery_country_id?: number;
    delivery_country_name?: string;
    delivery_invoice_date?: Date;
    unit_type_id?: number;
    delivery_name?: string;
    delivery_address?: string;
    delivery_address1?: string;
    billing_name?: string;
    billing_address?: string;
    billing_address1?: string;
    lang?: string;
    delivery_invoice_description?: string;
    disable_edit?: boolean;
    delivery_invoice_status?: DeliveryInvoiceStatus;

    details: SaveDelivery_InvoiceDetail[];
    disabled: number;
}

export interface SaveDelivery_InvoiceResult {
    delivery_invoice_id: number;
    delivery_invoice_no: number;
    details?: SaveDelivery_InvoiceDetail[];
    disable_edit?: boolean;
    delivery_invoice_status?: DeliveryInvoiceStatus;
}

export const saveDelivery_Invoice = async (data: SaveDelivery_InvoiceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'delivery_invoice_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        return value;
    };

    return post<SaveDelivery_InvoiceData, SaveDelivery_InvoiceResult>('savedelivery_invoice', data, replacer, reviver);
}
export interface RemoveDelivery_InvoiceData {
    delivery_invoice_id: number;

}

export const removeDelivery_Invoice = async (data: RemoveDelivery_InvoiceData) => post<RemoveDelivery_InvoiceData>('removedelivery_invoice', data);

export interface ReplenishmentBatch {
    replenishment_batch_id?: number;
    batch?: string;
    quantity?: Decimal;
}

export interface ReplenishmentBatch {
    replenishment_batch_id?: number;
    batch?: string;
    quantity?: Decimal;
}
export interface ReplenishmentSerial {
    replenishment_serial_id?: number;
    serial_pattern?: string;
    serial_no?: number;
    quantity?: Decimal;
}
export interface ReplenishmentExpiryDate {
    replenishment_expiry_id?: number;
    expiry_date?: Date;
    quantity?: Decimal;
}
export interface ReplenishmentDetail {
    replenishment_detail_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    use_batch?: number;
    use_serial?: number;
    use_expiry?: number;
    batches?: ReplenishmentBatch[];
    serials?: ReplenishmentSerial[];
    expiry_dates?: ReplenishmentExpiryDate[];
}
export interface SaveReplenishmentDetail {
    replenishment_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    batches?: ReplenishmentBatch[];
    serials?: ReplenishmentSerial[];
    expiry_dates?: ReplenishmentExpiryDate[];
}

export enum RecordStatus {
    PARKED = 0,
    ACTIVE = 1,
    COMPLETED = 2
}
export interface ReplenishmentData {
    replenishment_id: number;
    replenishment_no?: number;
    replenishment_date?: Date;
    replenishment_type?: number;
    warehouse_id?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_name?: string;
    sales_return_id?: number;
    sales_return_no?: number;
    purchase_order_id?: number;
    purchase_order_no?: number;
    work_order_id?: number;
    work_order_no?: number;
    issued_id?: number;
    issued_no?: number;
    credit_account_code?: string;
    adjustment_account_code?: string;
    transfer_employee_id?: number;
    transfer_employee_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    total_cost: Decimal;
    subjournal_id?: number;
    subjournal_name?: string;
    replenishment_description?: string;

    details?: ReplenishmentDetail[];
    replenishment_status: RecordStatus;
}


export interface ReplenishmentJournalDetailCostCenter {
    replenishment_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface ReplenishmentJournalDetail {
    replenishment_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: ReplenishmentJournalDetailCostCenter[];
}
export interface SearchReplenishmentsData {
    replenishment_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    parked: boolean;
    active: boolean;
}

export enum ReplenishmentType {
    OPEN_BALANCE = 0,
    PURCHASE_PRODUCTS = 1,
    PURCHASE_PRODUCTS_PO = 2,
    RETURN_PRODUCTS = 3,
    ADJUSTMENT_CREDIT = 4,
    TRANSFER_FROM_EMPLOYEE = 5,
    TRANSFER_FROM_SUPPLIER = 6,
    CUSTODY_CREDIT = 7,
    WORK_ORDER_CREDIT = 8
}
export interface SearchReplenishmentResult {
    replenishment_id: number;
    replenishment_no: number;
    replenishment_date: Date;
    replenishment_reference: string;
    replenishment_type?: ReplenishmentType;
    warehouse_id?: number;
    warehouse_no?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_name?: string;
    replenishment_description?: string;
    total_cost: Decimal;
    replenishment_status?: RecordStatus;

    transaction_type_id?: number;
    transaction_type_name?: string;
    transaction_type_description?: string;

}
export interface SearchReplenishmentsResult {
    replenishments: SearchReplenishmentResult[];
}

export const searchReplenishments = async (data: SearchReplenishmentsData) => {
    const reviver = (key: string, value: any): any => {

        if (key === 'total_cost') {
            return decimal(value);
        }
        else if (key === 'replenishment_date') {
            return date(value);
        }
        return value;
    }
    return post<SearchReplenishmentsData, SearchReplenishmentsResult>('searchreplenishments', data, undefined, reviver);
}

export interface FindReplenishmentData {
    replenishment_id: number;
    lang?: string;


}
export interface FindReplenishmentResult {
    replenishment_id: number;
    replenishment_no: number;
    replenishment_date: Date;
    replenishment_reference: string;
    replenishment_type?: number;
    warehouse_id?: number;
    warehouse_name?: string;
    account_code?: string;
    purchasing_account?: string;
    goods_in_transit_account?: string;
    goods_receipt_account?: string;
    purchased_assets_account?: string;
    returned_sales_account?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    total_cost: Decimal;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    replenishment_description?: string;
    sales_return_id?: number;
    sales_return_no?: number;
    purchase_order_id?: number;
    purchase_order_no?: number;
    work_order_id?: number;
    work_order_no?: number;
    issued_id?: number;
    issued_no?: number;
    transfer_employee_id?: number;
    transfer_employee_name?: string;
    credit_account_code?: string;
    adjustment_account_code?: string;
    vendor_id?: number;
    vendor_name?: string;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    media?: Media[];
    notes?: NoteData[];
    details?: ReplenishmentDetail[];
    replenishment_status: RecordStatus;
    journal_details?: ReplenishmentJournalDetail[];
    disable_edit?: boolean;

    transaction_type_id?: number;
    transaction_type_name?: string;
    transaction_type_description?: string;

}

export const findReplenishment = async (data: FindReplenishmentData) => {
    const reviver = (key: string, value: any): any => {

        if (key === 'replenishment_date') {
            return date(value);
        }
        if (key === 'quantity') {
            return decimal(value);
        }

        if (key === 'cost' || key === 'total_cost') {


            return decimal(value);
        }
        return value;
    };

    return post<FindReplenishmentData, FindReplenishmentResult>('findreplenishment', data, undefined, reviver);
}


export interface SaveReplenishmentData {
    replenishment_id?: number;
    replenishment_no?: number;
    replenishment_date?: Date;
    replenishment_reference?: string;
    warehouse_id?: number;
    employee_id?: number;
    custody_employee_id?: number;
    replenishment_type?: ReplenishmentType;
    sales_return_id?: number;
    purchase_order_id?: number;
    work_order_id?: number;
    work_order_no?: number;
    issued_id?: number;
    issued_no?: number;
    credit_account_code?: string;
    adjustment_account_code?: string;
    transfer_employee_id?: number;
    vendor_id?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_name?: string;
    currency_code?: string;
    unit_type_id?: number;
    journal_id?: number;
    lang?: string;
    replenishment_description?: string;
    media?: Media[];
    files?: File[];
    details: SaveReplenishmentDetail[];
    journal_details?: SaveReplenishmentJournalDetail[];
    replenishment_status?: RecordStatus;
    financial_year_id?: number;
    transaction_type_id?: number;

}

export interface SaveReplenishmentResult {
    replenishment_id: number;
    replenishment_no: number;
    replenishment_status: RecordStatus;
    details?: ReplenishmentDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    journal_details?: SaveReplenishmentJournalDetail[];
    media?: Media[];
    disable_edit?: boolean;
}

export interface SaveReplenishmentJournalDetailCostCenter {
    replenishment_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveReplenishmentJournalDetail {
    replenishment_journal_detail_id?: number;
    account_code?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveReplenishmentJournalDetailCostCenter[];
}


export const saveReplenishment = async (data: SaveReplenishmentData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'replenishment_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return postMP<SaveReplenishmentData, SaveReplenishmentResult>('savereplenishment', data, replacer, reviver);
}

export interface ReplenishmentJournalData {
    replenishment_id?: number;
    replenishment_date?: Date;
    warehouse_id?: number;
    employee_id?: number;
    replenishment_type?: ReplenishmentType;
    sales_return_id?: number;
    purchase_order_id?: number;
    work_order_id?: number;
    work_order_no?: number;
    issued_id?: number;
    issued_no?: number;
    credit_account_code?: string;
    adjustment_account_code?: string;
    vendor_id?: number;
    vendor_name?: string;
    lang?: string;
    details: SaveReplenishmentDetail[];
    journal_details?: SaveReplenishmentJournalDetail[];
}

export interface ReplenishmentJournalResult {
    journal_details?: ReplenishmentJournalDetail[];
}

export const replenishmentJournalEntries = async (data: ReplenishmentJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'replenishment_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return post<ReplenishmentJournalData, ReplenishmentJournalResult>('replenishmentjournalentries', data, replacer, reviver);
}

export interface SaveReplenishmentNote {
    replenishment_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveReplenishmentNote = async (data: SaveReplenishmentNote) => {
    return post<SaveReplenishmentNote, SaveNoteResult>('savereplenishmentnote', data);
};
export const removeReplenishmentNote = async (data: RemoveNote) => post<RemoveNote>('removereplenishmentnote', data);

export interface RemoveReplenishmentData {
    replenishment_id: number;

}

export const removeReplenishment = async (data: RemoveReplenishmentData) => post<RemoveReplenishmentData>('removereplenishment', data);

export interface IssuedBatch {
    issued_batch_id?: number;
    batch?: string;
    quantity?: Decimal;
}
export interface IssuedSerial {
    issued_serial_id?: number;
    product_serial?: string;
    quantity?: Decimal;
}
export interface IssuedExpiryDate {
    issued_expiry_id?: number;
    expiry_date?: Date;
    quantity?: Decimal;
}
export interface IssuedDetail {
    issued_detail_id?: number;
    product_code?: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    use_batch?: number;
    use_serial?: number;
    use_expiry?: number;
    batches?: IssuedBatch[];
    serials?: IssuedSerial[];
    expiry_dates?: IssuedExpiryDate[];
}
export interface SaveIssuedDetail {
    issued_detail_id?: number;
    product_code?: string;
    unit_type_id?: number;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    batches?: IssuedBatch[];
    serials?: IssuedSerial[];
    expiry_dates?: IssuedExpiryDate[];
}


export interface IssuedData {
    issued_id: number;
    issued_no?: number;
    issued_date?: Date;
    issued_type?: number;
    warehouse_id?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_name?: string;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    work_order_id?: number;
    work_order_no?: number;
    replenishment_id?: number;
    replenishment_no?: number;
    transfer_employee_id?: number;
    transfer_employee_name?: string;
    debit_account_code?: string;
    adjustment_account_code?: string;
    vendor_id?: number;
    vendor_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    total_cost: Decimal;
    subjournal_id?: number;
    subjournal_name?: string;
    issued_description?: string;
    to_warehouse_id?: number;
    to_warehouse_name?: string;
    transaction_type_id?: number;
    transaction_type_name?: string;
    transaction_type_description?: string;

    details?: IssuedDetail[];
    issued_status: RecordStatus;
    disabled: number;
}

export interface IssuedJournalDetailCostCenter {
    issued_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface IssuedJournalDetail {
    issued_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: IssuedJournalDetailCostCenter[];

}
export interface SearchIssuesData {
    issued_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    parked: boolean;
    active: boolean;
}

export enum IssuedType {
    SALES_PRODUCTS = 11,
    RETURN_PRODUCTS = 12,
    ADJUSTMENT_DEBIT = 13,
    TRANSFER_TO_EMPLOYEE = 14,
    TRANSFER_TO_SUPPLIER = 15,
    CUSTODY_DEBIT = 16,
    WORK_ORDER_DEBIT = 17
}


export interface SearchIssuedResult {
    issued_id: number;
    issued_no: number;
    issued_date: Date;
    issued_reference?: number;
    issued_type?: number;
    warehouse_id?: number;
    warehouse_no?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_name?: string;
    transfer_employee_id?: number;
    transfer_employee_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    issued_description?: string;
    total_cost: Decimal;
    issued_status?: RecordStatus;
    transaction_type_id?: number;
    transaction_type_name?: string;
    transaction_type_description?: string;
}
export interface SearchIssuesResult {
    issues: SearchIssuedResult[];
}


export const searchIssues = async (data: SearchIssuesData) => {
    const reviver = (key: string, value: any): any => {

        if (key === 'total_cost') {
            return decimal(value);
        }
        else if (key === 'issued_date') {
            return date(value);
        }
        return value;
    }

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchIssuesData, SearchIssuesResult>('searchissues', data, replacer, reviver);
}


export interface SearchIssuedProducts {
    issued_id?: number;
    lang: string;
    search?: string;
}

export interface SearchIssuedProductsResult {
    issued_details?: IssuedDetail[];
}

export const searchIssuedProducts = async (data: SearchIssuedProducts) => {


    const reviver = (key: string, value: any): any => {
        if (key === 'quantity' || key === 'cost') {
            return decimal(value);
        }

        return value;

    };

    return post<SearchIssuedProducts, SearchIssuedProductsResult>('searchissuedproducts', data, undefined, reviver);
}

export interface FindIssuedData {
    issued_id: number;
    lang?: string;
}
export interface FindIssuedResult {
    issued_id: number;
    issued_no: number;
    issued_date: Date;
    issued_reference?: string;
    issued_type?: number;
    warehouse_id?: number;
    warehouse_name?: string;
    sales_account?: string;
    goods_in_transit_account?: string;
    goods_receipt_account?: string;
    purchased_assets_account?: string;
    returned_sales_account?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    total_cost: Decimal;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    issued_description?: string;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    work_order_id?: number;
    work_order_no?: number;
    replenishment_id?: number;
    replenishment_no?: number;
    transfer_employee_id?: number;
    transfer_employee_name?: string;
    to_warehouse_id?: number;
    to_warehouse_name?: string;
    debit_account_code?: string;
    adjustment_account_code?: string;
    vendor_id?: number;
    vendor_name?: string;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    issued_status: RecordStatus;
    media?: Media[];
    notes?: NoteData[];
    details?: IssuedDetail[];
    disabled: number;
    journal_details?: IssuedJournalDetail[];
    disable_edit?: boolean;
    transaction_type_id?: number;
    transaction_type_name?: string;
    transaction_type_description?: string;

}

export const findIssued = async (data: FindIssuedData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {

        if (key === 'quantity') {
            return decimal(value);
        }

        if (key === 'cost' || key === 'total_cost') {
            return decimal(value);
        }

        return value;
    };

    return post<FindIssuedData, FindIssuedResult>('findissued', data, replacer, reviver);
}

export interface SaveIssuedData {
    issued_id?: number;
    issued_no?: number;
    issued_date?: Date;
    issued_reference?: string;
    warehouse_id?: number;
    employee_id?: number;
    custody_employee_id?: number;
    issued_type?: number;
    sales_invoice_id?: number;
    purchase_return_id?: number;
    work_order_id?: number;
    work_order_no?: number;
    replenishment_id?: number;
    replenishment_no?: number;
    debit_account_code?: string;
    adjustment_account_code?: string;
    transfer_employee_id?: number;
    vendor_id?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_name?: string;
    currency_code?: string;
    unit_type_id?: number;
    journal_id?: number;
    total_cost?: Decimal;
    lang?: string;
    issued_description?: string;
    to_warehouse_id?: number;
    to_warehouse_name?: string;

    transaction_type_id?: number;
    media?: Media[];
    files?: File[];
    notes?: NoteData[];
    journal_details?: SaveIssuedJournalDetail[];
    details: SaveIssuedDetail[];
    issued_status?: RecordStatus;
    financial_year_id?: number;

}

export interface SaveIssuedResult {
    issued_id: number;
    issued_no: number;
    issued_status: RecordStatus;
    details?: IssuedDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    journal_details?: SaveIssuedJournalDetail[];
    media?: Media[];
    disable_edit?: boolean;
    transaction_type_id?: number;

}



export interface SaveIssuedJournalDetailCostCenter {
    issued_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveIssuedJournalDetail {
    issued_journal_detail_id?: number;
    account_code?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveIssuedJournalDetailCostCenter[];
}
export interface SaveIssuedNote {
    issued_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}
export interface SaveNoteResult {
    note_id: number;
}
export const saveIssuedNote = async (data: SaveIssuedNote) => {
    return post<SaveIssuedNote, SaveNoteResult>('saveissuednote', data);
};
export const saveIssued = async (data: SaveIssuedData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'issued_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') {
            return decimal(value);
        }
        if (key === 'total_cost') {
            return decimal(value);
        }

        return value;
    };

    return postMP<SaveIssuedData, SaveIssuedResult>('saveissued', data, replacer, reviver);
}

export interface IssuedJournalData {
    issued_id?: number;
    issued_date?: Date;
    warehouse_id?: number;
    employee_id?: number;
    issued_type?: IssuedType;
    purchase_return_id?: number;
    sales_invoice_id?: number;
    work_order_id?: number;
    work_order_no?: number;
    debit_account_code?: string;
    adjustment_account_code?: string;
    transfer_employee_id?: number;
    vendor_id?: number;
    lang?: string;
    details: SaveIssuedDetail[];
    journal_details?: SaveIssuedJournalDetail[];
}
export interface IssuedJournalResult {
    journal_details?: IssuedJournalDetail[];
}
export const issuedJournalEntries = async (data: IssuedJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'issued_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'quantity' || key === 'debit' || key === 'credit' || key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return post<IssuedJournalData, IssuedJournalResult>('issuedjournalentries', data, replacer, reviver);
}

export const removeIssuedNote = async (data: RemoveNote) => post<RemoveNote>('removeissuednote', data);
export interface RemoveIssuedData {
    issued_id: number;

}

export const removeIssued = async (data: RemoveIssuedData) => post<RemoveIssuedData>('removeissued', data);


export interface InventoryReplenishmentBatch {
    inv_replenishment_batch_id?: number;
    batch?: string;
    quantity?: Decimal;
}
export interface InventoryReplenishmentSerial {
    inv_replenishment_serial_id?: number;
    serial_pattern?: string;
    serial_no?: number;
    quantity?: Decimal;
}
export interface InventoryReplenishmentExpiryDate {
    inv_replenishment_expiry_id?: number;
    expiry_date?: Date;
    quantity?: Decimal;
}
export interface InventoryAdjustmentReplenishmentDetail {
    inv_replenishment_detail_id?: number;
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    use_batch?: number;
    use_serial?: number;
    use_expiry?: number;
    batches?: InventoryReplenishmentBatch[];
    serials?: InventoryReplenishmentSerial[];
    expiry_dates?: InventoryReplenishmentExpiryDate[];
}
export interface InventoryIssuedBatch {
    inv_issued_batch_id?: number;
    batch?: string;
    quantity?: Decimal;
}
export interface InventoryIssuedSerial {
    inv_issued_serial_id?: number;
    product_serial?: string;
    quantity?: Decimal;
}
export interface InventoryIssuedExpiryDate {
    inv_issued_expiry_id?: number;
    expiry_date?: Date;
    quantity?: Decimal;
}
export interface InventoryAdjustmentIssuedDetail {
    inv_issued_detail_id?: number;
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    use_batch?: number;
    use_serial?: number;
    use_expiry?: number;
    batches?: IssuedBatch[];
    serials?: IssuedSerial[];
    expiry_dates?: IssuedExpiryDate[];
}


export interface InventoryAdjustmentDetail {
    inv_adjustment_detail_id?: number;
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    quantity?: Decimal;
    actual_quantity?: Decimal;
}
export interface SaveInventoryAdjustmentDetail {
    inv_adjustment_detail_id?: number;
    product_code: string;
    unit_type_id?: number;
    quantity?: Decimal;
    actual_quantity?: Decimal;
}

export interface InventoryAdjustmentData {
    warehouse_id?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_name?: string;
    inv_adjustment_id: number;
    inv_adjustment_no?: number;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    lang?: string;
    inv_adjustment_description?: string;

    details?: InventoryAdjustmentDetail[];
    disabled: number;
}

export interface SearchInventoryAdjustmentData {
    inv_adjustment_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    parked: boolean;
    active: boolean;
}

export interface SearchInventoryAdjustmentResult {
    inv_adjustment_id: number;
    inv_adjustment_no: number;
    inv_adjustment_date?: Date;
    warehouse_id?: number;
    warehouse_no?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_number?: number;
    employee_name?: string;
    replenishment_transaction_type_id?: number;
    replenishment_transaction_type_name?: string;
    issued_transaction_type_id?: number;
    issued_transaction_type_name?: string;
    replenishment_subjournal_id?: number;
    replenishment_subjournal_name?: string;
    issued_subjournal_id?: number;
    issued_subjournal_name?: string;
    replenishment_batch_id?: number;
    replenishment_batch_name?: string;
    issued_batch_id?: number;
    issued_batch_name?: string;
    replenishment_account_code?: string;
    replenishment_account_name?: string;
    issued_account_code?: string;
    issued_account_name?: string;
    replenishment_id?: number;
    replenishment_no?: number;
    issued_id?: number;
    issued_no?: number;
    inv_adjustment_status: RecordStatus;
    inv_adjustment_description?: string;
}
export interface SearchInventoryAdjustmentsResult {
    inv_adjustments: SearchInventoryAdjustmentResult[];
}


export const searchInventoryAdjustments = async (data: SearchInventoryAdjustmentData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'inv_adjustment_date') {
            return date(value);
        }
        return value;
    };
    return post<SearchInventoryAdjustmentData, SearchInventoryAdjustmentsResult>('searchinventoryadjustment', data, replacer, reviver);
}
export interface FindInventoryAdjustmentData {
    inv_adjustment_id: number;
    lang?: string;

}
export interface FindInventoryAdjustmentResult {
    inv_adjustment_id: number;
    inv_adjustment_no: number;
    inv_adjustment_date?: Date;
    warehouse_id?: number;
    warehouse_no?: number;
    warehouse_name?: string;
    employee_id?: number;
    employee_number?: number;
    employee_name?: string;
    replenishment_transaction_type_id?: number;
    replenishment_transaction_type_name?: string;
    issued_transaction_type_id?: number;
    issued_transaction_type_name?: string;
    replenishment_subjournal_id?: number;
    replenishment_subjournal_name?: string;
    issued_subjournal_id?: number;
    issued_subjournal_name?: string;
    replenishment_batch_id?: number;
    replenishment_batch_name?: string;
    issued_batch_id?: number;
    issued_batch_name?: string;
    replenishment_account_code?: string;
    replenishment_account_name?: string;
    issued_account_code?: string;
    issued_account_name?: string;
    replenishment_id?: number;
    replenishment_no?: number;
    issued_id?: number;
    issued_no?: number;
    inv_adjustment_status: RecordStatus;
    inv_adjustment_description?: string;
    notes?: NoteData[];

    details?: InventoryAdjustmentDetail[];
    replenishments?: InventoryAdjustmentReplenishmentDetail[];
    issues?: InventoryAdjustmentIssuedDetail[];
}

export const findInventoryAdjustment = async (data: FindInventoryAdjustmentData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'quantity' || key === 'actual_quantity') {
            return decimal(value);
        }
        else if (key === 'inv_adjustment_date') {
            return date(value);
        }

        return value;
    };

    return post<FindInventoryAdjustmentData, FindInventoryAdjustmentResult>('findinventoryadjustment', data, undefined, reviver);
}

export interface SaveInventoryReplenishmentDetail {
    inv_replenishment_detail_id?: number;
    product_code: string;
    unit_type_id?: number;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    batches?: InventoryReplenishmentBatch[];
    serials?: InventoryReplenishmentSerial[];
    expiry_dates?: InventoryReplenishmentExpiryDate[];
}
export interface SaveInventoryIssuedDetail {
    inv_issued_detail_id?: number;
    product_code: string;
    unit_type_id?: number;
    quantity?: Decimal;
    cost?: Decimal;
    total_cost?: Decimal;
    batches?: InventoryIssuedBatch[];
    serials?: InventoryIssuedSerial[];
    expiry_dates?: InventoryIssuedExpiryDate[];
}
export interface SaveInventoryAdjustmentData {
    inv_adjustment_id?: number;
    inv_adjustment_no?: number;
    inv_adjustment_date?: Date;
    warehouse_id?: number;
    employee_id?: number;
    replenishment_transaction_type_id?: number;
    issued_transaction_type_id?: number;
    replenishment_subjournal_id?: number;
    issued_subjournal_id?: number;
    replenishment_batch_id?: number;
    issued_batch_id?: number;
    replenishment_account_code?: string;
    issued_account_code?: string;
    inv_adjustment_status?: RecordStatus;
    inv_adjustment_description?: string;
    lang?: string;

    details?: SaveInventoryAdjustmentDetail[];
    replenishments?: SaveInventoryReplenishmentDetail[];
    issues?: SaveInventoryIssuedDetail[];
}

export interface SaveInventoryAdjustmentResult {
    inv_adjustment_id: number;
    inv_adjustment_no: number;
    inv_adjustment_status: RecordStatus;
    details?: InventoryAdjustmentDetail[];
    replenishments?: InventoryAdjustmentReplenishmentDetail[];
    issues?: InventoryAdjustmentIssuedDetail[];
    disable_edit?: boolean;
}

export interface SaveInventoryAdjustmentNote {
    inv_adjustment_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveInventoryAdjustmentNote = async (data: SaveInventoryAdjustmentNote) => {
    return post<SaveInventoryAdjustmentNote, SaveNoteResult>('saveinventoryadjustmentnote', data);
};

export const saveInventoryAdjustment = async (data: SaveInventoryAdjustmentData) => {

    const replacer = (key: string, value: any): any => {

        if (key === 'inv_adjustment_date' && value) {
            return isoDate(value);
        }
        return value;
    }

    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        if (key === 'acutal_qty') {
            return decimal(value);
        }


        return value;
    };

    return post<SaveInventoryAdjustmentData, SaveInventoryAdjustmentResult>('saveinventoryadjustment', data, replacer, reviver);
}
export const removeInventoryAdjustmentNote = async (data: RemoveNote) => post<RemoveNote>('removeinventoryadjustmentnote', data);

export interface RemoveInventoryAdjustmentData {
    inv_adjustment_id: number;

}

export const removeInventoryAdjustment = async (data: RemoveInventoryAdjustmentData) => post<RemoveInventoryAdjustmentData>('removeinventoryadjustment', data);

export interface Adjust_CostDetail {
    adjust_cost_detail_id?: number;
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    cost?: Decimal;
    new_cost?: Decimal;
}
export interface saveAdjust_CostDetail {
    adjust_cost_detail_id?: number;
    product_code: string;
    unit_type_id?: number;
    cost?: Decimal;
    new_cost?: Decimal;
}

export interface Adjust_CostData {
    employee_id?: number;
    employee_name?: string;
    adjust_cost_id: number;
    adjust_cost_no?: number;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    lang?: string;
    adjust_cost_description?: string;

    details?: Adjust_CostDetail[];
    disabled: number;
}

export interface SearchAdjust_CostsData {
    adjust_cost_id?: number;
    lang: string;
    search?: string;
    active: boolean;
}

export interface SearchAdjust_CostResult {
    employee_id?: number;
    employee_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    disabled?: number;
    adjust_cost_id: number;
    adjust_cost_no: number;
    adjust_cost_date: Date;
    lang?: string;
    adjust_cost_description?: string;
}
export interface SearchAdjust_CostsResult {
    adjust_costs: SearchAdjust_CostResult[];
}


export const searchAdjust_Costs = async (data: SearchAdjust_CostsData) => post<SearchAdjust_CostsData, SearchAdjust_CostsResult>('searchadjust_costs', data);
export interface FindAdjust_CostData {
    adjust_cost_id: number;
    lang?: string;

    active: boolean;
}
export interface FindAdjust_CostResult {
    adjust_cost_id: number;
    adjust_cost_no: number;
    adjust_cost_date: Date;
    employee_id?: number;
    employee_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    lang?: string;
    adjust_cost_description?: string;
    notes?: NoteData[];

    details?: Adjust_CostDetail[];
    disabled: number;
}

export const findAdjust_Cost = async (data: FindAdjust_CostData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'cost') {
            return decimal(value);
        }

        if (key === 'acutal_cost') {
            return decimal(value);
        }


        return value;
    };

    return post<FindAdjust_CostData, FindAdjust_CostResult>('findadjust_cost', data, undefined, reviver);
}

export interface SaveAdjust_CostData {
    adjust_cost_id?: number;
    adjust_cost_no?: number;
    adjust_cost_date?: Date;
    branch_id?: number;
    employee_id?: number;
    unit_type_id?: number;
    lang?: string;
    adjust_cost_description?: string;

    details: saveAdjust_CostDetail[];
    disabled: number;
}

export interface SaveAdjust_CostResult {
    adjust_cost_id: number;
    adjust_cost_no: number;
    details?: Adjust_CostDetail[];
}

export interface SaveAdjust_CostNote {
    adjust_cost_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export interface SaveNoteResult {
    note_id: number;
}

export const saveAdjust_CostNote = async (data: SaveAdjust_CostNote) => {
    return post<SaveAdjust_CostNote, SaveNoteResult>('saveadjust_costnote', data);
};

export const saveAdjust_Cost = async (data: SaveAdjust_CostData) => {

    const replacer = (key: string, value: any): any => {

        if (key === 'adjust_cost_date' && value) {
            return isoDate(value);
        }
        return value;
    }

    const reviver = (key: string, value: any): any => {
        if (key === 'cost') {
            return decimal(value);
        }

        if (key === 'acutal_cost') {
            return decimal(value);
        }


        return value;
    };

    return post<SaveAdjust_CostData, SaveAdjust_CostResult>('saveadjust_cost', data, replacer, reviver);
}
export const removeAdjust_CostNote = async (data: RemoveNote) => post<RemoveNote>('removeadjust_costnote', data);

export interface RemoveAdjust_CostData {
    adjust_cost_id: number;

}

export const removeAdjust_Cost = async (data: RemoveAdjust_CostData) => post<RemoveAdjust_CostData>('removeadjust_cost', data);

export interface Warehouse_BalanceDetail {
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;
}
export interface saveWarehouse_BalanceDetail {
    product_code: string;
    unit_type_id?: number;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;
}

export interface Warehouse_BalanceData {
    warehouse_id: number;
    warehouse_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    category_id?: number;
    category_name?: string;
    product_code?: string;
    product_name?: string;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;
    lang?: string;

    details?: Warehouse_BalanceDetail[];
    disabled: number;
}

export interface SearchWarehouse_BalancesData {
    warehouse_id?: number;
    warehouse_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    category_id?: number;
    category_name?: string;
    product_code?: string;
    product_name?: string;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;
    lang: string;
    search?: string;
    active: boolean;
}

export interface SearchWarehouse_BalanceResult {
    warehouse_id: number;
    warehouse_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    category_id?: number;
    category_name?: string;
    product_code: string;
    product_name?: string;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;
    disabled?: number;
    lang?: string;
}
export interface SearchWarehouse_BalancesResult {
    warehouse_balances: SearchWarehouse_BalanceResult[];
}


export const searchWarehouse_Balances = async (data: SearchWarehouse_BalancesData) => post<SearchWarehouse_BalancesData, SearchWarehouse_BalancesResult>('searchwarehouse_balances', data);
export interface FindWarehouse_BalanceData {
    warehouse_id: number;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    product_code?: string;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;

    lang?: string;

    active: boolean;
}
export interface FindWarehouse_BalanceResult {
    warehouse_id?: number;
    warehouse_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    lang?: string;
    product_code: string;
    openbalance_qty?: Decimal;
    replenishment_qty?: Decimal;
    issued_qty?: Decimal;
    adjustment_qty?: Decimal;
    net_qty?: Decimal;

    details?: Warehouse_BalanceDetail[];
    disabled: number;
}

export const findWarehouse_Balance = async (data: FindWarehouse_BalanceData) => post<FindWarehouse_BalanceData, FindWarehouse_BalanceResult>('findwarehouse_balance', data);

export interface SaveWarehouse_BalanceData {
    warehouse_id?: number;
    unit_type_id?: number;
    lang?: string;

    details: saveWarehouse_BalanceDetail[];
    disabled: number;
}

export interface SaveWarehouse_BalanceResult {
    warehouse_id: number;
}

export const saveWarehouse_Balance = async (data: SaveWarehouse_BalanceData) => post<SaveWarehouse_BalanceData, SaveWarehouse_BalanceResult>('savewarehouse_balance', data);
export interface RemoveWarehouse_BalanceData {
    warehouse_id: number;

}

export const removeWarehouse_Balance = async (data: RemoveWarehouse_BalanceData) => post<RemoveWarehouse_BalanceData>('removewarehouse_balance', data);


export enum ReceiveCashStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum ReceiveCashType {
    Customer = 1,
    Vendor = 2,
    Employee = 3,
    Other = 4,
    Custody_Employee = 5,
    Open_Balance = 6,
}
export interface Receive_CashDetail {
    receive_cash_inv_detail_id?: number;
    receive_cash_id?: number;
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}
export interface saveReceive_CashDetail {
    receive_cash_inv_detail_id?: number;
    receive_cash_id?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}
export interface Receive_Cash_PurchaseDetail {
    receive_cash_return_detail_id?: number;
    receive_cash_id?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}
export interface saveReceive_Cash_PurchaseDetail {
    receive_cash_return_detail_id?: number;
    receive_cash_id?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}

export interface ReceiveCashJournalData {
    receive_cash_id?: number;
    customer_id?: number;
    vendor_id?: number;
    employee_id?: number;
    custody_employee_id?: number;
    currency_code?: string;
    receive_cash_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    receive_cash_status?: ReceiveCashStatus;
    receive_cash_type?: number;
    financial_year_id?: number;
    details?: saveReceive_CashDetail[];
    journal_details?: SaveReceiveCashJournalDetail[];
    use_currencies?: number;

}

export interface SaveReceiveCashJournalDetailCostCenter {
    receive_cash_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveReceiveCashJournalDetail {
    receive_cash_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveReceiveCashJournalDetailCostCenter[];
}

export interface ReceiveCashJournalResult {
    journal_details?: ReceiveCashJournalDetail[];
}

export const receiveCashJournalEntries = async (data: ReceiveCashJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'receive_cash_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return post<ReceiveCashJournalData, ReceiveCashJournalResult>('receivecashjournalentries', data, replacer, reviver);
}
export interface ReceiveCashJournalDetailCostCenter {
    receive_cash_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface ReceiveCashJournalDetail {
    receive_cash_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: ReceiveCashJournalDetailCostCenter[];

}

export interface Receive_CashData {
    receive_cash_id: number;
    receive_cash_no?: number;
    receive_cash_date: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    total_amount?: Decimal;
    receive_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    receive_cash_description?: string;
    disable_edit?: boolean;
    receive_cash_status?: ReceiveCashStatus;

    invoice_details?: Receive_CashDetail[];
    purchase_return_details?: Receive_Cash_PurchaseDetail[];
    disabled: number;
    use_currencies?: number;
}

export interface SearchReceive_CashsData {
    receive_cash_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchReceive_CashResult {
    receive_cash_id: number;
    receive_cash_no?: number;
    receive_cash_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    total_amount?: Decimal;
    receive_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    receive_cash_description?: string;
    disable_edit?: boolean;
    receive_cash_status?: ReceiveCashStatus;
    invoice_details?: Receive_CashDetail[];
    purchase_return_details?: Receive_Cash_PurchaseDetail[];
    disabled?: number;
    use_currencies?: number;
}
export interface SearchReceive_CashsResult {
    receive_cash: Receive_CashData[];
}


export const searchReceive_Cashs = async (data: SearchReceive_CashsData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') {
            return decimal(value);
        }

        return value;
    };
    return post<SearchReceive_CashsData, SearchReceive_CashsResult>('searchreceive_cash', data, replacer, reviver);

}

export interface FindReceive_CashData {
    receive_cash_id?: number;
    invoice_details?: Receive_CashDetail[];
    purchase_return_details?: Receive_Cash_PurchaseDetail[];
    disable_edit?: boolean;
    journal_details?: ReceiveCashJournalDetail[];
    lang?: string;



}
export interface FindReceive_CashResult {
    receive_cash_id?: number;
    receive_cash_no?: number;
    receive_cash_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    receive_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    receive_cash_description?: string;
    receive_cash_status?: ReceiveCashStatus;
    disable_edit?: boolean;
    journal_details?: ReceiveCashJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;
    invoice_details?: Receive_CashDetail[];
    purchase_return_details?: Receive_Cash_PurchaseDetail[];
    disabled?: number;
}

export const findReceive_Cash = async (data: FindReceive_CashData) => {


    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining' || key === 'exchange_rate' || key === 'debit'
            || key === 'credit' || key === 'local_debit' || key === 'local_credit') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindReceive_CashData, FindReceive_CashResult>('findreceive_cash', data, undefined, reviver);
}
export interface SaveReceive_CashData {
    receive_cash_id?: number;
    receive_cash_no?: number;
    receive_cash_reference?: string;
    receive_cash_date?: Date;
    branch_id?: number;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    receive_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    receive_cash_description?: string;
    receive_cash_description_en?: string;
    receive_cash_description_ar?: string;
    disable_edit?: boolean;
    receive_cash_status?: ReceiveCashStatus;
    use_currencies?: number;

    invoice_details?: saveReceive_CashDetail[];
    purchase_return_details?: saveReceive_Cash_PurchaseDetail[];
    journal_details?: SaveReceiveCashJournalDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
}

export interface SaveReceive_CashResult {
    receive_cash_id?: number;
    receive_cash_no?: number;
    receive_cash_status?: ReceiveCashStatus;
    disable_edit?: boolean;
    invoice_details?: Receive_CashDetail[];
    purchase_return_details?: Receive_Cash_PurchaseDetail[];
    journal_details?: ReceiveCashJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;

}

export const saveReceive_Cash = async (data: SaveReceive_CashData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'receive_cash_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'credit' || key === 'local_debit' || key === 'local_credit') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SaveReceive_CashData, SaveReceive_CashResult>('savereceive_cash', data, replacer, reviver);
}
export interface RemoveReceive_CashData {
    receive_cash_id: number;
    invoice_details?: Receive_CashDetail[];

}

export const removeReceive_Cash = async (data: RemoveReceive_CashData) => post<RemoveReceive_CashData>('removereceive_cash', data);


export enum BankReceiveChequeStatus {
    PARKED = 0,
    ACTIVE = 1
}

export interface SaveBankReceiveChequeMember {
    bank_receive_cheque_member_id?: number;
    bank_receive_cheque_id?: number;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface BankReceiveChequeMember extends SaveBankReceiveChequeMember {
    bank_receive_cheque_member_id?: number;
    bank_receive_cheque_id?: number;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
    bank_account_id?: number;
}

export interface BankReceiveChequesJournalData {
    bank_receive_cheque_id?: number;
    customer_id?: number;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    bank_receive_cheque_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    bank_receive_cheque_status?: BankReceiveChequeStatus;
    bank_receive_cheque_type?: number;
    financial_year_id?: number;
    members?: SaveBankReceiveChequeMember[];
    journal_details?: SaveBankReceiveChequesJournalDetail[];
    use_currencies?: number;
}

export interface SaveBankReceiveChequesJournalDetailCostCenter {
    bank_receive_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveBankReceiveChequesJournalDetail {
    bank_receive_cheque_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveBankReceiveChequesJournalDetailCostCenter[];
}

export interface BankReceiveChequesJournalResult {
    journal_details?: BankReceiveChequesJournalDetail[];
}

export const bankReceiveChequesJournalEntries = async (data: BankReceiveChequesJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'bank_receive_cheque_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'amount') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'cost' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<BankReceiveChequesJournalData, BankReceiveChequesJournalResult>('bankreceivechequesjournalentries', data, replacer, reviver);
}
export interface BankReceiveChequesJournalDetailCostCenter {
    bank_receive_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface BankReceiveChequesJournalDetail {
    bank_receive_cheque_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: BankReceiveChequesJournalDetailCostCenter[];

}
export interface BankReceiveChequeData {
    bank_receive_cheque_id: number;
    bank_receive_cheque_no?: number;
    bank_receive_cheque_name?: string;
    currency_code?: string;
    bank_receive_cheque_date?: Date;
    bank_account_id?: number;
    bank_account_name?: string;
    employee_id?: number;
    employee_name?: string;
    amount?: Decimal;
    grand_total_amount?: Decimal;
    bank_receive_cheque_status?: BankReceiveChequeStatus;
    bank_receive_cheque_description?: string;
    disable_edit?: boolean;
    members?: BankReceiveChequeMember[];
    journal_details?: BankReceiveChequesJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    disabled: number;
    use_currencies?: number;
    lang?: string;
}

export interface SearchBankReceiveChequesData {
    bank_receive_cheque_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}


export interface SearchBankReceiveChequeResult {
    bank_receive_cheque_id: number;
    bank_receive_cheque_no?: number;
    bank_receive_cheque_name?: string;
    currency_code?: string;
    bank_receive_cheque_date?: Date;
    bank_account_id?: number;
    bank_account_name?: string;
    employee_id?: number;
    amount?: Decimal;
    grand_total_amount?: Decimal;
    employee_name?: string;
    bank_receive_cheque_status?: BankReceiveChequeStatus;
    bank_receive_cheque_description?: string;
    disable_edit?: boolean;
    members?: BankReceiveChequeMember[];
    journal_details?: BankReceiveChequesJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    lang?: string;
    disabled: number;
    use_currencies?: number;
}

export interface SearchBankReceiveChequesResult {
    bank_receive_cheques: SearchBankReceiveChequeResult[];
}

export interface SearchReceiveChequesResult {
    bank_receive_cheques: BankReceiveChequeMember[];
}


export const searchBankReceiveCheques = async (data: SearchBankReceiveChequesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'bank_receive_cheque_date' && value) {
            return date(value);
        }

        return value;
    };

    return post<SearchBankReceiveChequesData, SearchBankReceiveChequesResult>('searchbankreceivecheques', data, replacer, reviver);
}


export const searchReceiveCheques = async (data: SearchBankReceiveChequesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        else if (key === 'cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' || key === 'grand_total_amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchBankReceiveChequesData, SearchReceiveChequesResult>('searchreceivecheques', data, replacer, reviver);
}

export interface FindBankReceiveChequeData {
    bank_receive_cheque_id: number;
    disable_edit?: boolean;
    journal_details?: BankReceiveChequesJournalDetail[];
    lang: string;
}

export interface FindBankReceiveChequeResult {
    bank_receive_cheque_id: number;
    bank_receive_cheque_no?: number;
    bank_receive_cheque_name?: string;
    currency_code?: string;
    bank_receive_cheque_date?: Date;
    bank_account_id?: number;
    bank_account_name?: string;
    employee_id?: number;
    employee_name?: string;
    bank_receive_cheque_status?: BankReceiveChequeStatus;
    bank_receive_cheque_description?: string;
    disable_edit?: boolean;
    amount?: Decimal;
    grand_total_amount?: Decimal;
    members?: BankReceiveChequeMember[];
    journal_details?: BankReceiveChequesJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    disabled: number;
    lang?: string;
    use_currencies?: number;

}


export const findBankReceiveCheque = async (data: FindBankReceiveChequeData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'bank_receive_cheque_date' && value) {
            return date(value);
        }
        else if (key === 'amount' || key === 'grand_total_amount' && value) {
            return decimal(value);
        }
        else if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindBankReceiveChequeData, BankReceiveChequeData>('findbankreceivecheque', data, undefined, reviver);
}

export interface SaveBankReceiveChequeData {
    bank_receive_cheque_id?: number;
    bank_receive_cheque_no?: number;
    lang: string;
    bank_receive_cheque_name?: string;
    bank_receive_cheque_description?: string;
    bank_account_id?: number;
    employee_id?: number;
    bank_receive_cheque_status?: BankReceiveChequeStatus;
    currency_code?: string;
    bank_receive_cheque_date?: Date;
    bank_receive_cheque_description_en?: string;
    bank_receive_cheque_description_ar?: string;
    disable_edit?: boolean;
    members?: SaveBankReceiveChequeMember[];
    amount?: Decimal;
    journal_details?: BankReceiveChequesJournalDetail[];
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    disabled: number;
    use_currencies?: number;
}

export interface SaveBankReceiveChequeResult {
    bank_receive_cheque_id: number;
    bank_receive_cheque_no?: number;
    bank_receive_cheque_date?: Date;
    bank_account_id?: number;
    bank_account_name?: string;
    employee_id?: number;
    employee_name?: string;
    amount?: Decimal;
    grand_total_amount?: Decimal;
    bank_receive_cheque_status?: BankReceiveChequeStatus;
    disable_edit: boolean;
    members?: SaveBankReceiveChequeMember[];
    journal_details?: BankReceiveChequesJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    batch_id?: number;
    subjournal_id?: number;
    disabled: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;
    lang?: string;
}

export const saveBankReceiveCheque = async (data: SaveBankReceiveChequeData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'bank_receive_cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }

    const reviver = (key: string, value: any): any => {
        if (key === 'amount' || key === 'grand_total_amount') {
            return decimal(value);
        }
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<SaveBankReceiveChequeData, SaveBankReceiveChequeResult>('savebankreceivecheque', data, replacer, reviver);
}
export interface RemoveBankReceiveChequeData {
    bank_receive_cheque_id: number;
}

export const removeBankReceiveCheque = async (data: RemoveBankReceiveChequeData) => post<RemoveBankReceiveChequeData>('removebankreceivecheque', data);


export enum PayoutCashStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum PayoutCashType {
    Customer = 1,
    Vendor = 2,
    Employee = 3,
    Other = 4,
    Custody_Employee = 5,
}

export interface PayoutCashJournalData {
    payout_cash_id?: number;
    customer_id?: number;
    vendor_id?: number;
    employee_id?: number;
    custody_employee_id?: number;
    currency_code?: string;
    payout_cash_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    payout_cash_status?: PayoutCashStatus;
    payout_cash_type?: number;
    financial_year_id?: number;
    details?: savePayout_CashDetail[];
    journal_details?: SavePayoutCashJournalDetail[];
    use_currencies?: number;
}

export interface SavePayoutCashJournalDetailCostCenter {
    payout_cash_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SavePayoutCashJournalDetail {
    payout_cash_journal_detail_id?: number;
    account_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SavePayoutCashJournalDetailCostCenter[];
}

export interface PayoutCashJournalResult {
    journal_details?: PayoutCashJournalDetail[];
}

export const payoutCashJournalEntries = async (data: PayoutCashJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'payout_cash_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'exchange_rate' || key === 'local_debit' || key === 'local_credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'cost' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<PayoutCashJournalData, PayoutCashJournalResult>('payoutcashjournalentries', data, replacer, reviver);
}
export interface PayoutCashJournalDetailCostCenter {
    payout_cash_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface PayoutCashJournalDetail {
    payout_cash_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: PayoutCashJournalDetailCostCenter[];

}

export interface Payout_CashDetail {
    payout_cash_inv_detail_id?: number;
    payout_cash_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface savePayout_CashDetail {
    payout_cash_inv_detail_id?: number;
    payout_cash_id?: number;
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
    use_currencies?: number;

}

export interface Payout_Cash_SalesDetail {

    payout_cash_return_detail_id?: number;
    payout_cash_id?: number;
    sales_return_id: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface savePayout_Cash_SalesDetail {
    payout_cash_return_detail_id?: number;
    payout_cash_id?: number;
    sales_return_id?: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}


export interface Payout_CashData {
    payout_cash_id: number;
    payout_cash_no?: number;
    payout_cash_reference?: string;
    payout_cash_date: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    total_amount?: Decimal;
    payout_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    payout_cash_description?: string;
    disable_edit?: boolean;
    payout_cash_status?: PayoutCashStatus;
    invoice_details?: Payout_CashDetail[];
    sales_return_details?: Payout_Cash_SalesDetail[];
    disabled: number;
    use_currencies?: number;
}

export interface SearchPayout_CashsData {
    payout_cash_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchPayout_CashResult {
    payout_cash_id: number;
    payout_cash_no?: number;
    payout_cash_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    total_amount?: Decimal;
    payout_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    payout_cash_description?: string;
    disable_edit?: boolean;

    invoice_details?: Payout_CashData[];
    sales_return_details?: Payout_CashData[];
    disabled?: number;
}
export interface SearchPayout_CashsResult {
    payout_cash: Payout_CashData[];
}


export const searchPayout_Cashs = async (data: SearchPayout_CashsData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') {
            return decimal(value);
        }

        return value;
    };
    return post<SearchPayout_CashsData, SearchPayout_CashsResult>('searchpayout_cash', data, replacer, reviver);

}

export interface FindPayout_CashData {
    payout_cash_id?: number;
    invoice_details?: Payout_CashDetail[];
    sales_return_details?: Payout_Cash_SalesDetail[];
    disable_edit?: boolean;
    journal_details?: PayoutCashJournalDetail[];
    lang?: string;


}
export interface FindPayout_CashResult {
    payout_cash_id?: number;
    payout_cash_no?: number;
    payout_cash_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    payout_cash_description?: string;
    payout_cash_status?: PayoutCashStatus;
    disable_edit?: boolean;
    journal_details?: PayoutCashJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;
    invoice_details?: Payout_CashDetail[];
    sales_return_details?: Payout_Cash_SalesDetail[];
    disabled?: number;
}

export const findPayout_Cash = async (data: FindPayout_CashData) => {


    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindPayout_CashData, FindPayout_CashResult>('findpayout_cash', data, undefined, reviver);
}
export interface SavePayout_CashData {
    payout_cash_id?: number;
    payout_cash_no?: number;
    payout_cash_reference?: string;
    payout_cash_date?: Date;
    branch_id?: number;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_cash_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    payout_cash_description?: string;
    disable_edit?: boolean;
    payout_cash_status?: PayoutCashStatus;
    use_currencies?: number;
    invoice_details?: savePayout_CashDetail[];
    sales_return_details?: savePayout_Cash_SalesDetail[];
    journal_details?: PayoutCashJournalDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    payout_cash_description_en?: string;
    payout_cash_description_ar?: string;
}

export interface SavePayout_CashResult {
    payout_cash_id?: number;
    payout_cash_no?: number;
    payout_cash_status?: PayoutCashStatus;
    disable_edit?: boolean;
    invoice_details?: Payout_CashDetail[];
    sales_return_details?: Payout_Cash_SalesDetail[];
    journal_details?: PayoutCashJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;
}

export const savePayout_Cash = async (data: SavePayout_CashData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'payout_cash_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SavePayout_CashData, SavePayout_CashResult>('savepayout_cash', data, replacer, reviver);
}
export interface RemovePayout_CashData {
    payout_cash_id: number;

}

export const removePayout_Cash = async (data: RemovePayout_CashData) => post<RemovePayout_CashData>('removepayout_cash', data);

export interface SearchCustodiesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface CustodyData {
    custody_id: number;
    custody_account_code?: string;
    cost_center_code?: string;
    custody_name?: string;
    custody_description?: string;
    employee_id?: number;
    employee_name?: string;
}
export interface SearchCustodiesResult {
    custodies: CustodyData[];
}

export const searchCustodies = async (data: SearchCustodiesData) => post<SearchCustodiesData, SearchCustodiesResult>('searchcustodies', data);
export interface FindCustodyData {
    custody_id: number;
    lang: string;
}
export interface FindCustodyResult {
    custody_id: number;
    custody_name?: string;
    custody_description?: string;
    custody_account_code?: string;
    cost_center_code?: string;
    employee_id?: number;
    employee_name?: string;
    disabled: number;
}

export const findCustody = async (data: FindCustodyData) => post<FindCustodyData, FindCustodyResult>('findcustody', data);

export interface SaveCustodyData {
    custody_id?: number;
    lang: string;
    custody_name?: string;
    custody_description?: string;
    custody_account_code?: string;
    cost_center_code?: string;
    employee_id?: number;
    employee_name?: string;
    disabled: number;
}

export interface SaveCustodyResult {
    custody_id: number;
}

export const saveCustody = async (data: SaveCustodyData) => post<SaveCustodyData, SaveCustodyResult>('savecustody', data);
export interface RemoveCustodyData {
    custody_id: number;
}

export const removeCustody = async (data: RemoveCustodyData) => post<RemoveCustodyData>('removecustody', data);


export interface SearchExpensesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface ExpenseData {
    expense_id: number;
    expense_account_code?: string;
    expense_account_name?: string;
    cost_center_code?: string;
    cost_center_name?: string;
    expense_name?: string;
    expense_description?: string;
    vendor_id?: number;
    vendor_name?: string;
}
export interface SearchExpensesResult {
    expenses: ExpenseData[];
}

export const searchExpenses = async (data: SearchExpensesData) => post<SearchExpensesData, SearchExpensesResult>('searchexpenses', data);
export interface FindExpenseData {
    expense_id: number;
    lang: string;
}
export interface FindExpenseResult {
    expense_id: number;
    expense_name?: string;
    expense_description?: string;
    expense_account_code?: string;
    expense_account_name?: string;
    cost_center_code?: string;
    cost_center_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    disabled: number;
}

export const findExpense = async (data: FindExpenseData) => post<FindExpenseData, FindExpenseResult>('findexpense', data);

export interface SaveExpenseData {
    expense_id?: number;
    lang: string;
    expense_name?: string;
    expense_description?: string;
    expense_account_code?: string;
    cost_center_code?: string;
    vendor_id?: number;
    vendor_name?: string;
    disabled: number;
}

export interface SaveExpenseResult {
    expense_id: number;
}

export const saveExpense = async (data: SaveExpenseData) => post<SaveExpenseData, SaveExpenseResult>('saveexpense', data);
export interface RemoveExpenseData {
    expense_id: number;
}

export const removeExpense = async (data: RemoveExpenseData) => post<RemoveExpenseData>('removeexpense', data);


export enum ReceiveCheckStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum ReceiveCheckType {
    Customer = 1,
    Vendor = 2,
    Employee = 3,
    Other = 4,
    Custody = 5,
}

export enum ChequeLocation {
    CASHBOX = 1,
    NONTRADE = 2,
    BANK = 3,
    CUSTOMER = 4,
    VENDOR = 5,
}

export enum CompanyChequeType {
    DIRECT = 1,
    INDIRECT = 2,
}

export enum ChequeStatus {
    UNCOLLECTED = 1,
    COLLECTED = 2,
    REFUSED = 3,
    RETURNED = 4,
    ALL = 5,
}

export interface Receive_CheckDetail {
    receive_cheque_inv_detail_id?: number;
    receive_cheque_id?: number;
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface saveReceive_CheckDetail {
    receive_cheque_inv_detail_id?: number;
    receive_cheque_id?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}
export interface Receive_Check_PurchaseDetail {

    receive_cheque_return_detail_id?: number;
    receive_cheque_id?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface saveReceive_Check_PurchaseDetail {
    receive_cheque_return_detail_id?: number;
    receive_cheque_id?: number;
    purchase_return_id: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}


export interface ReceiveCheckJournalData {
    receive_cheque_id?: number;
    customer_id?: number;
    branch_id?: number;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    receive_cheque_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    receive_cheque_status?: ReceiveCheckStatus;
    receive_cheque_type?: number;
    financial_year_id?: number;
    details?: saveReceive_CheckDetail[];
    journal_details?: SaveReceiveCheckJournalDetail[];
    use_currencies?: number;
}

export interface SaveReceiveCheckJournalDetailCostCenter {
    receive_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveReceiveCheckJournalDetail {
    receive_cheque_journal_detail_id?: number;
    account_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveReceiveCheckJournalDetailCostCenter[];
}

export interface ReceiveCheckJournalResult {
    journal_details?: ReceiveCheckJournalDetail[];
}

export const receiveCheckJournalEntries = async (data: ReceiveCheckJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'receive_cheque_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'cost' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<ReceiveCheckJournalData, ReceiveCheckJournalResult>('receiveChequejournalentries', data, replacer, reviver);
}
export interface ReceiveCheckJournalDetailCostCenter {
    receive_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface ReceiveCheckJournalDetail {
    receive_cheque_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: ReceiveCheckJournalDetailCostCenter[];
    use_currecies?: number;

}

export interface Receive_CheckData {
    receive_cheque_id: number;
    receive_cheque_no?: number;
    receive_cheque_date: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    receive_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    receive_cheque_description?: string;
    total_amount?: Decimal;
    disable_edit?: boolean;
    receive_cheque_status?: ReceiveCheckStatus;
    invoice_details?: Receive_CheckDetail[];
    purchase_return_details?: Receive_Check_PurchaseDetail[];
    disabled: number;
    use_currencies?: number;
}

export interface SearchReceive_ChecksData {
    receive_cheque_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchReceive_CheckResult {
    receive_cheque_id: number;
    receive_cheque_no?: number;
    receive_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    total_amount: Decimal;
    receive_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    receive_cheque_description?: string;
    disable_edit?: boolean;
    use_currencies?: number;

    invoice_details?: Receive_CheckData[];
    purchase_return_details?: Receive_CheckData[];
    disabled?: number;
}
export interface SearchReceive_ChecksResult {
    receive_cheques: Receive_CheckData[];
}


export const searchReceive_Checks = async (data: SearchReceive_ChecksData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' || key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') {
            return decimal(value);
        }

        return value;
    };
    return post<SearchReceive_ChecksData, SearchReceive_ChecksResult>('searchreceive_cheque', data, replacer, reviver);
}
export interface FindReceive_CheckData {
    receive_cheque_id?: number;
    invoice_details?: Receive_CheckDetail[];
    purchase_return_details?: Receive_Check_PurchaseDetail[];
    disable_edit?: boolean;
    journal_details?: ReceiveCashJournalDetail[];
    lang?: string;
}
export interface FindReceive_CheckResult {
    receive_cheque_id?: number;
    receive_cheque_no?: number;
    receive_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    receive_cheque_type?: number;
    receive_cheque_reference?: string;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    // bank_cheque_name?: string;
    lang?: string;
    receive_cheque_description?: string;
    receive_cheque_status?: ReceiveCheckStatus;
    disable_edit?: boolean;
    journal_details?: ReceiveCheckJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    use_currencies?: number;

    invoice_details?: Receive_CheckDetail[];
    purchase_return_details?: Receive_Check_PurchaseDetail[];
    disabled?: number;
    media: Media[];
}

export const findReceive_Check = async (data: FindReceive_CheckData) => {
    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindReceive_CheckData, FindReceive_CheckResult>('findreceive_cheque', data, undefined, reviver);
}
export interface SaveReceive_CheckData {
    receive_cheque_id?: number;
    receive_cheque_no?: number;
    receive_cheque_date?: Date;
    branch_id?: number;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    receive_cheque_type?: number;
    receive_cheque_reference?: string;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    // bank_cheque_name?: string;
    lang?: string;
    receive_cheque_description?: string;
    receive_cheque_description_en?: string;
    receive_cheque_description_ar?: string;
    disable_edit?: boolean;
    receive_cheque_status?: ReceiveCheckStatus;
    journal_details?: ReceiveCheckJournalDetail[];
    use_currencies?: number;

    invoice_details?: Receive_CheckDetail[];
    purchase_return_details?: Receive_Check_PurchaseDetail[];
    disabled?: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    media?: Media[];
    files?: File[];
}

export interface SaveReceive_CheckResult {
    receive_cheque_id?: number;
    receive_cheque_no?: number;
    cheque_id?: number;
    receive_cheque_status?: ReceiveCheckStatus;
    disable_edit?: boolean;
    journal_details?: ReceiveCashJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    invoice_details?: Receive_CheckDetail[];
    purchase_return_details?: Receive_Check_PurchaseDetail[];
    media: Media[];
    use_currencies?: number;
}

export const saveReceive_Check = async (data: SaveReceive_CheckData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'receive_cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return postMP<SaveReceive_CheckData, SaveReceive_CheckResult>('savereceive_cheque', data, replacer, reviver);
}
export interface RemoveReceive_CheckData {
    receive_cheque_id: number;

}

export const removeReceive_Check = async (data: RemoveReceive_CheckData) => post<RemoveReceive_CheckData>('removereceive_cheque', data);


export interface ChequesData {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    amount?: Decimal;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_status?: ChequeStatus;
    bank_receive_cheque_id?: number;
    bank_receive_cheque_no?: number;
    location?: number;
    lang?: string;
}

export interface SearchChequesData {
    cheque_id?: number;
    searchRange?: SearchRangeType;
    chequeRange?: ChequeStatus;
    search?: string;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
    location?: number;
    currency_code?: string;
    cheque_status?: ChequeStatus[];
    employee_id?: number;
    cashbox_id?: number;
    custody_employee_id?: number;
    bank_account_id?: number;
    lang?: string;

}

export interface SearchChequesResult {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    amount?: Decimal;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_status?: ChequeStatus;
    location?: number;
    bank_receive_cheque_id?: number;
    bank_receive_cheque_no?: number;
    lang?: string;
}
export interface SearchChequesResult {
    cheques: ChequesData[];
}


export const searchCheques = async (data: SearchChequesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }

        return value;
    };
    return post<SearchChequesData, SearchChequesResult>('searchcheques', data, replacer, reviver);
}



export interface FindChequesDetailsData {
    trx_type?: number;
    cashbox_id?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    receive_cheque_id?: number;
    amount?: Decimal;

}

export interface FindChequesDetailsResult {
    cashbox_name?: string;
    currency_code?: string;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    bank_id?: number;
    bank_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_status?: ChequeStatus;
    location?: number;
    lang?: string;
    trx_id?: number;
    trx_type?: number;
    cashbox_id?: number;
    trx_no?: number;
    trx_date?: Date;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    amount?: Decimal;
    cheque_details: FindChequesDetailsData[];

}
export interface FindChequesDetailsResults {
    cheques: FindChequesDetailsData[];
}
export interface ChequesDetailsTrxData {
    trx_type?: number;
    cashbox_id?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    amount?: Decimal;
    lang?: string;

}


export const findCheques = async (data: FindChequesDetailsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }

        if (key === 'cheque_date' || key === 'trx_date' || key === 'from_date' || key === 'to_date') {
            return date(value);
        }

        return value;
    };

    return post<FindChequesDetailsData, FindChequesDetailsResult>('findchequesdetails', data, undefined, reviver)
};

export interface Company_ChequesData {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    amount?: Decimal;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_status?: ChequeStatus;
    lang?: string;
    location?: number;
}

export interface CompanyChequesDetailsTrxData {
    trx_type?: number;
    cashbox_id?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    amount?: Decimal;
    lang?: string;
    payout_cheque_id?: number;

}
export interface SearchCompany_ChequesData {
    cheque_id?: number;
    searchRange?: SearchRangeType;
    chequeRange?: ChequeStatus;
    search?: string;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
    location?: number;
    cheque_status?: ChequeStatus[];
    employee_id?: number;
    cashbox_id?: number;
    custody_employee_id?: number;
    bank_account_id?: number;
    lang?: string;
}
export interface SearchCompany_ChequesResult {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    amount?: Decimal;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_status?: ChequeStatus;
    location?: number;
    lang?: string;
}
export interface SearchCompany_ChequesResult {
    company_cheques: Company_ChequesData[];
}
export const searchCompany_Cheques = async (data: SearchCompany_ChequesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }
        return value;
    };
    return post<SearchCompany_ChequesData, SearchCompany_ChequesResult>('searchcompanycheques', data, replacer, reviver);
}

export interface FindCompanyChequesDetailsData {
    trx_type?: number;
    cashbox_id?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    amount?: Decimal;
    payout_cheque_id?: number;

}

export interface FindCompanyChequesDetailsResult {
    cashbox_name?: string;
    currency_code?: string;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    custody_employee_id?: number;
    custody_employee_name?: string;
    other_account?: string;
    account_name?: string;
    bank_id?: number;
    bank_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_status?: ChequeStatus;
    location?: number;
    lang?: string;
    trx_id?: number;
    trx_type?: number;
    cashbox_id?: number;
    trx_no?: number;
    trx_date?: Date;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    amount?: Decimal;
    cheque_details: FindCompanyChequesDetailsData[];
    payout_cheque_id?: number;

}
export interface FindCompanyChequesDetailsResults {
    cheques: FindCompanyChequesDetailsData[];
}

export const findCompanyCheques = async (data: FindCompanyChequesDetailsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }

        if (key === 'cheque_date' || key === 'trx_date' || key === 'from_date' || key === 'to_date') {
            return date(value);
        }

        return value;
    };

    return post<FindCompanyChequesDetailsData, FindCompanyChequesDetailsResult>('findcompanychequesdetails', data, undefined, reviver)
};


export enum PayoutCheckStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum PayoutCheckType {
    Vendor = 1,
    Customer = 2,
    Employee = 3,
    Other = 4,
    Transfer_To_Bank = 5,
}

export interface Payout_CheckDetail {
    payout_cheque_inv_detail_id?: number;
    payout_cheque_id?: number;
    cheque_id?: number;
    cashbox_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;

}

export interface savePayout_CheckDetail {
    payout_cheque_inv_detail_id?: number;
    payout_cheque_id?: number;
    cashbox_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;

}
export interface PayoutCheckJournalData {
    payout_cheque_id?: number;
    customer_id?: number;
    branch_id?: number;
    vendor_id?: number;
    bank_account_id?: number;
    employee_id?: number;
    currency_code?: string;
    payout_cheque_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    payout_cheque_status?: PayoutCheckStatus;
    payout_cheque_type?: number;
    financial_year_id?: number;
    details?: savePayout_CheckDetail[];
    journal_details?: SavePayoutCheckJournalDetail[];
    use_currencies?: number;
}

export interface SavePayoutCheckJournalDetailCostCenter {
    payout_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SavePayoutCheckJournalDetail {
    payout_cheque_journal_detail_id?: number;
    account_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SavePayoutCheckJournalDetailCostCenter[];
}

export interface PayoutCheckJournalResult {
    journal_details?: PayoutCheckJournalDetail[];
}

export const payoutCheckJournalEntries = async (data: PayoutCheckJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'payout_cheque_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'cost' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<PayoutCheckJournalData, PayoutCheckJournalResult>('payoutChequejournalentries', data, replacer, reviver);
}
export interface PayoutCheckJournalDetailCostCenter {
    payout_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface PayoutCheckJournalDetail {
    payout_cheque_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: PayoutCheckJournalDetailCostCenter[];

}

export interface Payout_CheckData {
    payout_cheque_id: number;
    payout_cheque_no?: number;
    payout_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    // bank_cheque_name?: string;
    lang?: string;
    payout_cheque_description?: string;
    payout_cheque_status?: PayoutCheckStatus;
    disable_edit?: boolean;
    cheque_details?: Payout_CheckDetail[];
    disabled: number;
    use_currencies?: number;
}

export interface SearchPayout_ChecksData {
    payout_cheque_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
    employee_id?: number;

}

export interface SearchPayout_CheckResult {
    payout_cheque_id: number;
    payout_cheque_no?: number;
    payout_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    lang?: string;
    payout_cheque_description?: string;
    disable_edit?: boolean;
    payout_cheque_status?: PayoutCheckStatus;
    cheque_details?: Payout_CheckData[];
    disabled?: number;
    use_currencies?: number;
}
export interface SearchPayout_ChecksResult {
    payout_cheques: Payout_CheckData[];
}


export interface SearchPayout_Check_NumbersResult {
    payout_cheque_numbers: Payout_CheckDetail[];
}

export const searchPayout_Checks = async (data: SearchPayout_ChecksData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchPayout_ChecksData, SearchPayout_ChecksResult>('searchpayout_cheques', data, replacer, reviver);
}


export const searchChequeNumbers = async (data: SearchPayout_ChecksData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        else if (key === 'cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchPayout_ChecksData, SearchPayout_Check_NumbersResult>('searchpayout_cheque_numbers', data, replacer, reviver);
}

export interface FindPayout_CheckData {
    payout_cheque_id?: number;
    cheque_details?: Payout_CheckDetail[];
    disable_edit?: boolean;
    journal_details?: PayoutCheckJournalDetail[];
    lang?: string;
}
export interface FindPayout_CheckResult {
    payout_cheque_id?: number;
    payout_cheque_no?: number;
    payout_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    lang?: string;
    payout_cheque_description?: string;
    payout_cheque_status?: PayoutCheckStatus;
    disable_edit?: boolean;
    journal_details?: PayoutCheckJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    cheque_details?: Payout_CheckDetail[];
    disabled?: number;
    use_currencies?: number;
}

export const findPayout_Check = async (data: FindPayout_CheckData) => {
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindPayout_CheckData, FindPayout_CheckResult>('findpayout_cheque', data, undefined, reviver);
}
export interface SavePayout_CheckData {
    payout_cheque_id?: number;
    payout_cheque_no?: number;
    payout_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    cashbox_id?: number;
    cashbox_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    payout_cheque_reference?: string;
    payout_cheque_description_en?: string;
    payout_cheque_description_ar?: string;
    // bank_cheque_name?: string;
    lang?: string;
    payout_cheque_description?: string;
    disable_edit?: boolean;
    payout_cheque_status?: PayoutCheckStatus;
    journal_details?: PayoutCheckJournalDetail[];
    cheque_details?: Payout_CheckDetail[];
    disabled?: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    use_currencies?: number;
}

export interface SavePayout_CheckResult {
    payout_cheque_id?: number;
    payout_cheque_no?: number;
    payout_cheque_status?: PayoutCheckStatus;
    disable_edit?: boolean;
    journal_details?: PayoutCheckJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    cheque_details?: Payout_CheckDetail[];
    use_currencies?: number;
}

export const savePayout_Check = async (data: SavePayout_CheckData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'payout_cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SavePayout_CheckData, SavePayout_CheckResult>('savepayout_cheque', data, replacer, reviver);
}
export interface RemovePayout_CheckData {
    payout_cheque_id: number;

}

export const removePayout_Check = async (data: RemovePayout_CheckData) => post<RemovePayout_CheckData>('removepayout_cheque', data);


export enum CustodyExpensesStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum CustodyExpensesType {
    Cash = 1,
    Cheques = 2,
    Others = 3
}


export interface Custody_ExpenseDetail {
    custody_expenses_detail_id?: number;
    custody_expenses_id?: number;
    expense_id?: number;
    expense_name?: string;
    expenses_date?: Date;
    expenses_account_code?: string;
    expenses_account_name?: string;
    cost_center_code?: string;
    amount?: Decimal;
    vendor_id?: number;
    vendor_name?: string;
    tax_id?: number;
    tax_name?: string;
    vat?: Decimal;
    total_amount?: Decimal;
    total_vat?: Decimal;

}
export interface Custody_Expense_ChequesDetail {
    custody_expenses_cheque_detail_id?: number;
    custody_expenses_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_type?: number;
    bank_id?: number;
    bank_name?: string;
    amount?: Decimal;
}

export interface SaveCustody_ExpenseDetail {
    custody_expenses_detail_id?: number;
    custody_expenses_id?: number;
    expense_id?: number;
    expense_name?: string;
    expenses_date?: Date;
    expenses_account_code?: string;
    expenses_account_name?: string;
    cost_center_code?: string;
    amount?: Decimal;
    vendor_id?: number;
    vendor_name?: string;
    tax_id?: number;
    tax_name?: string;
    vat?: Decimal;
    total_amount?: Decimal;
    total_vat?: Decimal;
}

export interface SaveCustody_Expense_ChequesDetail {
    custody_expenses_cheque_detail_id?: number;
    custody_expenses_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_type?: number;
    bank_id?: number;
    bank_name?: string;
    amount?: Decimal;
}


export interface CustodyExpensesJournalData {
    custody_expenses_id?: number;
    customer_id?: number;
    branch_id?: number;
    branch_name?: string;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    custody_expenses_date?: Date;
    amount?: Decimal;
    custody_account_code?: string;
    other_account?: string;
    lang?: string;
    custody_expenses_status?: CustodyExpensesStatus;
    custody_expenses_type?: number;
    financial_year_id?: number;
    details?: SaveCustody_ExpenseDetail[];
    journal_details?: SaveCustodyExpensesJournalDetail[];
}

export interface SaveCustodyExpensesJournalDetailCostCenter {
    custody_expenses_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveCustodyExpensesJournalDetail {
    custody_expenses_journal_detail_id?: number;
    account_code?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveCustodyExpensesJournalDetailCostCenter[];
}

export interface CustodyExpensesJournalResult {
    journal_details?: CustodyExpensesJournalDetail[];
}

export const custodyExpensesJournalEntries = async (data: CustodyExpensesJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'custody_expenses_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'debit' || key === 'credit' || key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return post<CustodyExpensesJournalData, CustodyExpensesJournalResult>('custodyexpensesjournalentries', data, replacer, reviver);
}
export interface CustodyExpensesJournalDetailCostCenter {
    custody_expenses_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface CustodyExpensesJournalDetail {
    custody_expenses_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    debit?: Decimal;
    credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: CustodyExpensesJournalDetailCostCenter[];
}
export interface Custody_ExpenseData {
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    custody_expenses_id: number;
    custody_expenses_no?: number;
    custody_expenses_type?: number;
    custody_expenses_date?: Date;
    custody_account_code?: string;
    branch_id?: number;
    branch_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    tax_id?: number;
    tax_name?: string;
    total_amount?: Decimal;
    total_vat?: Decimal;
    lang?: string;
    custody_expenses_description?: string;
    journal_details?: CustodyExpensesJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,

    details?: Custody_ExpenseDetail[];
    cheques_details?: Custody_Expense_ChequesDetail[];
    disabled: number;
}

export interface SearchCustody_ExpensesData {
    custody_expenses_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchCustody_ExpenseResult {
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    custody_expenses_id: number;
    custody_expenses_no?: number;
    custody_expenses_date?: Date;
    custody_expenses_type?: number;
    custody_account_code?: string;
    branch_id?: number;
    branch_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    tax_id?: number;
    tax_name?: string;
    total_amount: Decimal;
    total_vat: Decimal;
    lang?: string;
    custody_expenses_description?: string;
    disable_edit?: boolean;
    custody_expenses_status?: CustodyExpensesStatus;
    details?: Custody_ExpenseDetail[];
    cheques_details?: Custody_Expense_ChequesDetail[];
    disabled: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CustodyExpensesJournalDetail[];
}
export interface SearchCustody_ExpensesResult {
    custody_expenses: SearchCustody_ExpenseResult[];
}


export const searchCustody_Expenses = async (data: SearchCustody_ExpensesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchCustody_ExpensesData, SearchCustody_ExpensesResult>('searchcustody_expenses', data, replacer);
}
export interface FindCustody_ExpenseData {
    custody_expenses_id: number;
    lang?: string;

    active: boolean;
}
export interface FindCustody_ExpenseResult {
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    custody_expenses_id?: number;
    custody_expenses_no?: number;
    custody_expenses_date?: Date;
    custody_expenses_type?: number;
    custody_account_code?: string;
    branch_id?: number;
    branch_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    tax_id?: number;
    tax_name?: string;
    total_amount: Decimal;
    total_vat: Decimal;
    lang?: string;
    custody_expenses_description?: string;
    disable_edit?: boolean;
    custody_expenses_status?: CustodyExpensesStatus;
    details?: Custody_ExpenseDetail[];
    cheques_details?: Custody_Expense_ChequesDetail[];
    disabled: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CustodyExpensesJournalDetail[];
}

export const findCustody_Expense = async (data: FindCustody_ExpenseData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }

        if (key === 'vat') {
            return decimal(value);
        }


        return value;
    };

    return post<FindCustody_ExpenseData, FindCustody_ExpenseResult>('findcustody_expenses', data, undefined, reviver);
}

export interface SaveCustody_ExpenseData {
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    custody_expenses_id?: number;
    custody_expenses_no?: number;
    custody_expenses_date?: Date;
    custody_expenses_type?: number;
    custody_account_code?: string;
    branch_id?: number;
    branch_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    tax_id?: number;
    tax_name?: string;
    total_amount?: Decimal;
    total_vat?: Decimal;
    lang?: string;
    disable_edit?: boolean;
    custody_expenses_status?: CustodyExpensesStatus;
    custody_expenses_description?: string;
    details?: SaveCustody_ExpenseDetail[];
    cheques_details?: SaveCustody_Expense_ChequesDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CustodyExpensesJournalDetail[];
    disabled: number;
}

export interface SaveCustody_ExpenseResult {
    custody_expenses_id: number;
    custody_expenses_no: number;
    disable_edit?: boolean;
    custody_expenses_status?: CustodyExpensesStatus;
    details?: SaveCustody_ExpenseDetail[];
    cheques_details?: SaveCustody_Expense_ChequesDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CustodyExpensesJournalDetail[];
}

export const saveCustody_Expense = async (data: SaveCustody_ExpenseData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'custody_expense_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'amount') {
            return decimal(value);
        }

        if (key === 'vat') {
            return decimal(value);
        }

        return value;
    };

    return post<SaveCustody_ExpenseData, SaveCustody_ExpenseResult>('savecustody_expenses', data, replacer, reviver);
}
export interface RemoveCustody_ExpenseData {
    custody_expenses_id: number;

}

export const removeCustody_Expense = async (data: RemoveCustody_ExpenseData) => post<RemoveCustody_ExpenseData>('removecustody_expenses', data);




export enum PayoutCoCheckStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum PayoutCoCheckType {
    Vendor = 1,
    Customer = 2,
    Employee = 3,
    Other = 4,
    CHEQUES_COLLECTIONS = 5,
}

export interface Payout_CoCheckDetail {
    payout_company_cheque_inv_detail_id?: number;
    payout_company_cheque_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface savePayout_CoCheckDetail {
    payout_company_cheque_inv_detail_id?: number;
    payout_company_cheque_id?: number;
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}
export interface Payout_CoCheck_SalesDetail {

    payout_company_cheque_return_detail_id?: number;
    payout_company_cheque_id?: number;
    sales_return_id: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface savePayout_CoCheck_SalesDetail {
    payout_company_cheque_return_detail_id?: number;
    payout_company_cheque_id?: number;
    sales_return_id: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}

export interface PayoutCoCheckJournalData {
    payout_company_cheque_id?: number;
    customer_id?: number;
    vendor_id?: number;
    branch_id?: number;
    employee_id?: number;
    currency_code?: string;
    payout_company_cheque_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    company_cheque_number?: string;
    company_cheque_date?: Date;
    payout_company_cheque_status?: PayoutCoCheckStatus;
    payout_company_cheque_type?: number;
    financial_year_id?: number;
    details?: savePayout_CoCheckDetail[];
    journal_details?: SavePayoutCoCheckJournalDetail[];
    use_currencies?: number;
}

export interface SavePayoutCoCheckJournalDetailCostCenter {
    payout_company_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SavePayoutCoCheckJournalDetail {
    payout_company_cheque_journal_detail_id?: number;
    account_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SavePayoutCoCheckJournalDetailCostCenter[];
}

export interface PayoutCoCheckJournalResult {
    journal_details?: PayoutCoCheckJournalDetail[];
}

export const payoutCoCheckJournalEntries = async (data: PayoutCoCheckJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'payout_company_cheque_date' || key === 'company_cheque_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'cost' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<PayoutCoCheckJournalData, PayoutCoCheckJournalResult>('payoutCoChequejournalentries', data, replacer, reviver);
}
export interface PayoutCoCheckJournalDetailCostCenter {
    payout_company_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface PayoutCoCheckJournalDetail {
    payout_company_cheque_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: PayoutCoCheckJournalDetailCostCenter[];
}
export interface Payout_CoCheckData {
    payout_company_cheque_id: number;
    payout_company_cheque_no?: number;
    payout_company_cheque_date: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_company_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    company_cheque_number?: string;
    cheque_id?: number;
    company_cheque_date?: Date;
    bank_company_cheque_name?: string;
    lang?: string;
    payout_company_cheque_description?: string;
    payout_company_cheque_status?: PayoutCoCheckStatus;
    disable_edit?: boolean;
    invoice_details?: Payout_CoCheckDetail[];
    sales_return_details?: Payout_CoCheck_SalesDetail[];
    disabled: number;
    use_currencies?: number;
}

export interface SearchPayout_CoChecksData {
    payout_company_cheque_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchPayout_CoCheckResult {
    payout_company_cheque_id: number;
    payout_company_cheque_no?: number;
    payout_company_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_company_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    company_cheque_number?: string;
    cheque_id?: number;
    company_cheque_date?: Date;
    payout_company_cheque_status?: PayoutCoCheckStatus;
    bank_company_cheque_name?: string;
    lang?: string;
    payout_company_cheque_description?: string;
    disable_edit?: boolean;
    invoice_details?: Payout_CoCheckData[];
    sales_return_details?: Payout_CoCheckData[];
    disabled?: number;
    use_currencies?: number;
}
export interface SearchPayout_CoChecksResult {
    payout_company_cheques: Payout_CoCheckData[];
}


export const searchPayout_CoChecks = async (data: SearchPayout_CoChecksData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchPayout_CoChecksData, SearchPayout_CoChecksResult>('searchpayout_company_cheques', data, replacer, reviver);
}
export interface FindPayout_CoCheckData {
    payout_company_cheque_id?: number;
    invoice_details?: Payout_CoCheckDetail[];
    sales_return_details?: Payout_CoCheck_SalesDetail[];
    disable_edit?: boolean;
    journal_details?: PayoutCoCheckJournalDetail[];
    lang?: string;


}
export interface FindPayout_CoCheckResult {
    payout_company_cheque_id?: number;
    payout_company_cheque_no?: number;
    payout_company_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_company_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    company_cheque_number?: string;
    cheque_id?: number;
    company_cheque_date?: Date;
    bank_company_cheque_name?: string;
    lang?: string;
    payout_company_cheque_description?: string;
    payout_company_cheque_status?: PayoutCoCheckStatus;
    disable_edit?: boolean;
    journal_details?: PayoutCoCheckJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    invoice_details?: Payout_CoCheckDetail[];
    sales_return_details?: Payout_CoCheck_SalesDetail[];
    disabled?: number;
    use_currencies?: number;
}

export const findPayout_CoCheck = async (data: FindPayout_CoCheckData) => {
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindPayout_CoCheckData, FindPayout_CoCheckResult>('findpayout_company_cheque', data, undefined, reviver);
}
export interface SavePayout_CoCheckData {
    payout_company_cheque_id?: number;
    payout_company_cheque_no?: number;
    payout_company_cheque_date?: Date;
    branch_id?: number;
    bank_account_id?: number;
    bank_account_name?: string;
    cashier_id?: number;
    cashier_name?: string;
    currency_code?: string;
    amount?: Decimal;
    payout_company_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    company_cheque_number?: string;
    cheque_id?: number;
    company_cheque_date?: Date;
    bank_company_cheque_name?: string;
    payout_company_cheque_reference?: string;
    payout_company_cheque_description_en?: string;
    payout_company_cheque_description_ar?: string;
    lang?: string;
    payout_company_cheque_description?: string;
    disable_edit?: boolean;
    payout_company_cheque_status?: PayoutCoCheckStatus;
    journal_details?: PayoutCoCheckJournalDetail[];
    invoice_details?: savePayout_CoCheckDetail[];
    sales_return_details?: savePayout_CoCheck_SalesDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    use_currencies?: number;
}

export interface SavePayout_CoCheckResult {
    payout_company_cheque_id?: number;
    payout_company_cheque_no?: number;
    payout_company_cheque_date?: Date;
    payout_company_cheque_status?: PayoutCoCheckStatus;
    cheque_id?: number;
    disable_edit?: boolean;
    journal_details?: PayoutCoCheckJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    invoice_details?: Payout_CoCheckDetail[];
    sales_return_details?: Payout_CoCheck_SalesDetail[];
    use_currencies?: number;
}

export const savePayout_CoCheck = async (data: SavePayout_CoCheckData) => {
    const replacer = (key: string, value: any): any => {

        if (key === 'payout_company_cheque_date' || key === 'company_cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SavePayout_CoCheckData, SavePayout_CoCheckResult>('savepayout_company_cheque', data, replacer, reviver);
}
export interface RemovePayout_CoCheckData {
    payout_company_cheque_id: number;

}

export const removePayout_CoCheck = async (data: RemovePayout_CoCheckData) => post<RemovePayout_CoCheckData>('removepayout_company_cheque', data);

export enum DebitNoteStatus {
    PARKED = 0,
    ACTIVE = 1
}


export enum DebitNoteTransactionType {
    DEPOSIT = 1,
    RECEIVE_CHEQUES = 2,
    CHEQUES_COLLECTIONS = 3,
    DEBIT_INTERST = 4,
    TRANSFER = 5,
    Other = 6,
    REJECTED = 7,

}


export enum DebitNoteType {
    CUSTOMER = 1,
    VENDOR = 2,
    EMPLOYEE = 3,
    OTHER = 4,
}

export interface Debit_NoteDetail {
    debit_note_inv_detail_id?: number;
    debit_note_id?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface saveDebit_NoteDetail {
    debit_note_inv_detail_id?: number;
    debit_note_id?: number;
    sales_invoice_id?: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}
export interface Debit_Note_PurchaseDetail {

    debit_note_return_detail_id?: number;
    debit_note_id?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface saveDebit_Note_PurchaseDetail {
    debit_note_return_detail_id?: number;
    debit_note_id?: number;
    purchase_return_id?: number;
    purchase_return_no?: number;
    purchase_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}

export interface Debit_Note_ChequesDetail {
    debit_note_cheque_detail_id?: number;
    debit_note_id?: number;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface saveDebit_Note_ChequesDetail {
    debit_note_cheque_detail_id?: number;
    debit_note_id?: number;
    bank_account_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface DebitNoteJournalData {
    debit_note_id?: number;
    customer_id?: number;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    debit_note_date?: Date;
    amount?: Decimal;
    account_code?: string;
    other_account?: string;
    cheque_number?: string;
    cheque_date?: Date,
    cheque_id?: number;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    debit_note_status?: DebitNoteStatus;
    debit_note_type?: number;
    transaction_type?: number;
    financial_year_id?: number;
    invoice_details?: saveDebit_NoteDetail[];
    purchase_return_details?: saveDebit_Note_PurchaseDetail[];
    cheques_details?: saveDebit_Note_ChequesDetail[];
    journal_details?: SaveDebitNoteJournalDetail[];
    use_currencies?: number;
}

export interface SaveDebitNoteJournalDetailCostCenter {
    debit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveDebitNoteJournalDetail {
    debit_note_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveDebitNoteJournalDetailCostCenter[];
}

export interface DebitNoteJournalResult {
    journal_details?: DebitNoteJournalDetail[];
}

export const debitNoteJournalEntries = async (data: DebitNoteJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'debit_note_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost' || key === 'due_amount' || key === 'total_refund' || key === 'remaining') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate'
            || key === 'cost' || key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<DebitNoteJournalData, DebitNoteJournalResult>('debitnotejournalentries', data, replacer, reviver);
}
export interface DebitNoteJournalDetailCostCenter {
    debit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface DebitNoteJournalDetail {
    debit_note_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: DebitNoteJournalDetailCostCenter[];
}

export interface Debit_NoteData {
    debit_note_id: number;
    debit_note_no?: number;
    debit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    debit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    debit_note_description?: string;
    cheques_details?: Debit_Note_ChequesDetail[];
    invoice_details?: Debit_NoteDetail[];
    purchase_return_details?: Debit_Note_PurchaseDetail[];
    debit_note_status?: DebitNoteStatus;
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}

export interface SearchDebit_NotesData {
    debit_note_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;

}

export interface SearchDebit_NoteResult {
    debit_note_id: number;
    debit_note_no?: number;
    debit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    debit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    debit_note_description?: string;
    debit_note_status?: DebitNoteStatus;
    cheques_details?: Debit_Note_ChequesDetail[];
    invoice_details?: Debit_NoteDetail[];
    purchase_return_details?: Debit_Note_PurchaseDetail[];
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}
export interface SearchDebit_NotesResult {
    debit_notes: SearchDebit_NoteResult[];
}


export const searchDebit_Notes = async (data: SearchDebit_NotesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' || key === 'total_amount' || key === 'due_amount' || key === 'total_paid' || key === 'total_refund' || key === 'remaining') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchDebit_NotesData, SearchDebit_NotesResult>('searchdebit_notes', data, replacer, reviver);
}
export interface FindDebit_NoteData {
    debit_note_id?: number;
    cheques_details?: Debit_Note_ChequesDetail[];
    invoice_details?: Debit_NoteDetail[];
    debit_note_status?: DebitNoteStatus;
    purchase_return_details?: Debit_Note_PurchaseDetail[];
    disable_edit?: boolean;
    lang?: string;
}
export interface FindDebit_NoteResult {
    debit_note_id?: number;
    debit_note_no?: number;
    debit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    debit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    debit_note_description?: string;
    cheques_details?: Debit_Note_ChequesDetail[];
    invoice_details?: Debit_NoteDetail[];
    purchase_return_details?: Debit_Note_PurchaseDetail[];
    debit_note_status?: DebitNoteStatus;
    disable_edit?: boolean;
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: DebitNoteJournalDetail[];
    document_type?: NoteDocumentType;
    document_id?: number;
    document_no?: number;
    media: Media[];
    use_currencies?: number;
}

export const findDebit_Note = async (data: FindDebit_NoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'credit_note_date' || key === 'cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }; const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount'
            || key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate'
            || key === 'total_paid' || key === 'total_refund' || key === 'remaining') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindDebit_NoteData, FindDebit_NoteResult>('finddebit_note', data, replacer, reviver);
}

export enum NoteDocumentType {
    COLLECTED_DEBIT_NOTE = 1,
    COLLECTED_CREDIT_NOTE = 2,

}
export interface SaveDebit_NoteData {
    debit_note_id?: number;
    debit_note_no?: number;
    debit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    debit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    lang?: string;
    debit_note_description?: string;
    debit_note_description_en?: string;
    debit_note_description_ar?: string;
    debit_note_status?: DebitNoteStatus;
    cheques_details?: Debit_Note_ChequesDetail[];
    invoice_details?: Debit_NoteDetail[];
    purchase_return_details?: Debit_Note_PurchaseDetail[];
    disabled?: number;
    disable_edit?: boolean;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: DebitNoteJournalDetail[];
    document_type?: NoteDocumentType;
    document_id?: number;
    media?: Media[];
    files?: File[];
    use_currencies?: number;
}

export interface SaveDebit_NoteResult {
    debit_note_id?: number;
    debit_note_no?: number;
    cheques_details?: Debit_Note_ChequesDetail[];
    debit_note_status?: DebitNoteStatus;
    disable_edit?: boolean;
    invoice_details?: Debit_NoteDetail[];
    purchase_return_details?: Debit_Note_PurchaseDetail[];
    journal_details?: DebitNoteJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    media: Media[];
}

export const saveDebit_Note = async (data: SaveDebit_NoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'debit_note_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount'
            || key === 'total_paid' || key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate'
            || key === 'total_paid' || key === 'total_refund' || key === 'remaining') && value) {
            return decimal(value);
        }

        return value;
    };
    return postMP<SaveDebit_NoteData, SaveDebit_NoteResult>('savedebit_note', data, replacer, reviver);
}
export interface RemoveDebit_NoteData {
    debit_note_id: number;

}

export const removeDebit_Note = async (data: RemoveDebit_NoteData) => post<RemoveDebit_NoteData>('removedebit_note', data);

export enum CollectedDebitChequesStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum CollectedDebitChequesTransactionType {
    DEPOSIT = 1,
    RECEIVE_CHEQUES = 2,
    CHEQUES_COLLECTIONS = 3,
    DEBIT_INTERST = 4,
    TRANSFER = 5,
    Other = 6,
    REJECTED = 7,
}


export enum CollectedDebitChequesType {
    CUSTOMER = 1,
    VENDOR = 2,
    EMPLOYEE = 3,
    OTHER = 4,
}

export interface Collected_Debit_ChequesDetail {
    collected_debit_cheque_detail_id?: number;
    collected_debit_cheque_id?: number;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_id?: number;
    debit_note_id?: number;
    debit_note_no?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface saveCollected_Debit_ChequesDetail {
    collected_debit_cheque_detail_id?: number;
    collected_debit_cheque_id?: number;
    bank_account_id?: number;
    cheque_id?: number;
    debit_note_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface CollectedDebitChequesJournalData {
    collected_debit_cheque_id?: number;
    customer_id?: number;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    collected_debit_cheque_date?: Date;
    amount?: Decimal;
    account_code?: string;
    other_account?: string;
    lang?: string;
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    collected_debit_cheque_type?: number;
    transaction_type?: number;
    financial_year_id?: number;
    cheques_details?: saveCollected_Debit_ChequesDetail[];
    journal_details?: SaveCollectedDebitChequesJournalDetail[];
    use_currencies?: number;
}

export interface SaveCollectedDebitChequesJournalDetailCostCenter {
    collected_debit_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveCollectedDebitChequesJournalDetail {
    collected_debit_cheque_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveCollectedDebitChequesJournalDetailCostCenter[];
}

export interface CollectedDebitChequesJournalResult {
    journal_details?: CollectedDebitChequesJournalDetail[];
}

export const collectedDebitChequesJournalEntries = async (data: CollectedDebitChequesJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'collected_debit_cheque_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'cost' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<CollectedDebitChequesJournalData, CollectedDebitChequesJournalResult>('collecteddebitchequesjournalentries', data, replacer, reviver);
}
export interface CollectedDebitChequesJournalDetailCostCenter {
    collected_debit_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface CollectedDebitChequesJournalDetail {
    collected_debit_cheque_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: CollectedDebitChequesJournalDetailCostCenter[];
}

export interface Collected_Debit_ChequesData {
    collected_debit_cheque_id: number;
    collected_debit_cheque_no?: number;
    collected_debit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_debit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_debit_cheque_description?: string;
    cheques_details?: Collected_Debit_ChequesDetail[];
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}

export interface SearchCollected_Debit_ChequessData {
    collected_debit_cheque_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;

}

export interface SearchCollected_Debit_ChequesResult {
    collected_debit_cheque_id: number;
    collected_debit_cheque_no?: number;
    collected_debit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_debit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_debit_cheque_description?: string;
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    cheques_details?: Collected_Debit_ChequesDetail[];
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}
export interface SearchCollected_Debit_ChequessResult {
    collected_debit_cheques: SearchCollected_Debit_ChequesResult[];
}


export const searchCollected_Debit_Chequess = async (data: SearchCollected_Debit_ChequessData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'total_amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchCollected_Debit_ChequessData, SearchCollected_Debit_ChequessResult>('searchcollected_debit_cheques', data, replacer, reviver);
}
export interface FindCollected_Debit_ChequesData {
    collected_debit_cheque_id?: number;
    cheques_details?: Collected_Debit_ChequesDetail[];
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    disable_edit?: boolean;
    lang?: string;
}
export interface FindCollected_Debit_ChequesResult {
    collected_debit_cheque_id?: number;
    collected_debit_cheque_no?: number;
    collected_debit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_debit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_debit_cheque_description?: string;
    cheques_details?: Collected_Debit_ChequesDetail[];
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    disable_edit?: boolean;
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CollectedDebitChequesJournalDetail[];
    use_currencies?: number;
    media: Media[];
}

export const findCollected_Debit_Cheques = async (data: FindCollected_Debit_ChequesData) => {
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount' || key === 'total_paid'
            || key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }
        return value;
    };

    return post<FindCollected_Debit_ChequesData, FindCollected_Debit_ChequesResult>('findcollected_debit_cheque', data, undefined, reviver);
}
export interface SaveCollected_Debit_ChequesData {
    collected_debit_cheque_id?: number;
    collected_debit_cheque_no?: number;
    collected_debit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_debit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_debit_cheque_description?: string;
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    cheques_details?: Collected_Debit_ChequesDetail[];
    disabled?: number;
    disable_edit?: boolean;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CollectedDebitChequesJournalDetail[];
    use_currencies?: number;
    media: Media[];
    files?: File[];
}

export interface SaveCollected_Debit_ChequesResult {
    collected_debit_cheque_id?: number;
    collected_debit_cheque_no?: number;
    cheques_details?: Collected_Debit_ChequesDetail[];
    collected_debit_cheque_status?: CollectedDebitChequesStatus;
    disable_edit?: boolean;
    media: Media[];
    journal_details?: CollectedDebitChequesJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;
}

export const saveCollected_Debit_Cheques = async (data: SaveCollected_Debit_ChequesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'collected_debit_cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount'
            || key === 'total_paid' || key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }
        return value;
    };
    return postMP<SaveCollected_Debit_ChequesData, SaveCollected_Debit_ChequesResult>('savecollected_debit_cheque', data, replacer, reviver);
}
export interface RemoveCollected_Debit_ChequesData {
    collected_debit_cheque_id: number;

}

export const removeCollected_Debit_Cheques = async (data: RemoveCollected_Debit_ChequesData) => post<RemoveCollected_Debit_ChequesData>('removecollected_debit_cheque', data);

export enum CollectedCreditChequesStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum CollectedCreditChequesTransactionType {
    DEPOSIT = 1,
    RECEIVE_CHEQUES = 2,
    CHEQUES_COLLECTIONS = 3,
    DEBIT_INTERST = 4,
    TRANSFER = 5,
    Other = 6,
    REJECTED = 7,
}


export enum CollectedCreditChequesType {
    CUSTOMER = 1,
    VENDOR = 2,
    EMPLOYEE = 3,
    OTHER = 4,
}

export interface Collected_Credit_ChequesDetail {
    collected_credit_cheque_detail_id?: number;
    collected_credit_cheque_id?: number;
    bank_account_id?: number;
    bank_account_name?: string;
    credit_note_id?: number;
    credit_note_no?: number;
    cheque_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    cheques_details?: Collected_Credit_ChequesDetail[];
    amount?: Decimal;
}

export interface saveCollected_Credit_ChequesDetail {
    collected_credit_cheque_detail_id?: number;
    collected_credit_cheque_id?: number;
    bank_account_id?: number;
    cheque_id?: number;
    credit_note_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface CollectedCreditChequesJournalData {
    collected_credit_cheque_id?: number;
    customer_id?: number;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    collected_credit_cheque_date?: Date;
    amount?: Decimal;
    account_code?: string;
    other_account?: string;
    lang?: string;
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    collected_credit_cheque_type?: number;
    transaction_type?: number;
    financial_year_id?: number;
    cheques_details?: saveCollected_Credit_ChequesDetail[];
    journal_details?: SaveCollectedCreditChequesJournalDetail[];
    use_currencies?: number;
}

export interface SaveCollectedCreditChequesJournalDetailCostCenter {
    collected_credit_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveCollectedCreditChequesJournalDetail {
    collected_credit_cheque_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveCollectedCreditChequesJournalDetailCostCenter[];
}

export interface CollectedCreditChequesJournalResult {
    journal_details?: CollectedCreditChequesJournalDetail[];
}

export const collectedCreditChequesJournalEntries = async (data: CollectedCreditChequesJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'collected_credit_cheque_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') {
            return decimal(value);
        }

        return value;
    };
    return post<CollectedCreditChequesJournalData, CollectedCreditChequesJournalResult>('collectedcreditchequesjournalentries', data, replacer, reviver);
}
export interface CollectedCreditChequesJournalDetailCostCenter {
    collected_credit_cheque_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface CollectedCreditChequesJournalDetail {
    collected_credit_cheque_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: CollectedCreditChequesJournalDetailCostCenter[];
}

export interface Collected_Credit_ChequesData {
    collected_credit_cheque_id: number;
    collected_credit_cheque_no?: number;
    collected_credit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_credit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_credit_cheque_description?: string;
    cheques_details?: Collected_Credit_ChequesDetail[];
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}

export interface SearchCollected_Credit_ChequessData {
    collected_credit_cheque_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;

}

export interface SearchCollected_Credit_ChequesResult {
    collected_credit_cheque_id: number;
    collected_credit_cheque_no?: number;
    collected_credit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_credit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_credit_cheque_description?: string;
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    cheques_details?: Collected_Credit_ChequesDetail[];
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}
export interface SearchCollected_Credit_ChequessResult {
    collected_credit_cheques: SearchCollected_Credit_ChequesResult[];
}


export const searchCollected_Credit_Chequess = async (data: SearchCollected_Credit_ChequessData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'total_amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchCollected_Credit_ChequessData, SearchCollected_Credit_ChequessResult>('searchcollected_credit_cheques', data, replacer, reviver);
}
export interface FindCollected_Credit_ChequesData {
    collected_credit_cheque_id?: number;
    cheques_details?: Collected_Credit_ChequesDetail[];
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    disable_edit?: boolean;
    lang?: string;
}
export interface FindCollected_Credit_ChequesResult {
    collected_credit_cheque_id?: number;
    collected_credit_cheque_no?: number;
    collected_credit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_credit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_credit_cheque_description?: string;
    cheques_details?: Collected_Credit_ChequesDetail[];
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    disable_edit?: boolean;
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CollectedCreditChequesJournalDetail[];
    media: Media[];
    use_currencies?: number;
}

export const findCollected_Credit_Cheques = async (data: FindCollected_Credit_ChequesData) => {
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount' || key === 'total_paid'
            || key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };

    return post<FindCollected_Credit_ChequesData, FindCollected_Credit_ChequesResult>('findcollected_credit_cheque', data, undefined, reviver);
}
export interface SaveCollected_Credit_ChequesData {
    collected_credit_cheque_id?: number;
    collected_credit_cheque_no?: number;
    collected_credit_cheque_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    collected_credit_cheque_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    collected_credit_cheque_description?: string;
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    cheques_details?: Collected_Credit_ChequesDetail[];
    disabled?: number;
    disable_edit?: boolean;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CollectedCreditChequesJournalDetail[];
    media: Media[];
    files?: File[];
    use_currencies?: number;
}

export interface SaveCollected_Credit_ChequesResult {
    collected_credit_cheque_id?: number;
    collected_credit_cheque_no?: number;
    cheques_details?: Collected_Credit_ChequesDetail[];
    collected_credit_cheque_status?: CollectedCreditChequesStatus;
    disable_edit?: boolean;
    media: Media[];
    journal_details?: CollectedCreditChequesJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
}

export const saveCollected_Credit_Cheques = async (data: SaveCollected_Credit_ChequesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'collected_credit_cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount'
            || key === 'total_paid' || key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return postMP<SaveCollected_Credit_ChequesData, SaveCollected_Credit_ChequesResult>('savecollected_credit_cheque', data, replacer, reviver);
}
export interface RemoveCollected_Credit_ChequesData {
    collected_credit_cheque_id: number;

}

export const removeCollected_Credit_Cheques = async (data: RemoveCollected_Credit_ChequesData) => post<RemoveCollected_Credit_ChequesData>('removecollected_credit_cheque', data);

export enum CreditNoteStatus {
    PARKED = 0,
    ACTIVE = 1
}


export enum CreditNoteTransactionType {
    PAID = 1,
    PAID_CHEQUES = 2,
    CHEQUES_COLLECTIONS = 3,
    CREDIT_INTERST = 4,
    TRANSFER = 5,
    REJECTED = 6,

}


export enum CreditNoteType {
    CUSTOMER = 1,
    VENDOR = 2,
    EMPLOYEE = 3,
    OTHER = 4,
}


export interface Credit_NoteDetail {
    credit_note_inv_detail_id?: number;
    credit_note_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface saveCredit_NoteDetail {
    credit_note_inv_detail_id?: number;
    credit_note_id?: number;
    purchase_invoice_id?: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}
export interface Credit_Note_SalesDetail {

    credit_note_return_detail_id?: number;
    credit_note_id?: number;
    sales_return_id: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;

}

export interface saveCredit_Note_SalesDetail {
    credit_note_return_detail_id?: number;
    credit_note_id?: number;
    sales_return_id?: number;
    sales_return_no?: number;
    sales_return_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;
    total_paid?: Decimal;
    total_refund?: Decimal;
    remaining?: Decimal;
}

export interface Credit_Note_ChequesDetail {
    credit_note_cheque_detail_id?: number;
    credit_note_id?: number;
    bank_account_id?: number;
    bank_account_name?: string;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface saveCredit_Note_ChequesDetail {
    credit_note_cheque_detail_id?: number;
    credit_note_id?: number;
    bank_account_id?: number;
    cheque_number?: string;
    cheque_date?: Date;
    currency_code?: string;
    amount?: Decimal;
}

export interface CreditNoteJournalData {
    credit_note_id?: number;
    customer_id?: number;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    vendor_id?: number;
    employee_id?: number;
    currency_code?: string;
    credit_note_date?: Date;
    amount?: Decimal;
    account_code?: string;
    other_account?: string;
    cheque_number?: string;
    cheque_date?: Date,
    cheque_id?: number;
    lang?: string;
    credit_note_status?: CreditNoteStatus;
    credit_note_type?: number;
    transaction_type?: number;
    financial_year_id?: number;
    invoice_details?: saveCredit_NoteDetail[];
    sales_return_details?: saveCredit_Note_SalesDetail[];
    cheques_details?: saveCredit_Note_ChequesDetail[];
    journal_details?: SaveCreditNoteJournalDetail[];
    use_currencies?: number;
}

export interface SaveCreditNoteJournalDetailCostCenter {
    credit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveCreditNoteJournalDetail {
    credit_note_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveCreditNoteJournalDetailCostCenter[];
}

export interface CreditNoteJournalResult {
    journal_details?: CreditNoteJournalDetail[];
}

export const creditNoteJournalEntries = async (data: CreditNoteJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'credit_note_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'credit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<CreditNoteJournalData, CreditNoteJournalResult>('creditnotejournalentries', data, replacer, reviver);
}
export interface CreditNoteJournalDetailCostCenter {
    credit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface CreditNoteJournalDetail {
    credit_note_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: CreditNoteJournalDetailCostCenter[];
}

export interface Credit_NoteData {
    credit_note_id: number;
    credit_note_no?: number;
    credit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    credit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    credit_note_description?: string;
    cheques_details?: Credit_Note_ChequesDetail[];
    invoice_details?: Credit_NoteDetail[];
    sales_return_details?: Credit_Note_SalesDetail[];
    credit_note_status?: CreditNoteStatus;
    disable_edit?: boolean;
    disabled: number;
    use_currencies?: number;
}

export interface SearchCredit_NotesData {
    credit_note_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;

}

export interface SearchCredit_NoteResult {
    credit_note_id: number;
    credit_note_no?: number;
    credit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    credit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    credit_note_description?: string;
    credit_note_status?: CreditNoteStatus;
    cheques_details?: Credit_Note_ChequesDetail[];
    invoice_details?: Credit_NoteDetail[];
    sales_return_details?: Credit_Note_SalesDetail[];
    disable_edit?: boolean;
    disabled?: number;
    use_currencies?: number;
}
export interface SearchCredit_NotesResult {
    credit_notes: SearchCredit_NoteResult[];
}


export const searchCredit_Notes = async (data: SearchCredit_NotesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' || key === 'total_amount' || key === 'due_amount' || key === 'total_paid') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchCredit_NotesData, SearchCredit_NotesResult>('searchcredit_notes', data, replacer, reviver);
}
export interface FindCredit_NoteData {
    credit_note_id?: number;
    cheques_details?: Credit_Note_ChequesDetail[];
    invoice_details?: Credit_NoteDetail[];
    credit_note_status?: CreditNoteStatus;
    sales_return_details?: Credit_Note_SalesDetail[];
    disable_edit?: boolean;
    lang?: string;
}
export interface FindCredit_NoteResult {
    credit_note_id?: number;
    credit_note_no?: number;
    credit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    credit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    credit_note_description?: string;
    cheques_details?: Credit_Note_ChequesDetail[];
    invoice_details?: Credit_NoteDetail[];
    sales_return_details?: Credit_Note_SalesDetail[];
    credit_note_status?: CreditNoteStatus;
    disable_edit?: boolean;
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CreditNoteJournalDetail[];
    document_type?: NoteDocumentType;
    document_id?: number;
    document_no?: number;
    media: Media[];
    use_currencies?: number;
}

export const findCredit_Note = async (data: FindCredit_NoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'credit_note_date' || key === 'cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount' || key === 'total_paid'
            || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };

    return post<FindCredit_NoteData, FindCredit_NoteResult>('findcredit_note', data, replacer, reviver);
}
export interface SaveCredit_NoteData {
    credit_note_id?: number;
    credit_note_no?: number;
    credit_note_date?: Date;
    branch_id?: number;
    branch_name?: string;
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    amount?: Decimal;
    transaction_type?: number;
    credit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    vendor_id?: number;
    vendor_name?: string;
    employee_id?: number;
    employee_name?: string;
    other_account?: string;
    account_name?: string;
    cheque_number?: string;
    cheque_id?: number;
    cheque_date?: Date;
    bank_id?: number;
    bank_name?: string;
    lang?: string;
    credit_note_description?: string;
    credit_note_description_en?: string;
    credit_note_description_ar?: string;
    credit_note_status?: CreditNoteStatus;
    cheques_details?: Credit_Note_ChequesDetail[];
    invoice_details?: Credit_NoteDetail[];
    sales_return_details?: Credit_Note_SalesDetail[];
    disabled: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    journal_details?: CreditNoteJournalDetail[];
    document_type?: NoteDocumentType;
    document_id?: number;
    document_no?: number;
    media?: Media[];
    files?: File[];
    use_currencies?: number;
}

export interface SaveCredit_NoteResult {
    credit_note_id?: number;
    credit_note_no?: number;
    cheque_id?: number;
    cheques_details?: Credit_Note_ChequesDetail[];
    credit_note_status?: CreditNoteStatus;
    disable_edit?: boolean;
    invoice_details?: Credit_NoteDetail[];
    sales_return_details?: Credit_Note_SalesDetail[];
    journal_details?: CreditNoteJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    media: Media[];
}

export const saveCredit_Note = async (data: SaveCredit_NoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'credit_note_date' || key === 'cheque_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'total_amount' || key === 'due_amount'
            || key === 'total_paid' || key === 'debit' || key === 'credit'
            || key === 'local_debit' || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return postMP<SaveCredit_NoteData, SaveCredit_NoteResult>('savecredit_note', data, replacer, reviver);
}
export interface RemoveCredit_NoteData {
    credit_note_id: number;

}

export const removeCredit_Note = async (data: RemoveCredit_NoteData) => post<RemoveCredit_NoteData>('removecredit_note', data);

export interface JournalDetailCostCenter {
    journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
    amount?: Decimal;
    local_amount?: Decimal;
}
export interface JournalDetail {
    journal_detail_id?: number;
    journal_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    detail_description_en?: string;
    detail_description_ar?: string;
    costcenters?: JournalDetailCostCenter[];
}

export interface SaveJournalDetailCostCenter {
    journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveJournalDetail {
    journal_detail_id?: number;
    journal_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    detail_description_en?: string;
    detail_description_ar?: string;
    costcenters?: SaveJournalDetailCostCenter[];
}

export interface JournalData {
    journal_id: number;
    journal_no?: number;
    journal_date?: Date;
    subjournal_id?: number;
    subjournal_name?: string;
    subjournal_type?: string;
    document_id?: number;
    document_no?: number;
    document_type?: JournalDocumentType;
    journal_reference?: string;
    batch_id?: number;
    batch_no?: number;
    total_local_debit: Decimal;
    total_local_credit: Decimal;
    journal_description?: string;
    journal_status?: JournalStatusType;

    details?: JournalDetail[];
}

export enum SearchRangeType {
    LAST_DAY = 0,
    LAST_WEEK = 1,
    LAST_MONTH = 2,
    LAST_3_MONTH = 3,
    CUSTOM = 4
}

export interface SearchJournalsData {
    financial_year_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    unposted?: boolean;
    posted?: boolean;
    cancelled?: boolean;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    from_journal_no?: number;
    to_journal_no?: number;
    from_batch_no?: number;
    to_batch_no?: number;
    subjournal_id?: number;
}

export enum JournalStatusType {
    UNPOSTED = 0,
    POSTED = 1,
    CANCELLED = 2
}
export interface SearchJournalResult {
    financial_year_id?: number;
    journal_id: number;
    journal_no?: number;
    journal_date?: Date;
    subjournal_id?: number;
    subjournal_name?: string;
    document_id?: number;
    document_no?: number;
    document_type?: JournalDocumentType;
    journal_reference?: string;
    batch_id?: number;
    batch_no?: number;
    total_local_debit: Decimal;
    total_local_credit: Decimal;
    journal_description?: string;
    journal_status?: JournalStatusType;

}
export interface SearchJournalsResult {
    journals: SearchJournalResult[];
}


export const searchJournals = async (data: SearchJournalsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'journal_date') {
            return date(value);
        }
        return value;
    };

    return post<SearchJournalsData, SearchJournalsResult>('searchjournals', data, replacer, reviver);
}
export interface FindJournalData {
    journal_id: number;
    lang?: string;
}

export enum JournalDocumentType {
    NONE = 0,
    RECEIVE_CASH = 1,
    PAYOUT_CASH = 2,
    RECEIVE_CHEQUES = 3,
    PAYOUT_CHEQUES = 4,
    ISSUE_CHEQUES = 5,
    CUSTODY_EXPENSE = 6,
    BANK_DEBIT_NOTE = 7,
    BANK_CREDIT_NOTE = 8,
    SALES_INVOICE = 9,
    SALES_RETURN = 10,
    PURCHASE_INVOICE = 11,
    PURCHASE_RETURN = 12,
    INVENTORY_REPLENISHMENT = 13,
    INVENTORY_ISSUED = 14,
    INVENTORY_ADJUSTMENT = 15,
    INVENTORY_COST_ADJUSTMENT = 16,
    CURRENCY_REVALUATION = 17,
    DEFERRED_EXPENSE = 18,
    DEFERRED_REVENUE = 19,
    CUSTOMER_CREDIT_NOTE = 20,
    CUSTOMER_DEBIT_NOTE = 21,
    VENDOR_CREDIT_NOTE = 22,
    VENDOR_DEBIT_NOTE = 23,
    PAYOUT_COMPANY_CHEQUES = 24,
    BANK_RECEIVE_CHEQUES = 25,
    COLLECTED_CHEQUES = 26,
}
export interface FindJournalResult {
    journal_id: number;
    journal_no?: number;
    journal_date?: Date;
    subjournal_id?: number;
    subjournal_name?: string;
    subjournal_type?: SubJournalType;
    document_id?: number;
    document_no?: number;
    document_type?: JournalDocumentType;
    journal_reference?: string;
    batch_id?: number;
    batch_no?: number;
    use_currencies?: number;
    total_local_debit: Decimal;
    total_local_credit: Decimal;
    journal_description?: string;
    journal_status?: JournalStatusType;
    disable_edit?: number;
    notes?: NoteData[];

    details?: JournalDetail[];
}

export const findJournal = async (data: FindJournalData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'total_local_debit' || key === 'total_local_credit' ||
            key === 'debit' || key === 'credit' || key === 'local_debit' || key === 'local_credit' ||
            key === 'exchange_rate') {
            return decimal(value);
        }

        return value;
    };

    return post<FindJournalData, FindJournalResult>('findjournal', data, undefined, reviver);
}

export interface SaveJournalData {
    journal_id?: number;
    journal_no?: number;
    journal_date?: Date;
    financial_year_id?: number;
    subjournal_id?: number;
    use_currencies?: number;
    document_id?: number;
    document_no?: number;
    document_type?: JournalDocumentType;
    journal_reference?: string;
    batch_id?: number;
    lang?: string;
    journal_description?: string;
    journal_description_en?: string;
    journal_description_ar?: string;
    disable_edit?: number;
    details: SaveJournalDetail[];
    deferred_expense_detail_id?: number;
    deferred_revenue_detail_id?: number;
}

export interface SaveJournalResult {
    journal_id: number;
    journal_no: number;
    details?: JournalDetail[];
}

export const saveJournal = async (data: SaveJournalData) => {
    const replacer = (key: string, value: any): any => {

        if (key === 'journal_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {

        if (key === 'local_debit' || key === 'local_credit' || key === 'debit' || key === 'credit' ||
            key === 'exchange_rate' || key === 'distribute_pct' || key === 'amount' || key === 'local_amount') {
            return decimal(value);
        }

        return value;
    };
    return post<SaveJournalData, SaveJournalResult>('savejournal', data, replacer, reviver);
}

export interface PostJournalData {
    journal_id?: number;
    financial_year_id?: number;
    from_journal_no?: number;
    to_journal_no?: number;
    from_journal_date?: Date;
    to_journal_date?: Date
    subjournal_id?: number;
    from_batch_no?: number;
    to_batch_no?: number;
    lang?: string;
}

export interface PostJournalResult {
    journal_status: JournalStatusType
}

export const postJournal = async (data: PostJournalData) => {
    const replacer = (key: string, value: any): any => {

        if ((key === 'from_journal_date' || key === 'to_journal_date') && value) {
            return isoDate(value);
        }
        return value;
    }

    return post<PostJournalData, PostJournalResult>('postjournal', data, replacer);
}

export const unpostJournal = async (data: PostJournalData) => {
    const replacer = (key: string, value: any): any => {

        if ((key === 'from_journal_date' || key === 'to_journal_date') && value) {
            return isoDate(value);
        }
        return value;
    }

    return post<PostJournalData, PostJournalResult>('unpostjournal', data, replacer);
}

export interface SaveJournalNote {
    journal_id: number;
    note_id?: number;
    lang: string;
    note?: string;
}


export const saveJournalNote = async (data: SaveJournalNote) => {
    return post<SaveJournalNote, SaveNoteResult>('savejournalnote', data);
};
export interface RemoveJournalData {
    journal_id: number;

}

export interface RemoveJournalResult {
    journal_status: JournalStatusType;
}

export const removeJournal = async (data: RemoveJournalData) => post<RemoveJournalData, RemoveJournalResult>('removejournal', data);

export const removeJournalNote = async (data: RemoveNote) => post<RemoveNote>('removejournalnote', data);

export interface SearchAnalysisCodesData {
    search?: string;
    active: boolean;
    lang: string;
    exclude?: string[];

}

export interface AnalysisCodeData {
    analysis_code: string;
    analysis_code_name?: string;
    analysis_code_description?: string;
}
export interface SearchAnalysisCodesResult {
    analysis_codes: AnalysisCodeData[];
}

export const searchAnalysisCodes = async (data: SearchAnalysisCodesData) => post<SearchAnalysisCodesData, SearchAnalysisCodesResult>('searchanalysiscodes', data);
export interface FindAnalysisCodeData {
    analysis_code: string;
    lang: string;
}
export interface FindAnalysisCodeResult {
    analysis_code: string;
    analysis_code_name?: string;
    analysis_code_description?: string;
    disabled: number;
}

export const findAnalysisCode = async (data: FindAnalysisCodeData) => post<FindAnalysisCodeData, FindAnalysisCodeResult>('findanalysiscode', data);

export interface SaveAnalysisCodeData {
    op?: string;
    analysis_code?: string;
    lang: string;
    analysis_code_name?: string;
    analysis_code_description?: string;
    disabled: number;
}

export interface SaveAnalysisCodeResult {
    analysis_code: string;
}

export const saveAnalysisCode = async (data: SaveAnalysisCodeData) => post<SaveAnalysisCodeData, SaveAnalysisCodeResult>('saveanalysiscode', data);
export interface RemoveAnalysisCodeData {
    analysis_code: string;
}

export const removeAnalysisCode = async (data: RemoveAnalysisCodeData) => post<RemoveAnalysisCodeData>('removeanalysiscode', data);

export interface Bank_Account_BalanceData {
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;


}

export interface SearchBank_Account_BalancesData {
    bank_account_id?: number;
    currency_code?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    lang?: string;
}

export interface SearchBank_Account_BalancesResult {
    bank_account_balances?: Bank_Account_BalanceData[];
}

export const searchBank_Account_Balances = async (data: SearchBank_Account_BalancesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };

    return post<SearchBank_Account_BalancesData, SearchBank_Account_BalancesResult>('searchbank_account_balances', data, replacer, reviver);
}
export interface FindBank_Account_BalanceData {
    bank_account_id?: number;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    details?: Bank_Account_ChequesData[];
    trx_details?: Bank_Account_BalanceData[];
    trx_cheques?: ChequesData[];
    lang?: string;
    active: boolean;
}
export interface FindBank_Account_BalanceResult {
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    details?: Bank_Account_ChequesData[];
    trx_details?: Bank_Account_BalanceData[];
    trx_cheques?: ChequesData[];
}
export interface Bank_Account_ChequesData {
    bank_account_id?: number;
    bank_account_name?: string;
    currency_code?: string;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;


}

export const findBank_Account_Balance = async (data: FindBank_Account_BalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };
    return post<FindBank_Account_BalanceData, FindBank_Account_BalanceResult>('findbank_account_balance', data, replacer, reviver);
}


export interface CashBox_BalanceData {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_id?: number;
    document_type?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    debit?: Decimal;
    credit?: Decimal;

}

export interface SearchCashBox_BalancesData {
    cashbox_id?: number;
    currency_code?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    lang?: string;
}

export interface SearchCashBox_BalancesResult {
    cashbox_balances?: CashBox_BalanceData[];
}

export const searchCashBox_Balances = async (data: SearchCashBox_BalancesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit' || key === 'total_debit' || key === 'total_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };

    return post<SearchCashBox_BalancesData, SearchCashBox_BalancesResult>('searchcashbox_balances', data, replacer, reviver);
}
export interface FindCashBox_BalanceData {
    cashbox_id?: number;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    details?: CashBox_ChequesData[];
    trx_details?: CashBox_BalanceData[];
    trx_cheques?: ChequesData[];
    lang?: string;
    active: boolean;
}
export interface FindCashBox_BalanceResult {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    debit?: Decimal;
    credit?: Decimal;
    details?: CashBox_ChequesData[];
    trx_details?: CashBox_BalanceData[];
    trx_cheques?: ChequesData[];
}
export interface CashBox_ChequesData {
    cashbox_id?: number;
    cashbox_name?: string;
    currency_code?: string;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;


}

export const findCashBox_Balance = async (data: FindCashBox_BalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };
    return post<FindCashBox_BalanceData, FindCashBox_BalanceResult>('findcashbox_balance', data, replacer, reviver);
}
export interface Custody_Employee_BalanceData {
    custody_employee_id?: number;
    custody_employee_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;


}

export interface SearchCustody_Employee_BalancesData {
    custody_employee_id?: number;
    currency_code?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    lang?: string;
}

export interface SearchCustody_Employee_BalancesResult {
    custody_employee_balances?: Custody_Employee_BalanceData[];
}

export const searchCustody_Employee_Balances = async (data: SearchCustody_Employee_BalancesData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'amount' || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };

    return post<SearchCustody_Employee_BalancesData, SearchCustody_Employee_BalancesResult>('searchcustody_employee_balances', data, replacer, reviver);
}
export interface FindCustody_Employee_BalanceData {
    custody_employee_id?: number;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cash_balance?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    cheque_balance?: Decimal;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    details?: Custody_Employee_ChequesData[];
    trx_details?: Custody_Employee_BalanceData[];
    trx_cheques?: ChequesData[];
    lang?: string;
    active: boolean;
}
export interface FindCustody_Employee_BalanceResult {
    custody_employee_id?: number;
    custody_employee_name?: string;
    currency_code?: string;
    cash_debit?: Decimal;
    cash_credit?: Decimal;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    details?: Custody_Employee_ChequesData[];
    trx_details?: Custody_Employee_BalanceData[];
    trx_cheques?: ChequesData[];
}
export interface Custody_Employee_ChequesData {
    employee_id?: number;
    employee_name?: string;
    currency_code?: string;
    cheque_number?: string;
    cheque_date?: Date;
    cheque_debit?: Decimal;
    cheque_credit?: Decimal;
    receive_cheque_no?: number;
    payout_cheque_no?: number;
    trx_id?: number;
    trx_no?: number;
    trx_date?: Date;
    trx_type?: number;
    document_type?: number;


}

export const findCustody_Employee_Balance = async (data: FindCustody_Employee_BalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'cash_debit' || key === 'cash_credit' || key === 'cheque_debit' || key === 'cheque_credit'
            || key === 'cash_balance' || key === 'cheque_balance' && value) {
            return decimal(value);
        }
        else if (key === 'trx_date') {
            return date(value);
        }

        return value;

    };
    return post<FindCustody_Employee_BalanceData, FindCustody_Employee_BalanceResult>('findcustody_employee_balance', data, replacer, reviver);
}

export interface AccountBalanceData {
    financial_year_id: number;
    account_code: string;
    parent_account_code?: string;
    account_name?: string;
    currency_code?: string;
    open?: Decimal;
    open_local?: Decimal;
    pending_open?: Decimal;
    pending_open_local?: Decimal;
    posted_debit?: Decimal;
    posted_credit?: Decimal;
    posted_local_debit?: Decimal;
    posted_local_credit?: Decimal;

    pending_debit?: Decimal;
    pending_credit?: Decimal;
    pending_local_debit?: Decimal;
    pending_local_credit?: Decimal;
    total_pending_open?: Decimal;
    total_pending_open_local?: Decimal;
    total?: Decimal;
    total_local?: Decimal;
    total_pending?: Decimal;
    total_pending_local?: Decimal;


    children: AccountBalanceData[];

    journal_details?: AccountBalanceJournalDetail[];
}
export interface AccountBalanceJournalDetail {
    journal_id?: number;
    journal_no?: number;
    journal_date?: Date;
    journal_type?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    posted?: number;
    debit?: Decimal;
    local_debit?: Decimal;
    credit?: Decimal;
    local_credit?: Decimal;
    detail_description?: string;
    subjournal_name?: string;
    journal_status?: number;
}

export interface SearchAccountBalancesData {
    financial_year_id: number;
    currency_code?: string;

    search?: string;
    postedOnly: boolean;
    lang?: string;


}

export interface SearchAccountBalancesResult {
    account_balances?: AccountBalanceData[];
}

export const searchAccountBalances = async (data: SearchAccountBalancesData) => post<SearchAccountBalancesData, SearchAccountBalancesResult>('searchaccount_balances', data);
export interface FindAccountBalanceData {
    financial_year_id: number;
    account_code?: string;
    currency_code?: string;

    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today?: Date;
    unposted?: boolean;
    posted?: boolean;
    cancelled?: boolean;

    lang?: string;
}
export interface AccountPeriodBalanceData {
    period_no: number;
    period_name?: string;
    open?: Decimal;
    open_local?: Decimal;
    pending_open?: Decimal;
    pending_open_local?: Decimal;
    posted_debit?: Decimal;
    posted_credit?: Decimal;
    posted_local_debit: Decimal;
    posted_local_credit: Decimal;
    pending_debit?: Decimal;
    pending_credit?: Decimal;
    pending_local_debit: Decimal;
    pending_local_credit: Decimal;
    total_balance: Decimal;
    total_local_balance: Decimal;
    total_pending_balance: Decimal;
    total_pending_local_balance: Decimal;
}
export interface FindAccountBalanceResult {
    financial_year_id: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    open?: Decimal;
    open_local?: Decimal;
    pending_open?: Decimal;
    pending_open_local?: Decimal;
    pending_debit?: Decimal;
    pending_credit?: Decimal;
    pending_local_debit?: Decimal;
    pending_local_credit?: Decimal;
    posted_debit?: Decimal;
    posted_credit?: Decimal;
    posted_local_debit?: Decimal;
    posted_local_credit?: Decimal;
    total_pending_open?: Decimal;
    total_pending_open_local?: Decimal;
    total?: Decimal;
    total_local?: Decimal;
    total_pending?: Decimal;
    total_pending_local?: Decimal;

    period_balances?: AccountPeriodBalanceData[];
    journal_details?: AccountBalanceJournalDetail[];
}

export const findAccountBalance = async (data: FindAccountBalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'open_debit' || key === 'open_credit' ||
            key === 'open_local_debit' || key === 'open_local_credit' ||
            key === 'pending_debit' || key === 'pending_credit' ||
            key === 'pending_local_debit' || key === 'pending_local_credit' ||
            key === 'posted_debit' || key === 'posted_credit' ||
            key === 'posted_local_debit' || key === 'posted_local_credit' ||
            key === 'total_debit' || key === 'total_credit' ||
            key === 'total_local_debit' || key === 'total_local_credit' ||
            key === 'total_pending_debit' || key === 'total_pending_credit' ||
            key === 'total_pending_local_debit' || key === 'total_pending_local_credit' ||
            key === 'balance' || key === 'local_balance' ||
            key === 'pending_balance' || key === 'pending_local_balance' ||
            key === 'open_balance' || key === 'open_local_balance' ||
            key === 'open_pending_balance' || key === 'open_pending_local_balance'

        ) {
            return decimal(value);
        }
        return value;
    };
    return post<FindAccountBalanceData, FindAccountBalanceResult>('findaccount_balance', data, replacer, reviver)
};

export interface CostCenterBalanceData {
    financial_year_id: number;
    costcenter_code: string;
    costcenter_name?: string;
    parent_costcenter_code?: string;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    pending_debit?: Decimal;
    pending_credit?: Decimal;
    pending_local_debit?: Decimal;
    pending_local_credit?: Decimal;
    posted_debit?: Decimal;
    posted_credit?: Decimal;
    posted_local_debit?: Decimal;
    posted_local_credit?: Decimal;
    total_debit?: Decimal;
    total_credit?: Decimal;
    total_local_debit?: Decimal;
    total_local_credit?: Decimal;
    total_pending_debit?: Decimal;
    total_pending_credit?: Decimal;
    total_pending_local_debit?: Decimal;
    total_pending_local_credit?: Decimal;

    children: CostCenterBalanceData[]



}

export interface SearchCostCenterBalancesData {
    financial_year_id: number;
    currency_code?: string;
    search?: string;
    lang?: string;
}

export interface SearchCostCenterBalancesResult {
    costcenter_balances?: CostCenterBalanceData[];
}

export const searchCostCenterBalances = async (data: SearchCostCenterBalancesData) => post<SearchCostCenterBalancesData, SearchCostCenterBalancesResult>('searchcostcenter_balances', data);
export interface FindCostCenterBalanceData {
    financial_year_id: number;
    costcenter_code?: string;
    account_code?: string;
    currency_code?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today?: Date;
    lang?: string;
}
export interface CostCenterPeriodBalanceData {
    period_no: number;
    period_name?: string;
    open_balance?: Decimal;
    open_local_balance: Decimal;
    open_pending_balance?: Decimal;
    open_pending_local_balance: Decimal;
    posted_debit?: Decimal;
    posted_credit?: Decimal;
    posted_local_debit: Decimal;
    posted_local_credit: Decimal;
    pending_debit?: Decimal;
    pending_credit?: Decimal;
    pending_local_debit: Decimal;
    pending_local_credit: Decimal;
    total_balance: Decimal;
    total_local_balance: Decimal;
    total_pending_balance: Decimal;
    total_pending_local_balance: Decimal;
}
export interface FindCostCenterBalanceResult {
    financial_year_id: number;
    costcenter_code?: string;
    costcenter_name?: string;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    pending_debit?: Decimal;
    pending_credit?: Decimal;
    pending_local_debit?: Decimal;
    pending_local_credit?: Decimal;
    posted_debit?: Decimal;
    posted_credit?: Decimal;
    posted_local_debit?: Decimal;
    posted_local_credit?: Decimal;
    total_debit?: Decimal;
    total_credit?: Decimal;
    total_local_debit?: Decimal;
    total_local_credit?: Decimal;
    total_pending_debit?: Decimal;
    total_pending_credit?: Decimal;
    total_pending_local_debit?: Decimal;
    total_pending_local_credit?: Decimal;

    period_balances?: CostCenterPeriodBalanceData[]
    journal_details?: CostCenterJournalDetail[];
}

export interface CostCenterJournalDetail {
    journal_no?: number;
    journal_date?: Date;
    journal_type?: number;
    account_code?: string;
    account_name?: string;
    costcenter_code?: string;
    costcenter_name?: string;
    debit?: Decimal;
    local_debit?: Decimal;
    credit?: Decimal;
    local_credit?: Decimal;
    balance?: Decimal;
    local_balance?: Decimal;
    detail_description?: string;
    subjournal_name?: string;
    journal_status?: number;
}


export const findCostCenterBalance = async (data: FindCostCenterBalanceData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'pending_debit' || key === 'pending_credit' ||
            key === 'pending_local_debit' || key === 'pending_local_credit' ||
            key === 'posted_debit' || key === 'posted_credit' ||
            key === 'posted_local_debit' || key === 'posted_local_credit' ||
            key === 'total_debit' || key === 'total_credit' ||
            key === 'total_local_debit' || key === 'total_local_credit' ||
            key === 'total_pending_debit' || key === 'total_pending_credit' ||
            key === 'total_pending_local_debit' || key === 'total_pending_local_credit' ||
            key === 'balance' || key === 'local_balance' ||
            key === 'pending_balance' || key === 'pending_local_balance' ||
            key === 'open_balance' || key === 'open_local_balance' ||
            key === 'open_pending_balance' || key === 'open_pending_local_balance'

        ) {
            return decimal(value);
        }
        return value;
    };

    return post<FindCostCenterBalanceData, FindCostCenterBalanceResult>('findcostcenter_balance', data, replacer, reviver);
}



export interface MapData {
    host?: string;
    port?: string;
    user?: string;
    password?: string;
    database?: string;
    lang?: string;
}
export interface MapCurrency {
    currencyID: number;
    currencyCode: string;
    currencyName?: string;
    currency_code?: string;
}
export interface MapDataResult {
    currencies?: MapCurrency[];
}
export const mapData = async (data: MapData) => post<MapData, MapDataResult>('mapdata', data);
export interface ImportDataResult {
    accounts: number;
    costcenters: number;
}
export interface ImportData extends MapData {
    financial_year_id?: number;
    inventory_agent_id?: number;
    cashier_id?: number;
    employee_id?: number;
    currencies?: MapCurrency[];
    branches?: boolean;
    employees?: boolean;
    accounts?: boolean;
    subjournals?: boolean;
    journals?: boolean;
    costcenters?: boolean;
    warehouse_types?: boolean;
    warehouses?: boolean;
    products?: boolean;
    categories?: boolean;
    inventory?: boolean;
    customers?: boolean;
    sales_quotations?: boolean;
    sales_orders?: boolean;
    sales_invoices?: boolean;
    sales_returns?: boolean;
    customer_debit_notes?: boolean;
    customer_credit_notes?: boolean;
    vendors?: boolean;
    purchase_orders?: boolean;
    purchase_invoices?: boolean;
    purchase_returns?: boolean;
    vendor_debit_notes?: boolean;
    vendor_credit_notes?: boolean;
    cashbox?: boolean;
    bank_accounts?: boolean;
    receive_cash?: boolean;
    payout_cash?: boolean;
    receive_cheque?: boolean;
    payout_cheque?: boolean;
    payout_company_cheque?: boolean;
    debit_note?: boolean;
    credit_note?: boolean;
    bank_receive?: boolean;

}
export interface ImportDataResult {
    accounts: number;
    costcenters: number;
}


export const importData = async (data: ImportData) => post<ImportData, ImportDataResult>('importdata', data)


export interface SearchFinancialYearsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface FinancialYearData {
    financial_year_id: number;
    financial_year_name?: string;
    financial_year_description?: string;
    starting_date?: Date;
    ending_date?: Date;
    periods?: FinancialYearPeriodData[];
    closed: number;
    disabled: number;
}
export interface SearchFinancialYearsResult {
    financial_years: FinancialYearData[];
}

export const searchFinancialYears = async (data: SearchFinancialYearsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'starting_date' && value) {
            return date(value);
        }
        return value;
    };
    return post<SearchFinancialYearsData, SearchFinancialYearsResult>('searchfinancialyears', data, undefined, reviver);
}
export interface FindFinancialYearData {
    financial_year_id: number;
    lang: string;
}

export interface FinancialYearPeriodData {
    period_no: number;
    period_name?: string;
    start_date: Date;
    end_date: Date;
    closed: number;
}

export interface FindFinancialYearResult extends FinancialYearData {
    financial_year_id: number;
    financial_year_name?: string;
    financial_year_description?: string;
    starting_date?: Date;
    periods?: FinancialYearPeriodData[];

    closed: number;
    disabled: number;
}

export const findFinancialYear = async (data: FindFinancialYearData) => {

    const reviver = (key: string, value: any): any => {
        if ((key === 'starting_date' || key === 'start_date' || key === 'end_date') && value) {
            return date(value);
        }

        return value;
    };


    return post<FindFinancialYearData, FindFinancialYearResult>('findfinancialyear', data, undefined, reviver);
}

export interface SaveFinancialYearPeriodData {
    period_no: number;
    period_name?: string;
    closed: number;
}
export interface SaveFinancialYearData {
    financial_year_id?: number;
    lang: string;
    financial_year_name?: string;
    financial_year_description?: string;
    starting_date?: Date;
    periods?: SaveFinancialYearPeriodData[];
    closed: number;
    disabled: number;
}

export interface SaveFinancialYearResult {
    financial_year_id: number;
    periods?: FinancialYearPeriodData[];
}

export const saveFinancialYear = async (data: SaveFinancialYearData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'starting_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SaveFinancialYearData, SaveFinancialYearResult>('savefinancialyear', data, replacer);
};

export interface RemoveFinancialYearData {
    financial_year_id: number;
}

export const removeFinancialYear = async (data: RemoveFinancialYearData) => post<RemoveFinancialYearData>('removefinancialyear', data);

export interface SearchCurrenciesRevaluationData {
    search?: string;
    unposted?: boolean;
    posted?: boolean;
    cancelled?: boolean;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    lang: string;
}

export interface CurrencyRevaluationData {
    revaluation_id: number;
    revaluation_no: number;
    financial_year_id: number;
    currency_code?: string;
    revaluation_date?: Date;
    exchange_rate?: Decimal;
    revaluation_account?: string;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    revaluation_description?: string;
}
export interface SearchCurrenciesRevaluationResult {
    currencies_revaluation?: CurrencyRevaluationData[];
}

export const searchCurrenciesRevaluation = async (data: SearchCurrenciesRevaluationData) => {
    const replacer = (key: string, value: any): any => {
        if ((key === 'today' || key === 'from_date' || key === 'to_date') && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'exchange_rate' && value) {
            return decimal(value);
        }
        else if (key === 'revaluation_date') {
            return date(value);
        }
        return value;
    };
    return post<SearchCurrenciesRevaluationData, SearchCurrenciesRevaluationResult>('searchcurrencyrevaluation', data, replacer, reviver);
}
export interface FindCurrencyRevaluationData {
    revaluation_id: number;
    lang: string;
}
export interface FindCurrencyRevaluationResult {
    revaluation_id: number;
    revaluation_no: number;
    financial_year_id: number;
    currency_code?: string;
    revaluation_date?: Date;
    exchange_rate?: Decimal;
    revaluation_account?: string;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    revaluation_description?: string;
    journal_details?: JournalDetail[];
    total_local_debit?: Decimal;
    total_local_credit?: Decimal;
}

export const findCurrencyRevaluation = async (data: FindCurrencyRevaluationData) => {

    const reviver = (key: string, value: any): any => {
        if (key === 'exchange_rate' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'total_local_debit' || key === 'total_local_credit' && value) {
            return decimal(value);
        }
        else if (key === 'revaluation_date') {
            return date(value);
        }
        return value;
    };

    return post<FindCurrencyRevaluationData, FindCurrencyRevaluationResult>('findcurrencyrevaluation', data, undefined, reviver);
}
export interface SaveCurrencyRevaluationData {
    financial_year_id?: number;
    revaluation_id?: number;
    revaluation_no?: number;
    revaluation_description?: string;
    currency_code?: string;
    local_currency_code?: string;
    revaluation_date?: Date;
    exchange_rate?: Decimal;
    subjournal_id?: number;
    batch_id?: number;
    revaluation_account?: string;
    lang?: string;
}

export interface SaveCurrencyRevaluationResult {
    revaluation_id: number;
    revaluation_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_details?: JournalDetail[];
    journal_status?: JournalStatusType;
    total_local_debit?: Decimal;
    total_local_credit?: Decimal;
}

export const saveCurrencyRevaluation = async (data: SaveCurrencyRevaluationData) => {
    const replacer = (key: string, value: any): any => {
        if ((key === 'exchange_rate' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_debit' || key === 'total_local_debit' || key === 'total_local_credit') && value) {
            return value.toString();
        }
        else if (key === 'revaluation_date') {
            return isoDate(value);
        }
        return value;
    };

    return post<SaveCurrencyRevaluationData, SaveCurrencyRevaluationResult>('savecurrencyrevaluation', data, replacer);
};

export interface RunCurrencyRevaluationResult {
    journal_details?: JournalDetail[];
    total_local_debit?: Decimal;
    total_local_credit?: Decimal;
}

export const runCurrencyRevaluation = async (data: SaveCurrencyRevaluationData) => {
    const replacer = (key: string, value: any): any => {
        if ((key === 'exchange_rate' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_debit' || key === 'total_local_debit' || key === 'total_local_credit') && value) {
            return value.toString();
        }
        else if (key === 'revaluation_date') {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if ((key === 'exchange_rate' || key === 'debit' || key === 'credit' || key === 'local_debit' ||
            key === 'local_credit' || key === 'total_local_debit' || key === 'total_local_credit') && value) {
            return decimal(value);
        }

        return value;
    }

    return post<SaveCurrencyRevaluationData, RunCurrencyRevaluationResult>('runcurrencyrevaluation', data, replacer, reviver);
};

export interface RemoveCurrencyRevaluationData {
    revaluation_id: number;
}

export interface RemoveCurrencyRevaluationResult {
    journal_status: JournalStatusType;
}
export const removeCurrencyRevaluation = async (data: RemoveCurrencyRevaluationData) => post<RemoveCurrencyRevaluationData, RemoveCurrencyRevaluationResult>('removecurrencyrevaluation', data);

export enum BalanceSheetRowType {
    DETAIL = 0,
    TITLE = 1,
    SUBTOTAL = 2,
    TOTAL = 3
}
export interface BalanceSheetDetail {
    balance_sheet_detail_id?: number;
    row_description?: string;
    row_type: BalanceSheetRowType;
    account_details?: BalanceSheetAccountDetail[];
    previous_account_details?: BalanceSheetAccountDetail[];
}
export interface BalanceSheetAccountDetail {
    balance_sheet_account_detail_id?: number;
    account_code?: string;
    account_name?: string;
}

export interface SaveBalanceSheetDetail {
    balance_sheet_detail_id?: number;
    row_description?: string;
    row_type: BalanceSheetRowType;
    account_details?: SaveBalanceSheetAccountDetail[];
    previous_account_details?: SaveBalanceSheetAccountDetail[];
}

export interface SaveBalanceSheetAccountDetail {
    balance_sheet_account_detail_id?: number;
    account_code?: string;
}

export interface BalanceSheetData {
    balance_sheet_id: number;
    financial_year_id?: number;
    financial_year_name?: string;
    balance_sheet_name?: string;
    balance_sheet_description?: string;

    details?: BalanceSheetDetail[];
    disabled: number;
    media?: Media[];
}

export interface SearchBalanceSheetsData {
    balance_sheet_id?: number;
    lang: string;
    search?: string;
    active: boolean;
}

export interface SearchBalanceSheetResult {
    balance_sheet_id: number;
    financial_year_id?: number;
    financial_year_name?: string;
    balance_sheet_name?: string;
    balance_sheet_description?: string;
    disabled?: number;

}
export interface SearchBalanceSheetsResult {
    balance_sheets: SearchBalanceSheetResult[];
}


export const searchBalanceSheets = async (data: SearchBalanceSheetsData) => post<SearchBalanceSheetsData, SearchBalanceSheetsResult>('searchbalancesheets', data);
export interface FindBalanceSheetData {
    balance_sheet_id: number;
    lang?: string;
}
export interface FindBalanceSheetResult {
    balance_sheet_id: number;
    financial_year_id?: number;
    financial_year_name?: string;
    balance_sheet_name?: string
    balance_sheet_description?: string;
    disabled: number;
    notes?: NoteData[];

    details?: BalanceSheetDetail[];
    media?: Media[];
}

export const findBalanceSheet = async (data: FindBalanceSheetData) => post<FindBalanceSheetData, FindBalanceSheetResult>('findbalancesheet', data);

export interface SaveBalanceSheetData {
    balance_sheet_id?: number;
    financial_year_id?: number;
    balance_sheet_name?: string;
    balance_sheet_description?: string;
    lang?: string;
    disabled: number;

    details?: SaveBalanceSheetDetail[];
    media?: Media[];
    files?: File[];
}

export interface Media {
    media_id: number;
    file_name: string;
    mime_type: string;
    file_size: number;
}

export interface SaveBalanceSheetResult {
    balance_sheet_id: number;
    details?: SaveBalanceSheetDetail[];
    media?: Media[];
}

export const saveBalanceSheet = async (data: SaveBalanceSheetData) => postMP<SaveBalanceSheetData, SaveBalanceSheetResult>('savebalancesheet', data);
export interface SaveBalanceSheetNote {
    balance_sheet_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveBalanceSheetNote = async (data: SaveBalanceSheetNote) => {
    return post<SaveBalanceSheetNote, SaveNoteResult>('savebalancesheetnote', data);
};

export interface RemoveBalanceSheetData {
    balance_sheet_id: number;

}

export const removeBalanceSheet = async (data: RemoveBalanceSheetData) => post<RemoveBalanceSheetData>('removebalancesheet', data);
export const removeBalanceSheetNote = async (data: RemoveNote) => post<RemoveNote>('removebalancesheetnote', data);

export enum IncomeStatementRowType {
    DETAIL = 0,
    TITLE = 1,
    SUBTOTAL = 2,
    TOTAL = 3
}
export interface IncomeStatementDetail {
    income_statement_detail_id?: number;
    row_description?: string;
    row_type: IncomeStatementRowType;
    account_details?: IncomeStatementAccountDetail[];
    previous_account_details?: IncomeStatementAccountDetail[];
}
export interface IncomeStatementAccountDetail {
    income_statement_account_detail_id?: number;
    account_code?: string;
    account_name?: string;
}

export interface SaveIncomeStatementDetail {
    income_statement_detail_id?: number;
    row_description?: string;
    row_type: IncomeStatementRowType;
    account_details?: IncomeStatementAccountDetail[];
    previous_account_details?: IncomeStatementAccountDetail[];
}

export interface SaveIncomeStatement_Account_Detail {
    income_statement_account_detail_id?: number;
    account_code?: string;
}

export interface IncomeStatementData {
    income_statement_id: number;
    financial_year_id?: number;
    financial_year_name?: string;
    income_statement_name?: string;
    income_statement_description?: string;

    details?: IncomeStatementDetail[];
    account_details?: IncomeStatementAccountDetail[];
    disabled: number;
    media?: Media[];
}

export interface SearchIncomeStatementsData {
    income_statement_id?: number;
    lang: string;
    search?: string;
    active: boolean;
}

export interface SearchIncomeStatementResult {
    income_statement_id: number;
    financial_year_id?: number;
    financial_year_name?: string;
    income_statement_name?: string;
    income_statement_description?: string;
    disabled?: number;

}
export interface SearchIncomeStatementsResult {
    income_statements: SearchIncomeStatementResult[];
}


export const searchIncomeStatements = async (data: SearchIncomeStatementsData) => post<SearchIncomeStatementsData, SearchIncomeStatementsResult>('searchincome_statements', data);
export interface FindIncomeStatementData {
    income_statement_id: number;
    lang?: string;
}
export interface FindIncomeStatementResult {
    income_statement_id: number;
    financial_year_id?: number;
    financial_year_name?: string;
    income_statement_name?: string
    income_statement_description?: string;
    disabled: number;
    notes?: NoteData[];

    details?: IncomeStatementDetail[];
    media?: Media[];
}

export const findIncomeStatement = async (data: FindIncomeStatementData) => post<FindIncomeStatementData, FindIncomeStatementResult>('findincome_statement', data);

export interface SaveIncomeStatementData {
    income_statement_id?: number;
    financial_year_id?: number;
    income_statement_name?: string;
    income_statement_description?: string;
    lang?: string;
    disabled: number;

    details?: SaveIncomeStatementDetail[];
    media?: Media[];
    files?: File[];
}

export interface Media {
    media_id: number;
    file_name: string;
    mime_type: string;
    file_size: number;
}

export interface SaveIncomeStatementResult {
    income_statement_id: number;


    details?: SaveIncomeStatementDetail[];
    media?: Media[];
}

export const saveIncomeStatement = async (data: SaveIncomeStatementData) => postMP<SaveIncomeStatementData, SaveIncomeStatementResult>('saveincome_statement', data);

export interface RemoveIncomeStatementData {
    income_statement_id: number;

}

export interface SaveIncomeStatementNote {
    income_statement_id?: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveIncomeStatementNote = async (data: SaveIncomeStatementNote) => {
    return post<SaveIncomeStatementNote, SaveNoteResult>('saveincome_statementnote', data);
};

export const removeIncomeStatement = async (data: RemoveIncomeStatementData) => post<RemoveIncomeStatementData>('removeincome_statement', data);
export const removeIncomeStatementNote = async (data: RemoveNote) => post<RemoveNote>('removeincome_statementnote', data);

export interface SearchBatchesData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface BatchData {
    batch_id: number;
    batch_no?: number;
    start_date?: Date;
    end_date?: Date;
    batch_name?: string;
    batch_description?: string;
}
export interface SearchBatchesResult {
    batches: BatchData[];
}

export const searchBatches = async (data: SearchBatchesData) => post<SearchBatchesData, SearchBatchesResult>('searchbatches', data);
export interface FindBatchData {
    batch_id: number;
    lang: string;
}
export interface FindBatchResult {
    batch_id: number;
    batch_no?: number;
    start_date?: Date;
    end_date?: Date;
    batch_name?: string;
    batch_description?: string;
    disabled: number;
}

export const findBatch = async (data: FindBatchData) => post<FindBatchData, FindBatchResult>('findbatch', data);

export interface SaveBatchData {
    batch_id?: number;
    batch_no?: number;
    start_date?: Date;
    end_date?: Date;
    lang: string;
    batch_name?: string;
    batch_description?: string;
    disabled: number;
}

export interface SaveBatchResult {
    batch_id: number;
    batch_no: number;
}

export const saveBatch = async (data: SaveBatchData) => post<SaveBatchData, SaveBatchResult>('savebatch', data);
export interface RemoveBatchData {
    batch_id: number;
}

export const removeBatch = async (data: RemoveBatchData) => post<RemoveBatchData>('removebatch', data);


export enum CustomerCreditType {
    Invoice = 1,
    Other = 2,
}

export interface CustomerCreditNoteJournalData {
    customer_credit_note_id?: number;
    customer_id?: number;
    currency_code?: string;
    customer_credit_note_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    customer_credit_note_status?: RecordStatus;
    customer_credit_note_type?: number;
    financial_year_id?: number;
    details?: saveCustomerCreditNoteDetail[];
    journal_details?: SaveCustomerCreditNoteJournalDetail[];
    use_currencies?: number;
}

export interface SaveCustomerCreditNoteJournalDetailCostCenter {
    customer_credit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveCustomerCreditNoteJournalDetail {
    customer_credit_note_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveCustomerCreditNoteJournalDetailCostCenter[];
}

export interface CustomerCreditNoteJournalResult {
    journal_details?: CustomerCreditNoteJournalDetail[];
}

export const customerCreditNoteJournalEntries = async (data: CustomerCreditNoteJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'customer_credit_note_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'due_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<CustomerCreditNoteJournalData, CustomerCreditNoteJournalResult>('customercreditnotejournalentries', data, replacer, reviver);
}
export interface CustomerCreditNoteJournalDetailCostCenter {
    customer_credit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface CustomerCreditNoteJournalDetail {
    customer_credit_note_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: CustomerCreditNoteJournalDetailCostCenter[];

}

export interface CustomerCreditNoteDetail {
    customer_credit_note_inv_detail_id?: number;
    customer_credit_note_id?: number;
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface saveCustomerCreditNoteDetail {
    customer_credit_note_inv_detail_id?: number;
    customer_credit_note_id?: number;
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface CustomerCreditNoteData {
    customer_credit_note_id: number;
    customer_credit_note_no?: number;
    customer_credit_note_date: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_credit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_credit_note_description?: string;
    customer_credit_note_status?: RecordStatus;
    disable_edit?: boolean;
    use_currencies?: number;

    invoice_details?: CustomerCreditNoteDetail[];
    disabled: number;
}

export interface SearchCustomerCreditNotesData {
    customer_credit_note_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchCustomerCreditNoteResult {
    customer_credit_note_id: number;
    customer_credit_note_no?: number;
    customer_credit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_credit_note_type?: number;
    customer_credit_note_status?: RecordStatus;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_credit_note_description?: string;
    disable_edit?: boolean;
    use_currencies?: number;

    invoice_details?: CustomerCreditNoteData[];
    disabled?: number;
}
export interface SearchCustomerCreditNotesResult {
    customer_credit_notes: CustomerCreditNoteData[];
}


export const searchCustomerCreditNotes = async (data: SearchCustomerCreditNotesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_amount' || key === 'amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchCustomerCreditNotesData, SearchCustomerCreditNotesResult>('searchcustomer_credit_note', data, replacer, reviver);

}

export interface FindCustomerCredit_NoteData {
    customer_credit_note_id?: number;
    disable_edit?: boolean;
    invoice_details?: CustomerCreditNoteDetail[];
    journal_details?: CustomerCreditNoteJournalDetail[];
    lang?: string;


}
export interface FindCustomerCreditNoteResult {
    customer_credit_note_id?: number;
    customer_credit_note_no?: number;
    customer_credit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_credit_note_type?: number;
    customer_credit_note_status?: RecordStatus;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_credit_note_description?: string;
    invoice_details?: CustomerCreditNoteDetail[];
    disabled?: number;
    disable_edit?: boolean;
    journal_details?: CustomerCreditNoteJournalDetail[];
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    use_currencies?: number;


}

export const findCustomerCreditNote = async (data: FindCustomerCredit_NoteData) => {


    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };


    return post<FindCustomerCredit_NoteData, FindCustomerCreditNoteResult>('findcustomer_credit_note', data, undefined, reviver);
}
export interface SaveCustomerCreditNoteData {
    customer_credit_note_id?: number;
    customer_credit_note_no?: number;
    customer_credit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_credit_note_type?: number;
    customer_credit_note_status?: RecordStatus;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_credit_note_description?: string;
    customer_credit_note_description_en?: string;
    customer_credit_note_description_ar?: string;
   disable_edit?: boolean;
    invoice_details?: CustomerCreditNoteDetail[];
    journal_details?: CustomerCreditNoteJournalDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    use_currencies?: number;

}

export interface SaveCustomerCreditNoteResult {
    customer_credit_note_id?: number;
    customer_credit_note_no?: number;
    customer_credit_note_status?: RecordStatus;
    disable_edit?: boolean;
    invoice_details?: CustomerCreditNoteDetail[];
    journal_details?: CustomerCreditNoteJournalDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;

}

export const saveCustomerCreditNote = async (data: SaveCustomerCreditNoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'customer_credit_note_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };
    return post<SaveCustomerCreditNoteData, SaveCustomerCreditNoteResult>('savecustomer_credit_note', data, replacer, reviver);
}
export interface RemoveCustomerCreditNoteData {
    customer_credit_note_id: number;

}

export const removeCustomerCreditNote = async (data: RemoveCustomerCreditNoteData) => post<RemoveCustomerCreditNoteData>('removecustomer_credit_note', data);


export enum CustomerDebitType {
    Invoice = 1,
    Other = 2,
}

export interface CustomerDebitNoteJournalData {
    customer_debit_note_id?: number;
    customer_id?: number;
    currency_code?: string;
    customer_debit_note_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    customer_debit_note_status?: RecordStatus;
    customer_debit_note_type?: number;
    financial_year_id?: number;
    details?: saveCustomerDebitNoteDetail[];
    journal_details?: SaveCustomerDebitNoteJournalDetail[];
    use_currencies?: number;
}

export interface SaveCustomerDebitNoteJournalDetailCostCenter {
    customer_debit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveCustomerDebitNoteJournalDetail {
    customer_debit_note_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveCustomerDebitNoteJournalDetailCostCenter[];
}

export interface CustomerDebitNoteJournalResult {
    journal_details?: CustomerDebitNoteJournalDetail[];
}

export const customerDebitNoteJournalEntries = async (data: CustomerDebitNoteJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'customer_debit_note_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'debit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'due_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<CustomerDebitNoteJournalData, CustomerDebitNoteJournalResult>('customerdebitnotejournalentries', data, replacer, reviver);
}
export interface CustomerDebitNoteJournalDetailCostCenter {
    customer_debit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface CustomerDebitNoteJournalDetail {
    customer_debit_note_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: CustomerDebitNoteJournalDetailCostCenter[];

}

export interface CustomerDebitNoteDetail {
    customer_debit_note_inv_detail_id?: number;
    customer_debit_note_id?: number;
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface saveCustomerDebitNoteDetail {
    customer_debit_note_inv_detail_id?: number;
    customer_debit_note_id?: number;
    sales_invoice_id: number;
    sales_invoice_no?: number;
    sales_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface CustomerDebitNoteData {
    customer_debit_note_id: number;
    customer_debit_note_no?: number;
    customer_debit_note_date: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_debit_note_type?: number;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_debit_note_description?: string;
    customer_debit_note_status?: RecordStatus;
    disable_edit?: boolean;
    use_currencies?: number;

    invoice_details?: CustomerDebitNoteDetail[];
    disabled: number;
}

export interface SearchCustomerDebitNotesData {
    customer_debit_note_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchCustomerDebitNoteResult {
    customer_debit_note_id: number;
    customer_debit_note_no?: number;
    customer_debit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_debit_note_type?: number;
    customer_debit_note_status?: RecordStatus;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_debit_note_description?: string;
    disable_edit?: boolean;
    use_currencies?: number;

    invoice_details?: CustomerDebitNoteData[];
    disabled?: number;
}
export interface SearchCustomerDebitNotesResult {
    customer_debit_notes: CustomerDebitNoteData[];
}


export const searchCustomerDebitNotes = async (data: SearchCustomerDebitNotesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_amount' || key === 'amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchCustomerDebitNotesData, SearchCustomerDebitNotesResult>('searchcustomerdebitnote', data, replacer, reviver);

}

export interface FindCustomerDebitNoteData {
    customer_debit_note_id?: number;
    disable_edit?: boolean;
    invoice_details?: CustomerDebitNoteDetail[];
    journal_details?: CustomerDebitNoteJournalDetail[];
    lang?: string;


}
export interface FindCustomerDebitNoteResult {
    customer_debit_note_id?: number;
    customer_debit_note_no?: number;
    customer_debit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_debit_note_type?: number;
    customer_debit_note_status?: RecordStatus;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_debit_note_description?: string;
    disable_edit?: boolean;
    journal_details?: CustomerDebitNoteJournalDetail[];
    invoice_details?: CustomerDebitNoteDetail[];
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    use_currencies?: number;


}

export const findCustomerDebitNote = async (data: FindCustomerDebitNoteData) => {


    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };


    return post<FindCustomerDebitNoteData, FindCustomerDebitNoteResult>('findcustomerdebitnote', data, undefined, reviver);
}
export interface SaveCustomerDebitNoteData {
    customer_debit_note_id?: number;
    customer_debit_note_no?: number;
    customer_debit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    customer_debit_note_type?: number;
    customer_debit_note_status?: RecordStatus;
    customer_id?: number;
    customer_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    customer_debit_note_description?: string;
    disable_edit?: boolean;
    invoice_details?: CustomerDebitNoteDetail[];
    journal_details?: CustomerDebitNoteJournalDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    use_currencies?: number;
    batch_id?: number;
    customer_debit_note_description_en?: string;
    customer_debit_note_description_ar?: string;
}

export interface SaveCustomerDebitNoteResult {
    customer_debit_note_id?: number;
    customer_debit_note_no?: number;
    customer_debit_note_status?: RecordStatus;
    disable_edit?: boolean;
    journal_details?: CustomerDebitNoteJournalDetail[];
    invoice_details?: CustomerDebitNoteDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;

}

export const saveCustomerDebitNote = async (data: SaveCustomerDebitNoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'customer_debit_note_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SaveCustomerDebitNoteData, SaveCustomerDebitNoteResult>('savecustomerdebitnote', data, replacer, reviver);
}
export interface RemoveCustomerDebitNoteData {
    customer_debit_note_id: number;

}

export const removeCustomer_Debit_Note = async (data: RemoveCustomerDebitNoteData) => post<RemoveCustomerDebitNoteData>('removecustomerdebitnote', data);


export enum VendorCreditStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum VendorCreditType {
    Invoice = 1,
    Other = 2,
}

export interface VendorCreditNoteJournalData {
    vendor_credit_note_id?: number;
    vendor_id?: number;
    currency_code?: string;
    vendor_credit_note_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    vendor_credit_note_status?: VendorCreditStatus;
    vendor_credit_note_type?: number;
    financial_year_id?: number;
    details?: saveVendor_Credit_NoteDetail[];
    journal_details?: SaveVendorCreditNoteJournalDetail[];
    use_currencies?: number;
}

export interface SaveVendorCreditNoteJournalDetailCostCenter {
    vendor_credit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveVendorCreditNoteJournalDetail {
    vendor_credit_note_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveVendorCreditNoteJournalDetailCostCenter[];
}

export interface VendorCreditNoteJournalResult {
    journal_details?: VendorCreditNoteJournalDetail[];
}

export const vendorCreditNoteJournalEntries = async (data: VendorCreditNoteJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'vendor_credit_note_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'amount' || key === 'due_amount' || key === 'debit' || key === 'credit' 
        || key === 'local_debit' || key === 'local_credit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'amount' || key === 'due_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }


        return value;
    };
    return post<VendorCreditNoteJournalData, VendorCreditNoteJournalResult>('vendorcreditnotejournalentries', data, replacer, reviver);
}
export interface VendorCreditNoteJournalDetailCostCenter {
    vendor_credit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface VendorCreditNoteJournalDetail {
    vendor_credit_note_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: VendorCreditNoteJournalDetailCostCenter[];

}

export interface Vendor_Credit_NoteDetail {
    vendor_credit_note_inv_detail_id?: number;
    vendor_credit_note_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface saveVendor_Credit_NoteDetail {
    vendor_credit_note_inv_detail_id?: number;
    vendor_credit_note_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface Vendor_Credit_NoteData {
    vendor_credit_note_id: number;
    vendor_credit_note_no?: number;
    vendor_credit_note_date: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_credit_note_type?: number;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_credit_note_description?: string;
    vendor_credit_note_status?: VendorCreditStatus;
    disable_edit?: boolean;
    use_currencies?: number;
    invoice_details?: Vendor_Credit_NoteDetail[];
    disabled: number;
}

export interface SearchVendor_Credit_NotesData {
    vendor_credit_note_id?: number;
    lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchVendor_Credit_NoteResult {
    vendor_credit_note_id: number;
    vendor_credit_note_no?: number;
    vendor_credit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_credit_note_type?: number;
    vendor_credit_note_status?: VendorCreditStatus;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_credit_note_description?: string;
    disable_edit?: boolean;
    use_currencies?: number;
    invoice_details?: Vendor_Credit_NoteData[];
    disabled?: number;
}
export interface SearchVendor_Credit_NotesResult {
    vendor_credit_notes: Vendor_Credit_NoteData[];
}


export const searchVendor_Credit_Notes = async (data: SearchVendor_Credit_NotesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_amount' || key === 'amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchVendor_Credit_NotesData, SearchVendor_Credit_NotesResult>('searchvendor_credit_note', data, replacer, reviver);

}

export interface FindVendor_Credit_NoteData {
    vendor_credit_note_id?: number;
    disable_edit?: boolean;
    invoice_details?: Vendor_Credit_NoteDetail[];
    journal_details?: VendorCreditNoteJournalDetail[];
    lang?: string;


}
export interface FindVendor_Credit_NoteResult {
    vendor_credit_note_id?: number;
    vendor_credit_note_no?: number;
    vendor_credit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_credit_note_type?: number;
    vendor_credit_note_status?: VendorCreditStatus;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_credit_note_description?: string;
    disable_edit?: boolean;
    journal_details?: VendorCreditNoteJournalDetail[];
    invoice_details?: Vendor_Credit_NoteDetail[];
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    use_currencies?: number;

}

export const findVendor_Credit_Note = async (data: FindVendor_Credit_NoteData) => {


    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };


    return post<FindVendor_Credit_NoteData, FindVendor_Credit_NoteResult>('findvendor_credit_note', data, undefined, reviver);
}
export interface SaveVendor_Credit_NoteData {
    vendor_credit_note_id?: number;
    vendor_credit_note_no?: number;
    vendor_credit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_credit_note_type?: number;
    vendor_credit_note_status?: VendorCreditStatus;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_credit_note_description?: string;
    vendor_credit_note_description_en?: string;
    vendor_credit_note_description_ar?: string;
    disable_edit?: boolean;
    invoice_details?: Vendor_Credit_NoteDetail[];
    journal_details?: VendorCreditNoteJournalDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    use_currencies?: number;
}

export interface SaveVendor_Credit_NoteResult {
    vendor_credit_note_id?: number;
    vendor_credit_note_no?: number;
    vendor_credit_note_status?: VendorCreditStatus;
    disable_edit?: boolean;
    journal_details?: VendorCreditNoteJournalDetail[];
    invoice_details?: Vendor_Credit_NoteDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;
}

export const saveVendor_Credit_Note = async (data: SaveVendor_Credit_NoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'vendor_credit_note_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<SaveVendor_Credit_NoteData, SaveVendor_Credit_NoteResult>('savevendor_credit_note', data, replacer, reviver);
}
export interface RemoveVendor_Credit_NoteData {
    vendor_credit_note_id: number;

}

export const removeVendor_Credit_Note = async (data: RemoveVendor_Credit_NoteData) => post<RemoveVendor_Credit_NoteData>('removevendor_credit_note', data);


export enum VendorDebitStatus {
    PARKED = 0,
    ACTIVE = 1
}

export enum VendorDebitType {
    Invoice = 1,
    Other = 2,
}

export interface VendorDebitNoteJournalData {
    vendor_debit_note_id?: number;
    vendor_id?: number;
    currency_code?: string;
    vendor_debit_note_date?: Date;
    amount?: Decimal;
    other_account?: string;
    lang?: string;
    vendor_debit_note_status?: VendorDebitStatus;
    vendor_debit_note_type?: number;
    financial_year_id?: number;
    details?: saveVendor_Debit_NoteDetail[];
    journal_details?: SaveVendorDebitNoteJournalDetail[];
    use_currencies?: number;
}

export interface SaveVendorDebitNoteJournalDetailCostCenter {
    vendor_debit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    distribute_pct?: Decimal;
}
export interface SaveVendorDebitNoteJournalDetail {
    vendor_debit_note_journal_detail_id?: number;
    account_code?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    detail_description?: string;
    costcenters?: SaveVendorDebitNoteJournalDetailCostCenter[];
}

export interface VendorDebitNoteJournalResult {
    journal_details?: VendorDebitNoteJournalDetail[];
}

export const vendorDebitNoteJournalEntries = async (data: VendorDebitNoteJournalData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'vendor_debit_note_date' && value) {
            return isoDate(value);
        }
        else if ((key === 'debit' || key === 'debit' || key === 'cost') && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key=== 'amount' || key=== 'due_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate' || key === 'cost') && value) {
            return decimal(value);
        }

        return value;
    };
    return post<VendorDebitNoteJournalData, VendorDebitNoteJournalResult>('vendordebitnotejournalentries', data, replacer, reviver);
}
export interface VendorDebitNoteJournalDetailCostCenter {
    vendor_debit_note_journal_detail_costcenter_id?: number;
    costcenter_code?: string;
    costcenter_name?: string;
    distribute_pct?: Decimal;
}
export interface VendorDebitNoteJournalDetail {
    vendor_debit_note_journal_detail_id?: number;
    account_code?: string;
    account_name?: string;
    currency_code?: string;
    exchange_rate?: Decimal;
    debit?: Decimal;
    credit?: Decimal;
    local_debit?: Decimal;
    local_credit?: Decimal;
    analysis_code?: string;
    analysis_code_name?: string;
    detail_description?: string;
    costcenters?: VendorDebitNoteJournalDetailCostCenter[];

}

export interface Vendor_Debit_NoteDetail {
    vendor_debit_note_inv_detail_id?: number;
    vendor_debit_note_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface saveVendor_Debit_NoteDetail {
    vendor_debit_note_inv_detail_id?: number;
    vendor_debit_note_id?: number;
    purchase_invoice_id: number;
    purchase_invoice_no?: number;
    purchase_invoice_date?: Date;
    currency_code?: string;
    due_amount?: Decimal;

}

export interface Vendor_Debit_NoteData {
    vendor_debit_note_id: number;
    vendor_debit_note_no?: number;
    vendor_debit_note_date: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_debit_note_type?: number;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_debit_note_description?: string;
    vendor_debit_note_status?: VendorDebitStatus;
    disable_edit?: boolean;
    use_currencies?: number;
    invoice_details?: Vendor_Debit_NoteDetail[];
    disabled: number;
}

export interface SearchVendor_Debit_NotesData {
    vendor_debit_note_id?: number;
     lang?: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
}

export interface SearchVendor_Debit_NoteResult {
    vendor_debit_note_id: number;
    vendor_debit_note_no?: number;
    vendor_debit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_debit_note_type?: number;
    vendor_debit_note_status?: VendorDebitStatus;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_debit_note_description?: string;
    disable_edit?: boolean;
    use_currencies?: number;
    invoice_details?: Vendor_Debit_NoteData[];
    disabled?: number;
}
export interface SearchVendor_Debit_NotesResult {
    vendor_debit_notes: Vendor_Debit_NoteData[];
}


export const searchVendor_Debit_Notes = async (data: SearchVendor_Debit_NotesData) => {

    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    const reviver = (key: string, value: any): any => {
        if (key === 'due_amount' || key === 'total_amount' || key === 'amount') {
            return decimal(value);
        }

        return value;
    };

    return post<SearchVendor_Debit_NotesData, SearchVendor_Debit_NotesResult>('searchvendor_debit_note', data, replacer, reviver);

}

export interface FindVendor_Debit_NoteData {
    vendor_debit_note_id?: number;
    disable_edit?: boolean;
    invoice_details?: Vendor_Debit_NoteDetail[];
    journal_details?: VendorDebitNoteJournalDetail[];
    lang?: string;


}
export interface FindVendor_Debit_NoteResult {
    vendor_debit_note_id?: number;
    vendor_debit_note_no?: number;
    vendor_debit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_debit_note_type?: number;
    vendor_debit_note_status?: VendorDebitStatus;
    vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_debit_note_description?: string;
    disable_edit?: boolean;
    journal_details?: VendorDebitNoteJournalDetail[];
    invoice_details?: Vendor_Debit_NoteDetail[];
    disabled?: number;
    subjournal_id?: number;
    subjournal_name?: string;
    batch_id?: number;
    batch_no?: number;
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType,
    use_currencies?: number;

}

export const findVendor_Debit_Note = async (data: FindVendor_Debit_NoteData) => {


    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }


        return value;
    };


    return post<FindVendor_Debit_NoteData, FindVendor_Debit_NoteResult>('findvendor_debit_note', data, undefined, reviver);
}
export interface SaveVendor_Debit_NoteData {
    vendor_debit_note_id?: number;
    vendor_debit_note_no?: number;
    vendor_debit_note_date?: Date;
    branch_id?: number;
    currency_code?: string;
    amount?: Decimal;
    vendor_debit_note_type?: number;
    vendor_debit_note_status?: VendorDebitStatus;
    vendor_debit_note_description_en?: string;
    vendor_debit_note_description_ar?: string;
     vendor_id?: number;
    vendor_name?: string;
    other_account?: string;
    account_name?: string;
    lang?: string;
    vendor_debit_note_description?: string;
    disable_edit?: boolean;
    invoice_details?: Vendor_Debit_NoteDetail[];
    journal_details?: VendorDebitNoteJournalDetail[];
    disabled: number;
    journal_id?: number;
    subjournal_id?: number;
    batch_id?: number;
    use_currencies?: number;
}

export interface SaveVendor_Debit_NoteResult {
    vendor_debit_note_id?: number;
    vendor_debit_note_no?: number;
    vendor_debit_note_status?: VendorDebitStatus;
    disable_edit?: boolean;
    journal_details?: VendorDebitNoteJournalDetail[];
    invoice_details?: Vendor_Debit_NoteDetail[];
    journal_id?: number;
    journal_no?: number;
    journal_status?: JournalStatusType;
    use_currencies?: number;

}

export const saveVendor_Debit_Note = async (data: SaveVendor_Debit_NoteData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'vendor_debit_note_date' && value) {
            return isoDate(value);
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if ((key === 'price' || key === 'amount' || key === 'total_amount'
            || key === 'due_amount' || key === 'tax_amount' || key === 'debit' || key === 'credit' || key === 'local_debit'
            || key === 'local_credit' || key === 'exchange_rate') && value) {
            return decimal(value);
        }
        return value;
    };
    return post<SaveVendor_Debit_NoteData, SaveVendor_Debit_NoteResult>('savevendor_debit_note', data, replacer, reviver);
}
export interface RemoveVendor_Debit_NoteData {
    vendor_debit_note_id: number;

}

export const removeVendor_Debit_Note = async (data: RemoveVendor_Debit_NoteData) => post<RemoveVendor_Debit_NoteData>('removevendor_debit_note', data);

export interface SearchContractsData {
    search?: string;
    active: boolean;
    lang: string;
}

export interface ContractData {
    contract_id: number;
    contract_no: number;
    contract_name?: string;
    contract_description?: string;
    contract_type?: number;
    amount?: Decimal;
    branch_id?: number;
    vendor_id?: number;
    vendor_name?: string;
    purches_VAT_account?: string;
}
export interface SearchContractsResult {
    contracts: ContractData[];
}

export const searchContracts = async (data: SearchContractsData) => {
    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }
        return value;
    };
    return post<SearchContractsData, SearchContractsResult>('searchcontracts', data, undefined, reviver);
}
export interface FindContractData {
    contract_id: number;
    lang: string;
}
export interface FindContractResult {
    contract_id: number;
    contract_no?: number;
    contract_name?: string;
    contract_description?: string;
    contract_type?: number;
    amount?: Decimal;
    branch_id?: number;
    vendor_id?: number;
    vendor_name?: string;
    purches_VAT_account?: string;
    disabled: number;
    notes?: NoteData[];
    media: Media[];
}

export const findContract = async (data: FindContractData) => {

    const reviver = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return decimal(value);
        }

        return value;
    };


    return post<FindContractData, FindContractResult>('findcontract', data, undefined, reviver);
}
export interface SaveContractData {
    contract_id?: number;
    lang: string;
    contract_no?: number;
    contract_name?: string;
    contract_description?: string;
    contract_type?: number;
    amount?: Decimal;
    branch_id?: number;
    vendor_id?: number;
    vendor_name?: string;
    purches_VAT_account?: string;
    disabled: number;
    media: Media[];
    files?: File[];
}

export interface SaveContractResult {
    contract_id: number;
    contract_no: number;
    media: Media[];
}

export const saveContract = async (data: SaveContractData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'amount' && value) {
            return value.toString();
        }
        return value;
    };

    return postMP<SaveContractData, SaveContractResult>('savecontract', data, replacer);
};


export interface SaveContractNote {
    contract_id: number;
    note_id?: number;
    user_id?: number;
    lang: string;
    note?: string;
}

export const saveContractNote = async (data: SaveContractNote) => {
    return post<SaveContractNote, SaveNoteResult>('savecontractnote', data);
};

export interface RemoveContractData {
    contract_id: number;
}

export const removeContract = async (data: RemoveContractData) => post<RemoveContractData>('removecontract', data);
export const removeContractNote = async (data: RemoveNote) => post<RemoveNote>('removecontractnote', data);

export interface Work_OrderDetail {
    work_order_detail_id?: number;
    product_code: string;
    product_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    qty?: Decimal;
}
export interface Work_OrderData {
    work_order_id: number;
    work_order_no?: number;
    work_order_type?: number;
    warehouse_id?: number;
    warehouse_name?: string;
    product_code: string;
    employee_id?: number;
    employee_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    lang?: string;
    work_order_description?: string;
    product_qty?: Decimal;

    disabled: number;
}

export interface SearchWork_OrdersData {
    work_order_id?: number;
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
}

export interface SearchWork_OrderResult {
    work_order_id: number;
    work_order_no: number;
    work_order_date: Date;
    product_code: string;
    product_qty?: Decimal;
    employee_id?: number;
    employee_name?: string;
    branch_id?: number;
    branch_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    disabled?: number;
    lang?: string;
    work_order_description?: string;
}
export interface SearchWork_OrdersResult {
    work_orders: SearchWork_OrderResult[];
}


export const searchWork_Orders = async (data: SearchWork_OrdersData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };

    return post<SearchWork_OrdersData, SearchWork_OrdersResult>('searchwork_orders', data, replacer, undefined);
}
export interface FindWork_OrderData {
    work_order_id: number;
    lang?: string;
    active: boolean;
}
export interface FindWork_OrderResult {
    work_order_id: number;
    work_order_no: number;
    work_order_date: Date;
    product_code: string;
    product_qty?: Decimal;
    employee_id?: number;
    employee_name?: string;
    unit_type_id?: number;
    unit_type_name?: string;
    unit_type_symbol?: string;
    lang?: string;
    work_order_description?: string;
    details?: Work_OrderDetail[];
    disabled: number;
}

export const findWork_Order = async (data: FindWork_OrderData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {

        if (key === 'product_qty') {
            return decimal(value);
        }

        if (key === 'qty') {
            return decimal(value);
        }
        return value;
    };


    return post<FindWork_OrderData, FindWork_OrderResult>('findwork_order', data, replacer, reviver);
}

export interface SaveWork_OrderData {
    work_order_id?: number;
    work_order_no?: number;
    product_code?: string;
    product_qty?: Decimal;
    branch_id?: number;
    employee_id?: number;
    work_order_date?: Date;
    unit_type_id?: number;
    lang?: string;
    work_order_description?: string;
    details?: Work_OrderDetail[];

    disabled: number;
}

export interface SaveWork_OrderResult {
    work_order_id: number;
    work_order_no: number;
    details?: Work_OrderDetail[];
}
export const saveWork_Order = async (data: SaveWork_OrderData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'work_order_date' && value) {
            return isoDate(value);
        }
        if ((key === 'product_qty' || key === 'qty') && value) {
            return value.toString();
        }

        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'product_qty') {
            return decimal(value);
        }

        if (key === 'qty') {
            return decimal(value);
        }

        return value;
    };

    return post<SaveWork_OrderData, SaveWork_OrderResult>('savework_order', data, replacer, reviver);
}

export interface RunWork_OrderResult {
    details?: Work_OrderDetail[];
}

export const runWork_Order = async (data: SaveWork_OrderData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'work_order_date' && value) {
            return isoDate(value);
        }
        if (key === 'product_qty' && value) {
            return value.toString();
        }
        return value;
    }
    const reviver = (key: string, value: any): any => {
        if (key === 'qty') {
            return decimal(value);
        }

        return value;
    }

    return post<SaveWork_OrderData, RunWork_OrderResult>('runwork_order', data, replacer, reviver);
};

export interface RemoveWork_OrderData {
    work_order_id: number;

}

export const removeWork_Order = async (data: RemoveWork_OrderData) => post<RemoveWork_OrderData>('removework_order', data);

export interface SearchReportsData {
    lang: string;
    search?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    pending?: boolean;
    success?: boolean;
    failed?: boolean;
}
export interface SearchReportResult {
    report_run_id: number;
    report_run_name?: string;
    run_status: number;
    lang?: string;
    mime_type?: string;
    file_size?: number;
    file_name?: string;
    created: Date;
    error_message?: string;
}
export interface SearchReportsResult {
    reports: SearchReportResult[];
}
export const searchReports = async (data: SearchReportsData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'created') {
            return date(value);
        }
        return value;
    };
    return post<SearchReportsData, SearchReportsResult>('searchreports', data, replacer, reviver);
}

export interface RemoveReportData {
    report_run_id: number;
}

export const removeReport = async (data: RemoveReportData) => post<RemoveReportData>('removereport', data);
export interface SearchExpensesAnalysisData {
    search?: string;
    client?: string;
    searchRange: SearchRangeType;
    from_date?: Date;
    to_date?: Date;
    today: Date;
    active: boolean;
    parked: boolean;
    completed: boolean;
    lang: string;
}
export interface ExpenseAnalysisData {
    expense_analysis_id: number;
    user_email?: string;
    user_name?: string;
    expense_analysis_date?: Date;
    clickup_id?: number;
    expense_analysis_status: number;
}
export interface SearchExpensesAnalysisResult {
    expenses_analysis: ExpenseAnalysisData[];
}
export const searchExpensesAnalysis = async (data: SearchExpensesAnalysisData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'today' && value) {
            return isoDate(value);
        }
        else if (key === 'from_date' && value) {
            return isoDate(value);
        }
        else if (key === 'to_date' && value) {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'expense_analysis_date') {
            return date(value);
        }
        return value;
    };
    return post<SearchExpensesAnalysisData, SearchExpensesAnalysisResult>('searchexpensesanalysis', data, replacer, reviver);
}
export interface FindExpenseAnalysisData {
    expense_analysis_id: number;
    lang: string;
}
export interface FindExpenseAnalysisResult {
    expense_analysis_id: number;
    client: string;
    user_email?: string;
    user_name?: string;
    expense_analysis_date?: Date;
    clickup_id?: number;
    expens_analysis_status: number;
}
export const findExpenseAnalysis = async (data: FindExpenseAnalysisData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'expense_analysis_date') {
            return isoDate(value);
        }
        return value;
    };
    const reviver = (key: string, value: any): any => {
        if (key === 'expense_analysis_date') {
            return date(value);
        }
        return value;
    };
    return post<FindExpenseAnalysisData, FindExpenseAnalysisResult>('findexpenseanalysis', data, replacer, reviver);
}
export interface SaveExpenseAnalysisData {
    expense_analysis_id?: number;
    client?: string;
    expense_analysis_date: Date;
    expense_analysis_status: number;
}
export interface SaveExpenseAnalysisResult {
    expense_analysis_id: number;
}
export const saveExpenseAnalysis = async (data: SaveExpenseAnalysisData) => {
    const replacer = (key: string, value: any): any => {
        if (key === 'expense_analysis_date') {
            return isoDate(value);
        }
        return value;
    };
    return post<SaveExpenseAnalysisData, SaveExpenseAnalysisResult>('saveexpenseanalysis', data, replacer);
}
export interface RemoveExpenseAnalysisData {
    expense_analysis_id: number;
}
export const removeExpenseAnalysis = async (data: RemoveExpenseAnalysisData) => post<RemoveExpenseAnalysisData>('removeexpenseanalysis', data);
